import React, {useEffect, useState} from "react";
import {Stack, HStack, Text, Select, Heading, Checkbox, Button} from "@chakra-ui/react";
import Loader from "../../../components/loading/Loader";


interface OpenHoursFormProps {
  open?: string;
  close?: string;
  day: number;
  dayLabel: string;
  closedLabel?: string;
  openLabel?: string;
  closeLabel?: string;
  onChange: ({open, close, day, status}) => void
}

const hours : Array<String> = [];
for (let i = 6; i < 24; i++) {
  const currentHour = String(i ).padStart(2, "0");
  hours.push(currentHour + ":00");
  hours.push(currentHour + ":" + 15);
  hours.push(currentHour + ":" + 30);
  hours.push(currentHour + ":" + 45);
}

function OpenHoursForm({open, close, day, status, dayLabel, closedLabel = "We are closed on this day", openLabel = "Open", closeLabel = "Closed", onChange}: OpenHoursFormProps) {
  const [isClosed, setIsClosed] = useState(status === "Closed" || (open === null && close === null))

  const [openTime, setOpenTime] = useState("");
  const [closeTime, setCloseTime] = useState("");
  const [openTimeOptions, setOpenTimeOptions] = useState(hours);
  const [closeTimeOptions, setCloseTimeOptions] = useState(hours);

  useEffect(() => {
    if (open) {
      setOpenTime(open)
    }
  }, [open])

  useEffect(() => {
    if (close) {
      setCloseTime(close)
    }
  }, [close])

  useEffect(() => {
    if (openTime) {
      // setOpenTime(openTime)
      setCloseTimeOptions(getRemainingTimeOptions(openTime, true))
    }
  }, [openTime])

  useEffect(() => {
    if (closeTime) {
      // setCloseTime(closeTime)
      setOpenTimeOptions(getRemainingTimeOptions(closeTime, false))
    }
  }, [closeTime])

  function getRemainingTimeOptions(currentTime: string, forward: boolean) {
    const currentTimeIndex = hours.indexOf(currentTime)
    if (forward) {
      return hours.slice(currentTimeIndex, hours.length)
    } else {
      return hours.slice(0, currentTimeIndex)
    }
  }

  useEffect(() => {
    if ((openTime && closeTime) || isClosed) {
      let open, close;
      if (isClosed) {
        open = null
        close = null;
      } else {
        open = openTime ? openTime : null;
        close = closeTime ? closeTime : null;
      }
      onChange({day: day, open: open, close: close, status: isClosed ? "Closed": null})
    }
  }, [openTime, closeTime, isClosed])

  return (
      <Stack stack={3} padding={"10px 10px"}>
        <Heading as="h4" size="md">{dayLabel}</Heading>
        <Stack spacing={2} align={["flex-start", "flex-start"]}>
          <HStack>
            <Stack>
            <HStack>
              <Select  value={isClosed ? "" : openTime} onChange={(event) => setOpenTime(event.target.value)}>
                <option value={""}>--</option>
                {openTimeOptions.map(h => <option key={h} value={h}>{h}</option>)}
              </Select>
            </HStack>
            </Stack>
            <Stack>
              <HStack>
                <Select  value={isClosed ? "" : closeTime} onChange={(event) => setCloseTime(event.target.value)}>
                  <option value={""}>--</option>
                  {closeTimeOptions.map(h => <option key={h} value={h}>{h}</option>)}
                </Select>
              </HStack>
            </Stack>
          </HStack>
          <Checkbox onChange={(e) => setIsClosed(e.target.checked)} isChecked={isClosed}>{closedLabel}</Checkbox>
          {/*{changeCounter > 4 && <Button onClick={onSave}>Save</Button>}*/}
        </Stack>
      </Stack>
  )
}

export default OpenHoursForm;
