import React from 'react'
import { Spinner, Flex } from "@chakra-ui/react"

interface LoaderProps {
  children: any,
  text?: string,
  isLoading?: boolean,
}

function Loader(props : LoaderProps) {
  if (props.isLoading) {
    if (props.text) {
      return (
          <Flex direction={"column"} align={"center"}>
            <Spinner color={"primary.500"} emptyColor="gray.200"/>
            <small style={{marginTop: "5px"}}>{props.text}</small>
          </Flex>
      )
    } else {
      return (
          <Flex direction={"column"} align={"center"}>
            <Spinner color={"primary.500"} emptyColor="gray.200"/>
          </Flex>
      )
    }
  } else {
    return props.children || null
  }
}

export default Loader;
