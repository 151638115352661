import React, {createContext} from 'react';
import ApiService from "../services/apiService";
import axios from "axios";
import {SERVERLESS_API_URL} from "../config/halalmunchAppConfig";


interface ApiContextProps {
  apiService: ApiService
}

export const ApiContext = createContext<ApiContextProps>({
  apiService: {}
});


export function ApiProvider(props) {
  const axiosInstance = axios.create({
    baseURL: SERVERLESS_API_URL,
    timeout: 20000,
  })
  axiosInstance.defaults.timeout = 20000
  setupAxiosAuthHeader("default")

  function setupAxiosAuthHeader(token: string) {
    console.log("Setting header", token)
    if (token == null) {
      token = "default"
    }
    axiosInstance.defaults.headers["Authorization"] = `Bearer ${token}`
  }

  return (
      <ApiContext.Provider
          value={{
            apiService: new ApiService(axiosInstance),
            setupAxiosAuthHeader: setupAxiosAuthHeader,
          }}
      >
        {props.children}
      </ApiContext.Provider>
  )
}
