import React from 'react';
import ReactDOM from 'react-dom';
// import 'normalize.css/normalize.css'
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from 'react-router-dom';
import {AuthProvider} from "./providers/AuthProvider";
import {ApiProvider} from "./providers/ApiProvider";
import {ChakraProvider, extendTheme} from '@chakra-ui/react';
import {NotificationProvider} from './providers/NotificationProvider';
import ResponsiveProvider from "./providers/ResponsiveProvider";
import { Provider } from 'react-redux'
import { store } from './store/configureStore'
import 'animate.css';
import { FirebaseProvider } from './providers/FirebaseProvider';
import * as Sentry from '@sentry/react';
import {Integrations} from '@sentry/tracing';
import appInfo from '../package.json'
import {createBrowserHistory} from 'history';

const theme = extendTheme({
  fonts: {
    heading: "Roboto",
    body: "Roboto",
  },
  colors: {
    "primary": {
      // '50': '#fff7f7',
      // '100': '#ffeeee',
      // '200': '#ffd5d5',
      // '300': '#ffbcbc',
      // '400': '#ff8989',
      '500': '#ff5757',
      '600': '#e64e4e',
      '700': '#bf4141',
      '800': '#993434',
      '900': '#7d2b2b'
    },
    'secondary': {
      '50': '#f5faff',
      '100': '#ebf5ff',
      '200': '#cde6ff',
      '300': '#afd7fe',
      '400': '#74b8fe',
      '500': '#389afd',
      '600': '#328be4',
      '700': '#2a74be',
      '800': '#225c98',
      '900': '#1b4b7c'
    },
  }
})

const history = createBrowserHistory();


// Setup monitoring
if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://0da95c91afb543b2954464001a39e7ed@o409991.ingest.sentry.io/6256197",
    environment: process.env.NODE_ENV,
    release: "halal-munch-partners@" + appInfo.version,
    normalizeDepth: 10,
    integrations: [

      new Integrations.BrowserTracing({
        // Can also use reactRouterV3Instrumentation or reactRouterV4Instrumentation
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
    ]
  });
}


ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <BrowserRouter>
          <ChakraProvider theme={theme}>
            <ResponsiveProvider>
              <NotificationProvider>
                <ApiProvider>
                  <AuthProvider>
                    <FirebaseProvider>
                      <App/>
                    </FirebaseProvider>
                  </AuthProvider>
                </ApiProvider>
              </NotificationProvider>
            </ResponsiveProvider>
          </ChakraProvider>
        </BrowserRouter>
      </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
