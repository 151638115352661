import React, {useContext, useEffect, useRef, useState} from "react";
import {isMobile} from "react-device-detect";
import {HeroBackground, Panel, StandOutBox} from "../../components/blocks";
import {
  Flex, Stack, Input, InputGroup, Textarea,
  Select, Checkbox, CheckboxGroup, InputLeftAddon, Icon, Spinner, Box,
  HStack, Tag, TagLabel, TagCloseButton, FormControl, FormLabel, FormHelperText, Button,
  FormErrorMessage, InputRightAddon, Heading, DrawerOverlay, DrawerContent, DrawerHeader, DrawerFooter,
  DrawerBody, Drawer
} from '@chakra-ui/react'
import PostRestaurantOnBoardRequest from "../../models/requests/PostRestaurantOnBoardRequest";
import Logger from "../../utils/Logger";
import {useDebounce} from "react-use";
import {capitalize, isEmpty, uniq} from "lodash-es";
import {ApiContext} from "../../providers/ApiProvider";
import {useFormik } from 'formik';
import {NotificationContext} from "../../providers/NotificationProvider";
import Loader from "../../components/loading/Loader";

interface Props {
  onComplete: (Restaurant) => void,
  isOpen: boolean,
}
function NewRestaurantOnBoardingDrawer(props : Props) {
  const logger = new Logger(NewRestaurantOnBoardingDrawer.name)
  const {apiService} = useContext(ApiContext);
  const {success, error} = useContext(NotificationContext);

  const FORM_POSITIONS = {
    RESTAURANT: 0,
    LOCATION_CONTACT: 1,
    CATEGORIES: 2,
    SUMMARY: 3,
    SUBMIT: 4,
  }

  const [isLoading, setIsLoading] = useState(null);

  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
      restaurantType: "",
      halalCategory: "",
      amenities: [],
      cuisines: [],
      placeId: "",
      placeIdDescription: "",
      locationPrivacy: ["showFullAddress", "showOnMap"],
      email: "",
      phone: "",
      phoneOptions: ["allowOrders"],
      website: "",
      instagram: "",
      facebook: "",
    },
    onSubmit: submit,
    validate: validateForm,
  });

  const [formValuesSaved, setFormValuesSaved] = useState(formik.values)
  const [formPosition, setFormPosition] = useState(0);

  const [sessionToken, setSessionToken] = useState(null);
  const [autocompletePredictions, setAutocompletePredictions] = useState([]);

  const [restaurantTypes, setRestaurantTypes] = useState([]);
  const [halalCategories, setHalalCategories] = useState([]);
  const [cuisineCategories, setCuisineCategories] = useState([]);
  const [amenityCategories, setAmenityCategories] = useState([]);
  const [autocompleteSearch, setAutocompleteSearch] = useState(null);
  const [autocompleteSearchLoading, setAutocompleteSearchLoading] = useState(false);


  useEffect(() => {
    setIsLoading(true)
    apiService.restaurantGetOnBoardInputData()
        .then(data => {
          setRestaurantTypes(data.types);
          setHalalCategories(data.halalCategories);
          setCuisineCategories(data.cuisineCategories);
          setAmenityCategories(data.amenityCategories);
          const savedData = loadDataJson("formValues");
          if (!isEmpty(savedData)) {
            formik.setValues(savedData)
          }
          setIsLoading(false)
        })
        .catch(e => error("Failed to load data", e.message))
  }, [null]);

  useEffect(() => {
    if(!isLoading && !isEmpty(formik.values.name)) {
      saveDataJson("formValues", formik.values)
    }
  }, [formik.values, formik])

  useDebounce(() => {
    if (!isEmpty(autocompleteSearch)) {
      fetchAutocompletePredictions(autocompleteSearch);
      formik.setFieldValue("placeIdDescription", "")
    }
  }, 2000, [autocompleteSearch]);

  useEffect(() => {
    // formik.resetForm()
    formik.setErrors([])
  }, [formPosition])

  function updateFormikArrayField(field, currentData, dataToAdd, dataToRemove) {
    const data = new Set(currentData);
    if (dataToRemove) {
      data.delete(dataToRemove)
    } else {
      data.add(dataToAdd)
    }
    formik.setFieldValue(field, [...data])
  }


  function saveData(key: string, data: any) : void {
    window.localStorage.setItem(`halalmunch:${NewRestaurantOnBoardingDrawer.name}-formValues`, data)
  }

  function loadData(key: string) : any {
    const data = window.localStorage.getItem(`halalmunch:${NewRestaurantOnBoardingDrawer.name}-formValues`)
    logger.debug("loaded data", data)
    return (data == null) ? "{}" : data;
  }

  function saveDataJson(key: string, data: any) : void {
    saveData(key, JSON.stringify(data))
  }

  function loadDataJson(key: string) : any {
    return JSON.parse(loadData(key))
  }

  async function nextForm() {
    console.log("Next form")
    await formik.submitForm()
    const errors = formik.errors;
    const isForm1Error =  ["name", "description", "restaurantType"].some(i => errors.hasOwnProperty(i))
    const isForm2Error = ["placeId", "showAddressInput", "showFullAddress", "showOnMap",
      "email", "phone", "website", "instagram", "facebook"].some(i => errors.hasOwnProperty(i))
    const isForm3Error = ["halalCategory", "cuisines", "amenities"].some(i => errors.hasOwnProperty(i))


    if (isForm1Error) {
      setFormPosition(FORM_POSITIONS.RESTAURANT)
    } else if (isForm2Error) {
      setFormPosition(FORM_POSITIONS.LOCATION_CONTACT)
    } else if (isForm3Error) {
      setFormPosition(FORM_POSITIONS.CATEGORIES)
    } else {
      setFormPosition(prevState => prevState > FORM_POSITIONS.SUMMARY ? FORM_POSITIONS.SUMMARY : prevState + 1)
    }
  }

  async function validateForm(values) {
    const errors = {};
    logger.debug("Validating form")

    if (formPosition === FORM_POSITIONS.RESTAURANT) {
      if (isEmpty(values.name)) {
        errors.name = "Name cannot be empty"
      }
      if (isEmpty(values.restaurantType)) {
        errors.restaurantType = "Store type cannot be empty"
      }
      // setFormPosition(FORM_POSITIONS.RESTAURANT)
    } else if (formPosition === FORM_POSITIONS.LOCATION_CONTACT) {
      if (isEmpty(values.placeId)) {
        errors.placeId = "Please select an address"
      }
      // setFormPosition(FORM_POSITIONS.LOCATION)
    } else if (formPosition === FORM_POSITIONS.CATEGORIES) {
      if (isEmpty(values.halalCategory)) {
        errors.halalCategory = "Halal Category cannot be empty"
      }
      if (isEmpty(values.cuisines)) {
        errors.cuisines = "Please select at least one cuisine"
      }
      if (isEmpty(values.amenities)) {
        errors.amenities = "Please select at least one amenity"
      }
      // setFormPosition(FORM_POSITIONS.CONTACT)
    }

    return errors;
  }

  function submit() {
    if (formPosition === FORM_POSITIONS.SUMMARY) {
      const {
        name, description, restaurantType, halalCategory, amenities, cuisines, locationPrivacy, email,
        phone, website, instagram, facebook, placeId
      } = formik.values
      setIsLoading(true)
      const showFullAddress = locationPrivacy.some((i => i === "showFullAddress"));
      const showOnMap = locationPrivacy.some((i => i === "showOnMap"));
      const postRequest = new PostRestaurantOnBoardRequest(
          name, description, restaurantType, halalCategory, amenities, cuisines, placeId, showFullAddress, showOnMap, email, phone, website, instagram, facebook,
      )
      apiService.restaurantOnBoardSubmit(postRequest)
          .then(r => {
            success("Successfully created your store", r.message)
            props.onComplete(r.data)
          })
          .catch(e => error("Failed to create your store", e.message))
          .finally(() => setIsLoading(false));
    }
  }

  function getTitle() {
    let text;
    switch (formPosition) {
      case FORM_POSITIONS.LOCATION_CONTACT:
        text = "Location & Contact";
        break;
      case FORM_POSITIONS.CATEGORIES:
        text = "Halal & Cuisines";
        break;
      case FORM_POSITIONS.SUMMARY:
        text = "Summary";
        break;
      default:
        text = "Restaurant Details";
        break;
    }
    return text;

  }

  function fetchAutocompletePredictions(search) {
    setAutocompleteSearchLoading(true)
    apiService.restaurantPlacesGetEstablishmentsSearch(search, sessionToken)
        .then(r => {
          setSessionToken(r.sessionToken);
          setAutocompletePredictions(r.autocompletePredictions)
        })
        .catch(e => error("Failed to fetch addresses", e.message))
        .finally(() => setAutocompleteSearchLoading(false))
  }

  const showAutocompletePanel = !isEmpty(autocompleteSearch)

  return (
      <Drawer
          isOpen={props.isOpen}
          onClose={() => null}
          placement={"bottom"}

      >
        <DrawerOverlay>
          <DrawerContent style={{height: "100%"}}>
            <DrawerHeader>Setup your store - {getTitle()}</DrawerHeader>
            <DrawerBody>
              <Loader isLoading={isLoading}>
                    <Stack spacing={6}>
                      {(formPosition === FORM_POSITIONS.RESTAURANT || formPosition === FORM_POSITIONS.SUMMARY) &&
                      <Stack spacing={6}>
                        <FormControl isInvalid={formik.errors.name && formik.touched.name} isRequired>
                          <FormLabel>Business Name</FormLabel>
                          <Input
                            id={"name"}
                            name="name"
                            placeholder="Business name"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            defaultValue={formik.values.name || ""}
                          />
                          <FormErrorMessage>{formik.errors.name}</FormErrorMessage>
                        </FormControl>
                        <FormControl isInvalid={formik.errors.description && formik.touched.description}>
                          <FormLabel>Description</FormLabel>
                          <Textarea
                            name="description"
                            rows={3}
                            placeholder="Describe your business in a few words"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            defaultValue={formik.values.description || ""}
                          />
                          <FormErrorMessage>{formik.errors.description}</FormErrorMessage>
                          <FormHelperText>Let people know what type of food business you are, what you sell, your story
                            etc.</FormHelperText>
                        </FormControl>
                        <FormControl isInvalid={formik.errors.restaurantType && formik.touched.restaurantType} isRequired>
                          <FormLabel>Type of business</FormLabel>
                          <Select
                            id={"restaurantType"}
                            name={"restaurantType"}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            defaultValue={formik.values.restaurantType || ""}
                          >
                            <option value={""}>Please Select</option>
                            {restaurantTypes.map(r => <option value={r}>{r}</option>)}
                          </Select>
                          <FormErrorMessage>{formik.errors.description}</FormErrorMessage>
                          <FormHelperText>Are you a home book? Lockdown baker?</FormHelperText>
                        </FormControl>
                      </Stack>
                      }

                      {(formPosition === FORM_POSITIONS.LOCATION_CONTACT || formPosition === FORM_POSITIONS.SUMMARY) &&
                      <>
                        <FormControl isInvalid={formik.errors.placeId && formik.touched.placeId} isRequired>
                          <FormLabel>Your business address</FormLabel>
                          <Stack>
                            <InputGroup inside>
                              <Input
                                value={isEmpty(formik.values.placeIdDescription) ? autocompleteSearch : formik.values.placeIdDescription}
                                onChange={event => setAutocompleteSearch(event.target.value)}
                                id={"placeId"}
                                name={"placeId"}
                                onBlur={formik.handleBlur}
                              />
                              <InputRightAddon>
                                {autocompleteSearchLoading ? <Spinner/> : <Icon icon="search"/>}
                              </InputRightAddon>
                            </InputGroup>
                            <FormErrorMessage>{formik.errors.placeId}</FormErrorMessage>

                            <Box display={showAutocompletePanel ? 'flex'  : 'none'}
                                 style={{position: 'relative', width: '100%', maxHeight: '300px',}}>
                              <Panel

                                style={{
                                  maxHeight: '300px',
                                  overflowY: 'scroll',
                                  position: 'absolute',
                                  zIndex: 10,
                                  maxWidth: isMobile ? '70%' : '500px',
                                }}
                              >
                                <Stack spacing={4} flex={1}>
                                  {autocompletePredictions.map(a =>
                                      <Box
                                          borderBottomColor={"#e1e1e1"}
                                          borderBottomStyle={"solid"}
                                          borderBottomWidth={"1px"}
                                          display={"flex"}
                                          align={"center"}
                                          style={{
                                            padding: "5px",
                                            minHeight: '50px',
                                            margin: 0,
                                            textAlign: 'start',
                                            alignItems: 'center'
                                          }}
                                          onClick={() => {
                                            formik.setFieldValue("placeId", a.place_id)
                                            formik.setFieldValue("placeIdDescription", a.description)
                                            setAutocompleteSearch("")
                                          }}
                                      >
                                        {a.description}
                                      </Box>
                                  )}
                                </Stack>
                              </Panel>
                            </Box>
                          </Stack>
                        </FormControl>
                        <FormControl isInvalid={formik.errors.locationPrivacy && formik.touched.locationPrivacy} isRequired>
                          <FormLabel>Your privacy</FormLabel>
                          <CheckboxGroup
                            name="locationPrivacy"
                            onChange={(values) => {
                              formik.setFieldValue("locationPrivacy", values)
                            }}
                            onBlur={formik.handleBlur}
                            defaultValue={[...formik.values.locationPrivacy]}
                          >
                            <Stack>
                              <Checkbox name={"showFullAddress"} value={"showFullAddress"}>Show my full address to
                                users</Checkbox>
                              <Checkbox name={"showOnMap"} value={"showOnMap"}> Show my business location on our Explore
                                Map</Checkbox>
                            </Stack>
                            <FormHelperText>
                              This will allow customers to see you pin point on the map when they open your app,
                              this helps drive business for people nearby.
                            </FormHelperText>
                          </CheckboxGroup>
                        </FormControl>
                        <FormControl isInvalid={formik.errors.email && formik.touched.email}>
                          <FormLabel>Your business public email</FormLabel>
                          <Input
                            name="email"
                            placeholder="e.g. TheBurgerShop@halalmunch.com (Optional)"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            defaultValue={formik.values.email || ""}
                          />
                          <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
                          <FormHelperText>Users will be able to contact you via email.</FormHelperText>
                        </FormControl>
                        <FormControl isInvalid={formik.errors.phone && formik.touched.phone}>
                          <FormLabel>Your business phone number</FormLabel>
                          <InputGroup>
                            <Input
                              name="phone"
                              placeholder="e.g. 01234567890 (Optional)"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              defaultValue={formik.values.phone || ""}
                            />
                          </InputGroup>
                          <FormErrorMessage>{formik.errors.phone}</FormErrorMessage>
                          <FormHelperText>
                            Users will be able to call you via this number from our app.
                          </FormHelperText>
                        </FormControl>
                        <FormControl isInvalid={formik.errors.website && formik.touched.website}>
                          <FormLabel>Your website</FormLabel>
                          <InputGroup>
                            <Input
                              name="website"
                              placeholder="e.g. www.halalmunch.com"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              defaultValue={formik.values.website || ""}
                            />
                          </InputGroup>
                          <FormErrorMessage>{formik.errors.website}</FormErrorMessage>
                        </FormControl>
                        <FormControl isInvalid={formik.errors.instagram && formik.touched.instagram}>
                          <FormLabel>Instagram link</FormLabel>
                          <InputGroup>
                            <InputLeftAddon children={"instagram.com/"}/>
                            <Input
                              name="instagram"
                              placeholder="e.g. halalmunch"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              defaultValue={formik.values.instagram || ""}
                            />
                          </InputGroup>
                        </FormControl>
                        <FormControl isInvalid={formik.errors.facebook && formik.touched.facebook}>
                          <FormLabel>Facebook link</FormLabel>
                          <InputGroup>
                            <InputLeftAddon children={"facebook.com/"}/>
                            <Input
                              name="facebook"
                              placeholder="e.g. halalmunch"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              defaultValue={formik.values.facebook || ""}
                            />
                          </InputGroup>
                          <FormErrorMessage>{formik.errors.facebook}</FormErrorMessage>
                        </FormControl>
                      </>
                      }

                      {(formPosition === FORM_POSITIONS.CATEGORIES || formPosition === FORM_POSITIONS.SUMMARY) &&
                      <>
                        <FormControl isInvalid={formik.errors.halalCategory && formik.touched.halalCategory} isRequired>
                          <FormLabel>Halal Category</FormLabel>
                          <Select
                            name="halalCategory"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            defaultValue={formik.values.halalCategory || ""}
                          >
                            <option value={""}>Please Select</option>
                            {halalCategories.map(h => <option value={h.name}>{h.title}</option>)}
                          </Select>
                          <FormErrorMessage>{formik.errors.halalCategory}</FormErrorMessage>
                          <FormHelperText>What level of Halal food does your business sell?</FormHelperText>
                        </FormControl>
                        <FormControl isInvalid={formik.errors.cuisines && formik.touched.cuisines} isRequired>
                          <FormLabel>Cuisines</FormLabel>
                          <Stack spacing={2}>
                            <HStack spacing={2} wrap={"wrap"}>
                              {formik.values.cuisines.map(c =>
                                  <Tag>
                                    <TagLabel>{c}</TagLabel>
                                    <TagCloseButton onClick={() => {
                                      updateFormikArrayField("cuisines", formik.values.cuisines, null, c)
                                    }}/>
                                  </Tag>
                              )}
                            </HStack>
                            <Select
                              name="cuisines"
                              onBlur={formik.handleBlur}
                              defaultValue={""}
                              onChange={(event) => {
                                updateFormikArrayField("cuisines", formik.values.cuisines, event.target.value)
                              }}
                            >
                              <option value={""}>Please Select</option>
                              {cuisineCategories.map(h => <option value={h.name}>{h.title}</option>)}
                            </Select>
                          </Stack>
                          <FormErrorMessage>{formik.errors.cuisines}</FormErrorMessage>
                          <FormHelperText>What cuisines does your business cover?</FormHelperText>
                        </FormControl>
                        <FormControl isInvalid={formik.errors.amenities && formik.touched.amenities} isRequired>
                          <FormLabel>Amenity</FormLabel>
                          <Stack spacing={2}>
                            <HStack spacing={2} wrap={"wrap"}>
                              {formik.values.amenities.map(c =>
                                  <Tag>
                                    <TagLabel>{c}</TagLabel>
                                    <TagCloseButton onClick={() => {
                                      updateFormikArrayField("amenities", formik.values.amenities, null, c)
                                    }}/>
                                  </Tag>
                              )}
                            </HStack>
                            <Select
                              name="amenities"
                              onBlur={formik.handleBlur}
                              defaultValue={""}
                              onChange={(event) => {
                                updateFormikArrayField("amenities", formik.values.amenities, event.target.value)
                              }}
                            >
                              <option value={""}>Please Select</option>
                              {amenityCategories.map(h => <option value={h.name}>{h.title}</option>)}
                            </Select>
                          </Stack>
                          <FormErrorMessage>{formik.errors.amenities}</FormErrorMessage>
                          <FormHelperText>What amenity does your business cover?</FormHelperText>
                        </FormControl>
                      </>
                      }

                    </Stack>
                  </Loader>
            </DrawerBody>
            <DrawerFooter>
              <HStack spacing={4}>
                {formPosition > FORM_POSITIONS.RESTAURANT &&
                <Button appearance="default"
                        isFullWith
                        onClick={() => setFormPosition(p => p - 1)}>Go Back</Button>
                }
                <Button colorScheme="secondary"
                        isFullWith
                        onClick={formPosition === FORM_POSITIONS.SUMMARY ? formik.handleSubmit : nextForm}
                        isLoading={formPosition === FORM_POSITIONS.SUMMARY && isLoading}>
                  {formPosition === FORM_POSITIONS.SUMMARY ? "Submit" : "Next"}
                </Button>
              </HStack>
            </DrawerFooter>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
  )

}

export default NewRestaurantOnBoardingDrawer;
