import {DashboardBasePanel} from "./DashboardBase";
import {
  Stack,
} from "@chakra-ui/react";
import React, {useContext, useEffect, useState} from "react";
import {Restaurant} from "../../../models/models";
import Logger from "../../../utils/Logger";
import {ApiContext} from "../../../providers/ApiProvider";
import {NotificationContext} from "../../../providers/NotificationProvider";
import {AuthContext} from "../../../providers/AuthProvider";
import {InputWithSaveButton} from "../../../components/inputs";

interface StoreContactUpdatePanelProps {
  restaurant: Restaurant | null | undefined,
}

function StoreContactUpdatePanel(props : StoreContactUpdatePanelProps) {
  const logger = new Logger(StoreContactUpdatePanel.name)

  // Props
  const {restaurant} = {...props}

  // App State
  const {isAuthenticated} = useContext(AuthContext);
  const {apiService} = useContext(ApiContext);
  const {success, error} = useContext(NotificationContext);

  // Component State
  const [isLoading, setIsLoading] = useState(false)

  function updateRestaurant(data: any) {
    setIsLoading(true)
    apiService.restaurantUpdate(restaurant.id, data)
        .then(data => {
          success("Updated restaurant success", data.message)
          // setRestaurant(data.result)
        })
        .catch(e => error("Failed to update store", e.message))
        .finally(() => setIsLoading(false))
  }



  return (
      <DashboardBasePanel title={"Store Contact"} isLoading={restaurant == null}>
        <Stack spacing={4}>
          <InputWithSaveButton
              label={"Email"}
              name={"email"}
              isLoading={isLoading}
              value={restaurant?.contact?.email}
              onSave={(value) => updateRestaurant({email: value})}
          />
          <InputWithSaveButton
              label={"Phone"}
              name={"phone"}
              isLoading={isLoading}
              value={restaurant?.contact?.phone}
              onSave={(value) => updateRestaurant({phone: value})}
          />
          <InputWithSaveButton
              label={"Instagram"}
              name={"instagram"}
              isLoading={isLoading}
              value={restaurant?.contact?.instagram}
              onSave={(value) => updateRestaurant({instagram: `instagram.com/${value}`})}
              addon={"instagram.com/"}
          />
          <InputWithSaveButton
              label={"Facebook"}
              name={"facebook"}
              value={restaurant?.contact?.facebook}
              onSave={(value) => updateRestaurant({facebook: `facebook.com/${value}`})}
              addon={"facebook.com/"}
          />
          <InputWithSaveButton
              label={"Business Website"}
              name={"website"}
              isLoading={isLoading}
              value={restaurant?.contact?.website}
              onSave={(value) => updateRestaurant({website: value})}
          />
        </Stack>
      </DashboardBasePanel>
  )
}

export default StoreContactUpdatePanel;
