import React from "react";
import { Skeleton, SkeletonText, SkeletonCircle, Flex, Stack } from "@chakra-ui/react"
import {LogoDefault} from "../../components/logos";
import {Panel} from "../../components/blocks";

function MainDashboardLoader() {

  return (
      <Flex
          flex={1}
      >
          {/*  Side bar*/}
        <Stack
            width={"250px"}
            spacing={8}
            padding={"10px 15px 20px 15px"}
        >
          <LogoDefault/>
          <Skeleton height={"30px"} />
          <Skeleton height={"30px"} />
          <Skeleton height={"30px"} />
          <Skeleton height={"30px"} />
          <Skeleton height={"30px"} />
          <Skeleton height={"30px"} />
        </Stack>
        <Stack
            flex={1}
            spacing={30}

            // marginLeft={"40px"}
            padding={"30px 25px"}
        >

          <Skeleton height={"30px"} width={"250px"}/>

          <Panel>
            <SkeletonText mt={4} noOfLines={4} spacing={4}/>
          </Panel>
          <Panel>
            <SkeletonCircle padding={6} boxShadow={"lg"} bg={"white"}/>
            <SkeletonText mt={4} noOfLines={4} spacing={4}/>
          </Panel>
          <Panel>
            <Skeleton height={"60px"} width={"100px"} />
            <Skeleton height={"90px"} />
          </Panel>
          <Panel>
            <Skeleton height={"100px"} />
          </Panel>
          {/*<Skeleton height={"60px"} />*/}
          {/*<Skeleton height={"60px"} />*/}
          {/*<Skeleton height={"60px"} />*/}
          {/*<Skeleton height={"60px"} />*/}
        </Stack>
      </Flex>
  )
}

export default MainDashboardLoader;
