import React, {useContext, useState} from "react";
import {MenuItemGroupUpdate} from "../../../models/MenuItemGroup";
import {Button, FormControl, FormErrorMessage, FormLabel, Input, Stack} from '@chakra-ui/react'
import {ApiContext} from "../../../providers/ApiProvider";
import {useFormik} from "formik";
import {isEmpty} from "lodash-es";
import {NotificationContext} from "../../../providers/NotificationProvider";


interface Props {
  restaurantId: string,
  onComplete: () => void,
}
function CreateMenuGroupForm(props : Props) {
  const {apiService} = useContext(ApiContext);
  const {success, error} = useContext(NotificationContext);
  const {restaurantId, onComplete} = {...props}
  const [isCategoriesLoading, setIsCategoriesLoading] = useState(false)


  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
      variations: []
    },
    onSubmit: onSubmit,
    validate: validate,
  })

  function validate(values) {
    let errors = {};

    if (isEmpty(values.name)) {
      errors.name = "Item name cannot be empty"
    }

    return errors
  }

  function onSubmit(values) {
    const menuGroup = new MenuItemGroupUpdate(values.name, values.description, 0);
    apiService.menuItemGroupUpdate(restaurantId, menuGroup)
        .then(r => {
          success(null, r.message)
          onComplete(r.data)
        })
        .catch(e => error(null, e.message))
        .finally(() => setIsCategoriesLoading(false))
  }

  return (
      <div style={{display: 'flex', flexDirection: 'column', flex: 1, justifyContent: 'center'}}>
        <Stack>
          <FormControl >
            <FormLabel>Category Name</FormLabel>
            <Input
                id="name"
                name="name"
                placeholder="Category Name e.g. Desserts"
                onChange={formik.handleChange}
            />
            <FormErrorMessage>{formik.errors.name}</FormErrorMessage>
          </FormControl>
          <FormControl >
            <FormLabel>Description</FormLabel>
            <Input
                id="description"
                name="description"
                placeholder="Category Description e.g. Our delicious handmade desserts you all love!"
                onChange={formik.handleChange}
            />
            <FormErrorMessage>{formik.errors.description}</FormErrorMessage>
          </FormControl>
          <Button type="submit" isLoading={formik.isSubmitting || isCategoriesLoading} onClick={formik.handleSubmit}>Add Category</Button>
        </Stack>
      </div>
  )
}

export default CreateMenuGroupForm;
