import {Restaurant} from "../../models/models";
import React, {useContext, useEffect, } from "react";
import {
  AlertIcon, AlertTitle,
  Stack, Alert, Text,
} from "@chakra-ui/react";
import {ApiContext} from "../../providers/ApiProvider";
import {DashboardBase, DashboardBasePanel} from "./components/DashboardBase";

interface Props {
  restaurant: Restaurant | null
}
function DiscountsDashboard(props : Props) {

  const {restaurant=null} = {...props};

  useEffect(() => {
  }, [restaurant])

  return (
      <DashboardBase title={"Discounts"}>
        <DashboardBasePanel
            title={"Coming Soon"}
        >
          <Text>
            Create discounts and attract new customers!
          </Text>
        </DashboardBasePanel>
      </DashboardBase>
  )
}


export default DiscountsDashboard;
