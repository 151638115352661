import axios, {AxiosInstance} from 'axios';
import ServiceHandler from './ServiceHandler';
import Logger from '../utils/Logger';
import {
  Restaurant,
  RestaurantAccount,
  MenuItem,
  MenuItemGroupUpdate,
  MenuItemGroup,
  RestaurantCollection,
  OrderSettings, Order
} from "../models/models";
import PostRestaurantOnBoardRequest from "../models/requests/PostRestaurantOnBoardRequest";
import PostUpdateRestaurant from "../models/requests/PostUpdateRestaurant";

class ApiService {
  logger = new Logger(ApiService.name);
  axiosInstance: AxiosInstance;


  constructor(axiosInstance: AxiosInstance) {
    this.axiosInstance = axiosInstance
  }

  // -----------------------------------------
  // Users
  // -----------------------------------------

  async userAccountSignUp(email: string, password: string, confirm_password: string): Promise<{ token: string, user: RestaurantAccount }> {
    const response = await this.axiosInstance.post(`/auth/sign-up`,
        {
          email: email, password: password, confirm_password: confirm_password
        }
    ).catch(ServiceHandler.handleServiceError);


    return ServiceHandler.handleAxiosResponse(response)
  }

  async userAccountSignIn(email: string, password: string): Promise<{ token: string, user: RestaurantAccount }> {
    const response = await this.axiosInstance.post(`/auth/sign-in`,
        {
          email: email, password: password
        }
    ).catch(ServiceHandler.handleServiceError);


    return ServiceHandler.handleAxiosResponse(response)
  }

  async userAccountSendVerificationMail(username: string): Promise<any> {
    const response = await this.axiosInstance.post(`/auth/send-verify`,
        {
          username: username
        }
    ).catch(ServiceHandler.handleServiceError);
    return ServiceHandler.handleAxiosResponse(response, false)
  }

  async userAccountVerifyEmail(id: string): Promise<any> {
    const response = await this.axiosInstance.post(`/auth/verify`,
        {
          verification_id: id
        }
    ).catch(ServiceHandler.handleServiceError);
    return ServiceHandler.handleAxiosResponse(response, false)

  }

  async userAccountResetPassword(email: string): Promise<any> {
    const response = await this.axiosInstance.post(`/auth/password-reset`,
        {
          email: email
        }
    ).catch(ServiceHandler.handleServiceError);
    return ServiceHandler.handleAxiosResponse(response, false)

  }

  async userAccountGetCurrent(token): Promise<{user: RestaurantAccount, firebase_token: string}> {
    const response = await this.axiosInstance.get(`/auth/current-user`, {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        }
    ).catch(ServiceHandler.handleServiceError);


    return ServiceHandler.handleAxiosResponse(response)
  }

  async accountUpdate(restaurantId: string, email: string, phone: string): Promise<RestaurantAccount> {
    const response = await this.axiosInstance.put(`/restaurant/${restaurantId}/account`,
        {
          phone: phone,
          email: email,
        }
    ).catch(ServiceHandler.handleServiceError);
    return ServiceHandler.handleAxiosResponse(response, false)
  }

  async accountDelete(restaurantId: string): Promise<RestaurantAccount> {
    const response = await this.axiosInstance.delete(`/restaurant/${restaurantId}/account`,
    ).catch(ServiceHandler.handleServiceError);
    return ServiceHandler.handleAxiosResponse(response, false)
  }

  async accountGetByRestaurantId(restaurantId: string): Promise<RestaurantAccount> {
    const response = await this.axiosInstance.get(`/restaurant/${restaurantId}/account`,
    ).catch(ServiceHandler.handleServiceError);
    return ServiceHandler.handleAxiosResponse(response, true)
  }

  async accountSetup(restaurantId: string): Promise<any> {
    const response = await this.axiosInstance.post(`/restaurant/${restaurantId}/account/setup`,
        {}
    ).catch(ServiceHandler.handleServiceError);
    return ServiceHandler.handleAxiosResponse(response, false)

  }

  async accountRequestPhoneVerificationCode(restaurantId: string): Promise<any> {
    const response = await this.axiosInstance.post(`/restaurant/${restaurantId}/account/phone-verification`,
        {}
    ).catch(ServiceHandler.handleServiceError);
    return ServiceHandler.handleAxiosResponse(response, false)
  }

  async accountSubmitPhoneVerificationCode(restaurantId: string, code: string): Promise<any> {
    const response = await this.axiosInstance.post(`/restaurant/${restaurantId}/account/phone-verification/verify`,
        {code: code}
    ).catch(ServiceHandler.handleServiceError);
    return ServiceHandler.handleAxiosResponse(response, false)
  }

  async accountSetupReauth(restaurantId: string, email: string): Promise<any> {
    const response = await this.axiosInstance.post(`/${restaurantId}/account/setup/reauth`,
        {}
    ).catch(ServiceHandler.handleServiceError);
    return ServiceHandler.handleAxiosResponse(response, true)

  }

  async restaurantGetForCurrentUser(restaurantId = null): Promise<Restaurant> {
    let url = `/restaurant`
    if (restaurantId) {
      url += `/${restaurantId}`
    }
    const response = await this.axiosInstance.get(url)
        .catch(ServiceHandler.handleServiceError)

    const data = ServiceHandler.handleAxiosResponse(response)
    return Object.assign(new Restaurant(), data);
  }


  async restaurantGetByIdForCurrentUser(restaurantId): Promise<Restaurant> {
    let url = `/restaurant/${restaurantId}`
    const response = await this.axiosInstance.get(url)
        .catch(ServiceHandler.handleServiceError)

    const data = ServiceHandler.handleAxiosResponse(response)
    return Object.assign(new Restaurant(), data);
  }

  // -----------------------------------------
  // Restaurant
  // -----------------------------------------

  async restaurantGetOnBoardInputData(): Promise<Array<any>> {
    const response = await this.axiosInstance.get(`/restaurant/on-board`)
        .catch(ServiceHandler.handleServiceError)

    return ServiceHandler.handleAxiosResponse(response)
  }

  async restaurantPlacesGetEstablishmentsSearch(query: string, sessionToken: string): Promise<{ sessionToken: string, autocompletePredictions: Array<any> }> {
    const response = await this.axiosInstance.get(`/places/restaurants/search`, {
      params: {
        query: query, sessionToken: sessionToken
      }
    })
        .catch(ServiceHandler.handleServiceError)

    return ServiceHandler.handleAxiosResponse(response)
  }

  async restaurantOnBoardSubmit(postRequest: PostRestaurantOnBoardRequest): Promise<Restaurant> {
    const response = await this.axiosInstance.post(`/restaurant`,
        postRequest
    ).catch(ServiceHandler.handleServiceError);


    return ServiceHandler.handleAxiosResponse(response, false)
  }

  async restaurantUpdate(restaurantId: string, postRequest: PostUpdateRestaurant): Promise<Restaurant> {
    const response = await this.axiosInstance.put(`/restaurant/${restaurantId}`,
        postRequest
    ).catch(ServiceHandler.handleServiceError);


    return ServiceHandler.handleAxiosResponse(response, false)
  }

  async restaurantGetUploadS3Link(restaurantId: string) {
    const response = await this.axiosInstance.get(`/restaurant/${restaurantId}/image-upload-url`,
    ).catch(ServiceHandler.handleServiceError);
    return ServiceHandler.handleAxiosResponse(response, true)
  }

  async restaurantUploadImageS3(restaurantId: string) {
    const response = await this.axiosInstance.get(`/restaurant/${restaurantId}/image`,
    ).catch(ServiceHandler.handleServiceError);
    return ServiceHandler.handleAxiosResponse(response, true)
  }

  // -----------------------------------------
  // Orders
  // -----------------------------------------

  async ordersGetByRestaurantId(restaurantId: any) {
    const response = await this.axiosInstance.get(`/restaurant/${restaurantId}/orders`,)
        .catch(ServiceHandler.handleServiceError);
    return ServiceHandler.handleAxiosResponse(response, true)
  }

  async ordersGetLiveByRestaurantId(restaurantId: any) {
    const response = await this.axiosInstance.get(`/restaurant/${restaurantId}/orders-live`,)
        .catch(ServiceHandler.handleServiceError);
    return ServiceHandler.handleAxiosResponse(response, true)
  }



  async ordersWeekStats(restaurantId: string) : Promise<{accepted: Array<Order>, cancelled: Array<Order>, revenue: number}> {
    const response = await this.axiosInstance.get(`/restaurant/${restaurantId}/orders/stats/weekly`,)
        .catch(ServiceHandler.handleServiceError);
    return ServiceHandler.handleAxiosResponse(response, false)
  }

  async ordersAccept(orderId: any) {
    const response = await this.axiosInstance.post(`/orders/${orderId}/accept`,)
        .catch(ServiceHandler.handleServiceError);
    return ServiceHandler.handleAxiosResponse(response, false)
  }

  async ordersReject(orderId: any) {
    const response = await this.axiosInstance.post(`/orders/${orderId}/reject`,)
        .catch(ServiceHandler.handleServiceError);
    return ServiceHandler.handleAxiosResponse(response, false)
  }

  async ordersCancel(orderId: any) {
    const response = await this.axiosInstance.post(`/orders/${orderId}/cancel`,)
        .catch(ServiceHandler.handleServiceError);
    return ServiceHandler.handleAxiosResponse(response, false)
  }

  async ordersReady(orderId: any) {
    const response = await this.axiosInstance.post(`/orders/${orderId}/ready`,)
        .catch(ServiceHandler.handleServiceError);
    return ServiceHandler.handleAxiosResponse(response, false)
  }

  async ordersComplete(orderId: any) {
    const response = await this.axiosInstance.post(`/orders/${orderId}/complete`,)
        .catch(ServiceHandler.handleServiceError);
    return ServiceHandler.handleAxiosResponse(response, false)
  }

  // -----------------------------------------
  // Opening hours
  // -----------------------------------------

  async openHoursUpdate(restaurantId: any, openHours: any) {
    this.logger.info(`Calling update open hours with restaurantId: ${restaurantId} openHours: ${openHours}`)
    const response = await this.axiosInstance.post(`/restaurant/${restaurantId}/open-hours`,
        openHours
    ).catch(ServiceHandler.handleServiceError);
    return ServiceHandler.handleAxiosResponse(response, false)
  }

  async collectionUpdate(restaurantId: any, collection: RestaurantCollection) {
    const response = await this.axiosInstance.put(`/restaurant/${restaurantId}/collection`,
        collection
    ).catch(ServiceHandler.handleServiceError);
    return ServiceHandler.handleAxiosResponse(response, false)
  }

  async collectionGet(restaurantId: any) : Promise<RestaurantCollection> {
    const response = await this.axiosInstance.get(`/restaurant/${restaurantId}/collection`,)
        .catch(ServiceHandler.handleServiceError);
    return ServiceHandler.handleAxiosResponse(response, true)
  }

  async openHoursGet(restaurantId: any) {
    const response = await this.axiosInstance.get(`/restaurant/${restaurantId}/open-hours`,
    ).catch(ServiceHandler.handleServiceError);


    return ServiceHandler.handleAxiosResponse(response, true)
  }

  async orderSettingsGet(restaurantId: any) : Promise<OrderSettings> {
    const response = await this.axiosInstance.get(`/restaurant/${restaurantId}/order-settings`,
    ).catch(ServiceHandler.handleServiceError);


    return ServiceHandler.handleAxiosResponse(response, true)
  }

  async orderSettingsUpdate(restaurantId: any, orderSettings: OrderSettings) : Promise {
    const response = await this.axiosInstance.post(`/restaurant/${restaurantId}/order-settings`, {
      orderSettings
        }
    ).catch(ServiceHandler.handleServiceError);


    return ServiceHandler.handleAxiosResponse(response, false)
  }

  // -----------------------------------------
  // Menu
  // -----------------------------------------

  async menuUpdate(restaurantId: any, menuItems: Array<MenuItem>) {
    const response = await this.axiosInstance.post(`/restaurant/${restaurantId}/menu`,
        menuItems
    ).catch(ServiceHandler.handleServiceError);


    return ServiceHandler.handleAxiosResponse(response, false)

  }

  async menuGet(restaurantId: any) {
    const response = await this.axiosInstance.get(`/restaurant/${restaurantId}/menu`,
    ).catch(ServiceHandler.handleServiceError);


    return ServiceHandler.handleAxiosResponse(response, true)
  }

  async menuItemGroupUpdate(restaurantId: any, menuItemGroup: MenuItemGroupUpdate | MenuItemGroup) {
    const response = await this.axiosInstance.post(`/restaurant/${restaurantId}/menu/groups`,
        menuItemGroup
    ).catch(ServiceHandler.handleServiceError);


    return ServiceHandler.handleAxiosResponse(response, false)

  }

  async menuItemGroupDelete(restaurantId: any, id: any) {
    const response = await this.axiosInstance.delete(`/restaurant/${restaurantId}/menu/groups/${id}`,
    ).catch(ServiceHandler.handleServiceError);


    return ServiceHandler.handleAxiosResponse(response, true)
  }

  // -----------------------------------------
  // Enquiries
  // -----------------------------------------

  async enquiryContactPost(email, subject, enquiry) {
    const response = await this.axiosInstance.post(`/enquiries/contact`,
        {email, subject, enquiry}
    ).catch(ServiceHandler.handleServiceError);


    return ServiceHandler.handleAxiosResponse(response, true)
  }
}

export default ApiService;
