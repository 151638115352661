class FormatterUtil {

  static formatCurrency(value, currency="GBP", locales="en-GB") {
    if (value == null) {
      return null
    }
    const formatter = new Intl.NumberFormat(locales, {
      style: 'currency',
      currency: currency,

      // These options are needed to round to whole numbers if that's what you want.
      //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
      //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    return formatter.format(value)
  }
}

export default FormatterUtil
