import React from "react";
import {Box, Flex, Heading, Stack, Text, Button, Skeleton} from "@chakra-ui/react";
import {Panel} from "../../../components/blocks";
import ErrorMessageBoundary from "../../../components/error/ErrorMessageBoundary";
import Loader from "../../../components/loading/Loader";

interface DashboardBaseProps {
  title: string,
  subtitle?: string,
  children?: any,
}

export function DashboardBase({title, subtitle, children}: DashboardBaseProps) {
  return (
      <Stack spacing={5} flex={1} bg={"#f9fafa"}
             padding={["20px 0 25px 0", "25px 0 30px 0", "30px 15px 35px 15px"]}
             id={title}
      >
        <Heading marginLeft={["10px", "10px", "0"]} size={"xl"}>{title}</Heading>
        {subtitle && <Text paddingLeft={["10px", "10px", "0"]} fontSize="md" color="gray.500">{subtitle}</Text>}
        {children}
      </Stack>
  )
}


interface DashboardBasePanelProps {
  title: string,
  description?: string,
  actionButtonText?: string,
  onAction?: () => void,
  isLoading?: boolean,
  children: any,
}

export function DashboardBasePanel(props: DashboardBasePanelProps) {
  const {title, description, actionButtonText="Refresh", onAction=undefined, isLoading=false, children} = {...props}
  return (
      <Panel
          padding={["10px 15px 20px 15px", "10px 15px 20px 15px", "30px"]}
          borderRadius={"5px"}
          borderColor={"#f1f1f1"}
          borderWidth={"1px"}
          boxShadow={"none"}
      >
        <Stack
            padding={"15px 0 20px 0"}
        >
          <Flex
              direction={"row"}
              justify={"space-between"}
          >
            <Heading  size={"md"} paddingBottom={2}>{title}</Heading>
            {onAction && <Button onClick={onAction}>{actionButtonText}</Button>}
          </Flex>
          {description && <Text>{description}</Text>}
        </Stack>
        <ErrorMessageBoundary>
          <Loader isLoading={isLoading}>
            {children}
          </Loader>
        </ErrorMessageBoundary>
      </Panel>
  )
}
