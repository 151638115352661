export default {
  HOME: "/",

  AUTH: "/auth",
  AUTH_SIGN_IN: "/auth/sign-in",
  AUTH_SIGN_UP: "/auth/sign-up",
  AUTH_FORGOTTEN_PASSWORD: "/auth/password-reset",

  DASHBOARD: "/dashboard",
  ACCOUNT_DASHBOARD: "/account/dashboard",
  ACCOUNT_NEW_STORE: "/account/new-store",
  ACCOUNT_REAUTH: "/account/reauth",
}
