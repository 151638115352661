import React, {createContext} from 'react';
import {useBreakpointValue} from '@chakra-ui/react';

export const ResponsiveContext = createContext({
  isMobile: true,
})

export default function ResponsiveProvider(props) {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  return (
      <ResponsiveContext.Provider
          value={{
            isMobile: isMobile,
          }}
      >
        {props?.children}
      </ResponsiveContext.Provider>
  )
}
