import {Restaurant} from "../../models/models";
import React, {useContext, useEffect, useState} from "react";
import {AuthContext} from "../../providers/AuthProvider";
import {
  Alert, AlertDescription,
  AlertIcon, AlertTitle,
  Button, CloseButton, Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack
} from "@chakra-ui/react";
import VerifyEmailForm from "../auth/components/VerifyEmailForm";
import {isEmpty} from "lodash-es";
import {Link as RouterLink, Route, Switch, useLocation} from "react-router-dom";
import routes from "../../constants/routes";
import SummaryDashboard from "./SummaryDashboard";
import OrdersDashboard from "./OrdersDashboard";
import DiscountsDashboard from "./DiscountsDashboard";
import MenuDashboard from "./MenuDashboard";
import OpenHoursDashboard from "./OpenHoursDashboard";
import AccountSettingsDashboard from "./AccountSettingsDashboard";
import NewRestaurantOnBoardingDrawer from "./NewRestaurantOnBoardingDrawer";
import {DASHBOARD_PAGES} from "./DashboardPage";
import SalesDashboard from "./SalesDashboard";
import ReviewsDashboard from "./ReviewsDashboard";
import {AddMenuAlert, AddOpeningHoursAlert} from "./DashboardAlerts";
import Loader from "../../components/loading/Loader";
import ErrorMessageBoundary from "../../components/error/ErrorMessageBoundary";
import {NotificationContext} from "../../providers/NotificationProvider";
import {ApiContext} from "../../providers/ApiProvider";
import {ResponsiveContext} from "../../providers/ResponsiveProvider";

interface DashboardProps {
}

function Dashboards(props: DashboardProps) {
  const {isVerified, isAuthenticated} = useContext(AuthContext);
  const {success, error} = useContext(NotificationContext);
  const {apiService} = useContext(ApiContext);
  const {isMobile} = useContext(ResponsiveContext);
  const location = useLocation();

  const [showAddRestaurantDrawer, setShowAddRestaurantDrawer] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [fetchRestaurantError, setFetchRestaurantError] = useState(null);
  const [restaurant, setRestaurant] = useState(null)
  const [errorMessage, setErrorMessage] = useState(null)

  useEffect(() => {
    if (isAuthenticated) {
      if (restaurant == null || restaurant?.id == null) {
        fetchRestaurant();
      }
    }
  }, [isAuthenticated, location.pathname]);

  useEffect(() => {
    if (!isLoading && isEmpty(errorMessage)) {
      setShowAddRestaurantDrawer(restaurant == null || restaurant?.id == null)
    }
  }, [restaurant, errorMessage, isLoading])

  function fetchRestaurant() {
    setIsLoading(true);
    setFetchRestaurantError(null)
    apiService.restaurantGetForCurrentUser()
        .then(r => {
          if (r == null || r?.id == null) {
            setRestaurant(null)
          } else {
            setRestaurant(r)
          }
        })
        .catch(e => {
          setRestaurant(null)
          setErrorMessage(e.message)
        })
        .finally(() => setIsLoading(false))
  }

  return (
      <Stack id={"dashboard_stack"} flex={1} align={"stretch"} width={"100%"} bg={"#f9fafa"}>

        {errorMessage &&
        <Alert status="error">
          <AlertIcon/>
          <Stack>
            <AlertTitle mr={2}>Could not fetch your store details!</AlertTitle>
            <AlertDescription>
              {errorMessage}.
              <Button onClick={fetchRestaurant}>Click here to try again</Button>
            </AlertDescription>
          </Stack>
          <CloseButton position="absolute" right="8px" top="8px"/>
        </Alert>
        }

        <Loader isLoading={isLoading || !isAuthenticated}>

          <Switch>
            <Route exact path={`${routes.DASHBOARD}/${DASHBOARD_PAGES.DASH.eventKey}`}>
              <ErrorMessageBoundary>
                <SummaryDashboard restaurant={restaurant} isLoading={isLoading}/>
              </ErrorMessageBoundary>
            </Route>
            <Route path={`${routes.DASHBOARD}/${DASHBOARD_PAGES.SALES.eventKey}`}>
              <ErrorMessageBoundary>
                <SalesDashboard restaurant={restaurant}/>
              </ErrorMessageBoundary>
            </Route>
            <Route path={`${routes.DASHBOARD}/${DASHBOARD_PAGES.LIVE_ORDERS.eventKey}`}>
              <ErrorMessageBoundary>
                <OrdersDashboard restaurant={restaurant}/>
              </ErrorMessageBoundary>
            </Route>
            <Route path={`${routes.DASHBOARD}/${DASHBOARD_PAGES.DISCOUNTS.eventKey}`}>
              <ErrorMessageBoundary>
                <DiscountsDashboard restaurant={restaurant}/>
              </ErrorMessageBoundary>
            </Route>
            <Route path={`${routes.DASHBOARD}/${DASHBOARD_PAGES.MENU.eventKey}`}>
              <ErrorMessageBoundary>
                <MenuDashboard restaurant={restaurant}/>
              </ErrorMessageBoundary>
            </Route>
            <Route path={`${routes.DASHBOARD}/${DASHBOARD_PAGES.REVIEWS.eventKey}`}>
              <ErrorMessageBoundary>
                <ReviewsDashboard restaurant={restaurant}/>
              </ErrorMessageBoundary>
            </Route>
            {/*<Route path={`${routes.DASHBOARD}/${DASHBOARD_PAGES.STORE.eventKey}`}>*/}
            {/*  <StoreDetailsDashboard restaurant={restaurant} restaurantId={restaurant.id}/>*/}
            {/*</Route>*/}
            <Route path={`${routes.DASHBOARD}/${DASHBOARD_PAGES.OPEN_HOURS.eventKey}`}>
              <ErrorMessageBoundary>
                <OpenHoursDashboard restaurant={restaurant}/>
              </ErrorMessageBoundary>
            </Route>
            <Route path={`${routes.DASHBOARD}/${DASHBOARD_PAGES.ACCOUNT_SETTINGS.eventKey}`}>
              <ErrorMessageBoundary>
                <AccountSettingsDashboard restaurantId={restaurant?.id}/>
              </ErrorMessageBoundary>
            </Route>
            <Route>
              <ErrorMessageBoundary>
                <SummaryDashboard restaurant={restaurant}/>
              </ErrorMessageBoundary>
            </Route>
          </Switch>

          <NewRestaurantOnBoardingDrawer
              isOpen={showAddRestaurantDrawer}
              onComplete={() => {
                setShowAddRestaurantDrawer(false)
                fetchRestaurant()
              }}
          />

        </Loader>
      </Stack>
  )

}

export default Dashboards;
