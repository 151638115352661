import React, {useContext} from 'react';
import './App.css';
import {Switch, Route, useHistory, Redirect} from 'react-router-dom'
import routes from "./constants/routes";
import Home from "./pages/Home";
import DashboardPage from "./pages/dashboard/DashboardPage";
import './custom-theme.less';
import NewRestaurantOnBoardingDrawer from "./pages/dashboard/NewRestaurantOnBoardingDrawer";
import Auth from "./pages/auth/Auth";
import {Flex} from '@chakra-ui/react'
import {AuthContext} from "./providers/AuthProvider";

function App() {
  const history = useHistory();

  const {isAuthenticated} = useContext(AuthContext)


  return (
      <Flex
          flex={1}
          direction={"column"}
          width={"100%"}
      >
        <Switch>
          <Route path={routes.ACCOUNT_DASHBOARD}>
            <Redirect to={routes.DASHBOARD}/>
          </Route>
          <Route path={routes.ACCOUNT_REAUTH}>
            <Redirect to={routes.DASHBOARD}/>
          </Route>
          <Route path={`${routes.DASHBOARD}/:page`}>
            <DashboardPage/>
          </Route>
          <Route path={`${routes.DASHBOARD}`}>
            <DashboardPage/>
          </Route>
          {/*<Route path={routes.ACCOUNT_NEW_STORE}>*/}
          {/*  <NewRestaurantOnBoardingDrawer onComplete={() => history.push(routes.DASHBOARD)}/>*/}
          {/*</Route>*/}
          <Route path={`${routes.AUTH}`}>
            <Auth/>
          </Route>

          {isAuthenticated ?
              <Route>
                <DashboardPage/>
              </Route>
                :
              <Route>
                <Home/>
              </Route>
          }
        </Switch>
      </Flex>
  )
}

export default App;
