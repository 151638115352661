import React, {useContext, useEffect, useState} from 'react';
import {colors} from "../constants/styles";
import {useHistory} from 'react-router-dom'
import routes from "../constants/routes";
import {AuthContext} from "../providers/AuthProvider";
import {
  Button,  Flex,  Heading, Container, Stack,
  Box, FormControl, FormLabel, Input, HStack, InputLeftAddon, InputGroup, Text,
  Image, Icon, Link,
  Table,  Thead,  Tbody,  Tfoot,  Tr,  Th,  Td,  TableCaption,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Alert, AlertIcon, AlertTitle, AlertDescription, Textarea
} from '@chakra-ui/react';
import {HeroBackground, HomePageDisplayText, Panel} from '../components/blocks'
import {
  AiFillCalculator,
  AiFillCheckCircle,
  AiFillCloseCircle, AiFillHeart,
  AiFillShop, AiFillTrophy,
  AiOutlineCheck, AiOutlineLineChart, AiOutlineUsergroupAdd
} from "react-icons/ai";
import {LogoDefault} from "../components/logos";
import platformImage from '../assets/filtericons/001-online-lesson.png'
import onlineOrderImage from '../assets/filtericons/002-online-order.png'
import audienceImage from '../assets/filtericons/003-audience.png'
import engagementImage from '../assets/filtericons/004-engagement.png'
import halalImage from '../assets/filtericons/005-halal.png'
import reputationImage from '../assets/filtericons/006-reputation.png'
import questionHeroImage from '../assets/images/question-hero.jpg'
import Header from "../components/Header";
import {ResponsiveContext} from "../providers/ResponsiveProvider";
import {ApiContext} from "../providers/ApiProvider";



const currentFormatter = new Intl.NumberFormat('en-GB', {
  style: 'currency',
  currency: 'GBP',

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

function Home() {

  const {isAuthenticated} = useContext(AuthContext);
  const history = useHistory();

  // useEffect(() => {
  //   if (isAuthenticated) {
  //     history.push(routes.DASHBOARD)
  //   }
  // }, [isAuthenticated])


  return (
      <>
        <div style={{
          display: 'flex', flexDirection: 'column', flex: 1, justifyContent: 'space-around', backgroundColor: colors.WHITE, overflowX: 'hidden'
        }}>
          <Stack spacing={30} >
            <HeroBlock/>
            <Stack spacing={100} align={"center"} textAlign={"center"}>
              <PromoBlock/>
              <WhyBlock/>
              <SignUpNowBlock/>
              <FeaturesBlock/>
              <SavingBlock/>
              <FeatureComparisonBlock/>
              <PricingBlock/>
              <SignUpNowBlock/>
              <FAQBlock/>
              <ContactBlock/>
            </Stack>
          </Stack>
          <FooterBlock/>

        </div>
      </>
  )

}

function HeroBlock() {
  const history = useHistory();
  return (
      <HeroBackground style={{height: '60vh', backgroundColor: colors.WHITE}}>
        <Flex flex={1} direction={'column'} justify={'flex-start'}
              height={"60vh"} bg={"linear-gradient(180deg, rgba(0,0,0,0.7) 2%, rgba(255,255,255,0) 40%)"}
        >
          <Header/>
          <Box width={["100%", "100%", "50%"]} padding={["20px 15px", "40px 15px", "10% 15px"]} >
            <Stack spacing={4}
                   height={"60%"}
                   direction={"column"}
                   padding={"12vh 25px"}
                   justify={"center"}
                   flex={1}
            >
              <Heading as={"h1"} size={"2xl"} color={"white"} bg={"rgba(0,0,0, 0.8)"} padding={"20px"}>
                Halal food, all in one platform.<br/>
                <u>No monthly fees.</u>
              </Heading>
              <Heading as={"h4"} size={"md"} color={"white"} bg={"rgba(0,0,0, 0.8)"} padding={"20px"}>
                Everything you need to digitalise your business.
                <br/><br/>
                Empowering small businesses with all the good stuff without the price.
              </Heading>
            </Stack>
            <Button colorScheme={"primary"} marginTop={"20px"} marginLeft={"25px"} onClick={() => history.push(routes.AUTH_SIGN_UP)}>Get Started</Button>
          </Box>
        </Flex>
      </HeroBackground>
  )
}

function PromoBlock() {
  return (

      <HomeBlock justify={"center"} align={"center"} height={"30vh"} spacing={30}>
        <Heading as="h2" size="2xl" textAlign={"center"} className={"animate__animated animate__fadeInUp"}>
          Join a Halal products market with an expected growth of
          {/*Selling online should not cost you your business.*/}
          {/*<Heading as="h2" size="xl" color={"primary.500"}><u>Halal Munch is here to change that.</u></Heading>*/}
        </Heading>
        <Heading as="h2" size="4xl" textAlign={"center"} color={"primary.500"} className={"animate__animated animate__fadeInUp"} textShadow={"5px 5px rgb(0 0 0 / 20%)"}>
          $2 trillion
        </Heading>
      </HomeBlock>
  )
}

function WhyBlock() {
  return (
      <Flex flex={1} bg={`url(${questionHeroImage})`}
            bgSize={"cover"} bgRepeat={"none"}>
        <HomeBlock minHeight={"40vh"} flex={1}
                   bg={"linear-gradient(180deg, rgba(255,255,255,1) 2%, rgba(255,255,255,0.7) 40%)"}
        >
          <Stack spacing={6}
                 flex={1}
                 direction={'column'}
                 align={'center'}
                 justify={'center'}
                 w={"100%"}
                 id={"features"}
                 textAlign={"center"}
          >
            <Heading as={"h2"} size={"xl"} paddingBottom={10}>Why partner with Halal Munch?</Heading>
            <Stack spacing={10}>
              <Feature
                  title={"Free Online Platform"}
                  image={platformImage}
              >
                Save money by creating and customising your own online
                web page and integrate with our app.
              </Feature>
              <Feature
                  title={"Free Order Management and Tracking"}
                  image={onlineOrderImage}
              >Leverage our in-house order management system, tailored
                to your needs
              </Feature>
              <Feature title={"User Insights"}
                       image={engagementImage}

              >
                Know what your customer likes with analytics and insights through our intelligent machine
                learning
              </Feature>
              <Feature title={"Targeted Audience"}
                       image={audienceImage}

              >
                Halal Munch targets the ever growing Halal food target audience. An estimated 1 trillion dollar
                industry, and we want you to get invloved.
              </Feature>
              <Feature title={"Halal Food Community"}
                       image={halalImage}
              >
                Be part of a community that loves exploring new food. An excellent way to market your business
                through word-of-mouth and reach an ever growing Halal food community.
              </Feature>
              <Feature title={"Reputable Platform"}
                       image={reputationImage}
              >
                Join a platform that's built by the people, for the people.
                Over a selection of 3000 restaurants across the UK rated, reviewed, and approved by Halal food lovers.
              </Feature>
            </Stack>
          </Stack>
        </HomeBlock>
      </Flex>

  )
}

function SignUpNowBlock() {
  const history = useHistory();

  return (
      <HomeBlock>
        <Panel
            width={"100%"}
            backgroundColor={"primary.500"}
            height={"100px"}
        >
          <Flex
              height={"100%"}
              justify={"space-between"}
              align={"center"}
              padding={"0 10px"}
          >
            <Heading size={"md"} color={"white"}>Become a Halal Munch Partner today</Heading>
            <Button onClick={() => history.push(routes.AUTH_SIGN_UP)} minWidth={"110px"}>Sign Up Now!</Button>
          </Flex>
        </Panel>
      </HomeBlock>
  )
}


export function Feature({title, icon, image, children}) {
  return (
      <HStack spacing={5} width={"100%"}>
        { image ?
            <Image loading="lazy"src={image} width={["30px", "35px", "40px"]} height={["30px", "35px", "40px"]}/>
            :
            <Icon
                as={icon || AiOutlineCheck} boxSize={"1.5em"}
                color={"green"}
                size={"lg"}
            />
        }
        <Stack align={"flex-start"} textAlign={"left"}>
          <Text fontSize={"lg"} fontWeight={"bold"}>{title}</Text>

          {children && <Text>{children}</Text>}
        </Stack>
      </HStack>
  )
}

function FeaturesBlock() {
  return (
      <HomeBlock>
        <Stack spacing={6} width={"100%"}
               align={'center'}
               justify={'center'}
        >
          <Heading as={"h2"} size={"xl"}>Features tailored to your needs</Heading>
          <Stack spacing={1} width={"100%"}>
            <Text fontSize={"lg"}>It doesn't take much to get setup with Halal Munch partners.</Text>
            <Text fontSize={"lg"}>Sign up, upload your menu, setup your opening hours - start taking orders. No setup fees. That simple.</Text>
          </Stack>
          <Stack spacing={4} width={"100%"}>

            <Feature title={"Online order management"}/>
            <Feature title={"Online order payments"}/>
            <Feature title={"Flexible payouts"}/>
            <Feature title={"Free Marketing"}/>
            <Feature title={"Halal Munch delivery partners (Coming Soon)"}/>
            <Feature title={"Discounted supplies and packaging (Coming Soon)"}/>
          </Stack>
        </Stack>
      </HomeBlock>
  )
}

function HowItWorksBlock() {
  return (
      <Stack spacing={6}
             flex={1}
             direction={'column'}
             align={'center'}
             justify={'center'}
             w={"100%"}
             id={"features"}
             bg={"white"}
             textAlign={"center"}
      >
        <Heading as={"h2"} size={"xl"}>How does Halal Munch work?</Heading>
        <Panel>
          <Stack>

          </Stack>
        </Panel>
      </Stack>

  )
}

function SavingBlock() {

  const [weeklyOrders, setWeeklyOrders] = useState(200);
  const [averageOrder, setAverageOrder] = useState(25.00);
  const [saving, setSaving] = useState("");

  const averageCommission = 0.14; // 14%
  const chargePerOrder = 0.5; //50p
  const currentFormatter = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });

  useEffect(() => {
    const yearlyOrders = weeklyOrders * 52;
    const yearlyIncome = yearlyOrders * averageOrder;
    const hmCost = yearlyIncome * 0.05;
    const otherOrgCost = (yearlyIncome * averageCommission) + (yearlyOrders * chargePerOrder);
    const currentSaving = otherOrgCost - hmCost
    setSaving(currentFormatter.format(currentSaving))
  }, [weeklyOrders, averageOrder])

  return (
      <HomeBlock minHeight={"60vh"} align={"center"} justify={"center"} bg={"mediumspringgreen"} >
        <Flex flex={1} direction={'column'} align={'center'} justify={'center'} w={"100%"}>
          <Heading size={"2xl"} color={"gray.800"} paddingBottom={"20px"}
                   textShadow={"5px 5px rgb(0 0 0 / 20%)"}
          >
            More Money in <u>Your Pocket</u>
          </Heading>
          <Panel
              padding={"0 0 20px 0"}
              margin={"25px 50px"}
              minHeight={"300px"}
              maxWidth={"800px"}
              width={"100%"}
              boxShadow={"0 10px 30px rgba(0,0,0,0.4)"}
          >
            <Flex direction={'column'} w={"100%"}>
              <Flex bg={"#f5f5f5"} flex={1} align={'center'} justify={'center'} style={{borderTopLeftRadius: '10px', borderTopRightRadius: '10px'}}>
                <Stack spacing={4} align={'center'} style={{padding: '40px'}} >
                  <Heading size={"md"} >You could be <u>saving</u></Heading>
                  <HStack align={'flex-end'}>
                  <Heading size={"2xl"} color={"primary.500"}>{saving}</Heading>
                    <Text style={{fontSize: '1em'}} > each year *</Text>
                  </HStack>
                </Stack>
              </Flex>
              <Stack spacing={3} padding={5}>

                  <FormControl>
                    <FormLabel>Orders per week</FormLabel>
                    <Input type={"number"} placeholder={weeklyOrders} onChange={(event) => setWeeklyOrders(event.target.value)}/>
                  </FormControl>
                  <FormControl>
                    <FormLabel>Average order value</FormLabel>
                    <InputGroup>
                      <InputLeftAddon children={"£"}/>
                      <Input type={"number"} placeholder={averageOrder} onChange={(event) => setAverageOrder(event.target.value)}/>
                    </InputGroup>
                  </FormControl>
                  <Text size={"sm"} color={"gray.500"}>* based on average competitor pricing</Text>
                </Stack>
            </Flex>
          </Panel>
        </Flex>
      </HomeBlock>

  )
}

function PricingBlock() {

  const history = useHistory();

  return (
      <HomeBlock id={"pricing"} flex={1} direction={'column'} align={'center'} justify={'center'} w={"100%"} bg={"primary.500"} minHeight={"50vh"} >
        <Stack align={'center'} justify={'center'} textAlign={"center"} spacing={10}>
          <Heading as={"h2"} size={"2xl"} color={"gray.800"} textShadow={"5px 5px rgb(0 0 0 / 20%)"}><u>Pricing that works for you!</u></Heading>
          <Text fontSize={"xl"} color={"gray.900"} width={"90%"} textAlign={"center"} fontWeight={400}>
            Get started selling online for free. We want to make digitalisation affordable for small businesses.
            <br/>No monthly fees, no hidden charges, no hassle.
            <br/>Control when you want payouts.
          </Text>
          <Stack spacing={4} align={'center'} style={{padding: '40px'}}  bg={"white"} borderRadius={"10px"}>
            <HStack align={'flex-end'}>
              <Heading size={"3xl"} color={"gray.800"} textShadow={"5px 5px rgb(0 0 0 / 20%)"}>{currentFormatter.format(0)}</Heading>
              <Text style={{fontSize: '1.2em', fontWeight: 'bold'}} color={"gray.800"} textShadow={"5px 5px rgb(0 0 0 / 20%)"}> / month *</Text>
            </HStack>
            <Text color={"gray.900"}>* We do charge 5% processing and transaction fee per order</Text>
          </Stack>
        </Stack>
      </HomeBlock>

  )
}

function FeatureComparisonBlock() {


  const featurePricingData = [
    {
      featureName: "Online platform",
      free: true,
      competitor: true,
      premium: "",
    },
    {
      featureName: "Order management",
      free: true,
      competitor: true,
      premium: "",
    },
    {
      featureName: "Online payments",
      free: true,
      competitor: true,
      premium: "",
    },
    {
      featureName: "Flexible payouts",
      free: true,
      competitor: true,
      premium: "",
    },
    {
      featureName: "No monthly charge",
      free: true,
      competitor: false,
      premium: "",
    },
    {
      featureName: "Targeted Audience",
      free: true,
      competitor: false,
      premium: "",
    },
    {
      featureName: "Catered for small businesses",
      free: true,
      competitor: false,
      premium: "",
    },
    {
      featureName: "Focus on Halal Food",
      free: true,
      competitor: false,
      premium: "",
    },
  ]

  return (
    <HomeBlock>
      <Stack width={"100%"} align={"center"} textAlign={"center"}>
        <Heading as={"h2"} size={"xl"}>Feature comparison</Heading>

        <Text fontSize={"lg"} color={"gray.500"} width={"90%"} textAlign={"center"}>
          Take a look at what we offer compared to our market competitors, and decide for yourself what works best for you.
          We aim to focus on the small businesses that need a helping hand to get online!
        </Text>
        <Table variant="simple">
          <TableCaption>Feature comparison</TableCaption>
          <Thead>
            <Tr>
              <Th>Feature</Th>
              <Th>Halal Munch</Th>
              <Th>Competitors</Th>
            </Tr>
          </Thead>
          <Tbody>
            {featurePricingData.map(fp =>
                <Tr>
                  <Td>{fp.featureName}</Td>
                  <Td>{fp.free ? <Icon as={AiFillCheckCircle} color={"green"}/> : <Icon as={AiFillCloseCircle} color={"red"}/>}</Td>
                  <Td>{fp.competitor? <Icon as={AiFillCheckCircle} color={"green"}/> : <Icon as={AiFillCloseCircle} color={"red"}/>}</Td>
                </Tr>
            )}
          </Tbody>
        </Table>
      </Stack>
    </HomeBlock>
  )
}

function FAQBlock() {

  const faqData = [
    {
      q: "What if I don't have a physical store?",
      a: "There is no requirement to have a physical store to start selling on Halal Munch."
    },
    {
      q: "I am a small business, can I still join?",
      a: "Yes, 100%. We fully support small business, in fact this is why we started our partners program."
    },
    {
      q: "Is there really no monthly fee?",
      a: "Yes we do not charge anything for usage of our service. " +
          "We only charge 5% processing and transaction fee per order to help cover our costs. "
    },
    {
      q: "Why do you charge commission?",
      a: "Without charging a small fee we would not be able to support our customers and improve our service." +
          "We intentionally charge substantially less than "
    },
    {
      q: "How do I get started?",
      a: "Simply sign up and register your business, once verified by our team you can start selling there and then."
    },
    {
      q: "How do you make money?",
      a: "We charge a small commission of 5% to cover transaction costs and the costs to run our service."
    },
    {
      q: "What if my food isn't halal?",
      a: "We cater for both halal and non-halal on our service, so don't worry your business will get recognised."
    },
  ]

  return (
      <HomeBlock>
        <Stack width={"100%"} align={"center"} textAlign={"center"} spazing={6} id={"faq"}>
        <Heading as={"h2"} size={"xl"}>FAQ</Heading>

        <Accordion width={"100%"}>
          {faqData.map(faq =>
            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    {faq.q}
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                {faq.a}
              </AccordionPanel>
            </AccordionItem>
          )}
        </Accordion>
      </Stack>
      </HomeBlock>
  )
}

function ContactBlock() {

  const {apiService} = useContext(ApiContext);

  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [enquiry, setEnquiry] = useState("");
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  function submit() {
    if (validateInputs()) {
      setError(null);
      setLoading(true);
      apiService.enquiryContactPost(email, subject, enquiry)
          .then(data => setMessage(data))
          .catch(e => setError(e.message))
          .finally(() => setLoading(false));
    }
  }

  function validateInputs() {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      setError("Email is not valid")
      return false;
    }

    return true;
  }

  function onEmailChange(event) {
    setEmail(event.target.value)
  }

  function onSubjectChange(event) {
    setSubject(event.target.value)
  }

  function onEnquiryChange(event) {
    setEnquiry(event.target.value);
  }

  function renderForm() {
    if (message) {
      return (
          <Stack width={"100%"}>
            <Alert
                status={'success'}
                variant='subtle'
                flexDirection='column'
                alignItems='center'
                justifyContent='center'
                textAlign='center'
                height='200px'
            >
              <AlertIcon boxSize='40px' mr={0}/>
              <AlertTitle mt={4} mb={1} fontSize='lg'>
                Thanks, your message has been sent.
              </AlertTitle>
              <AlertDescription maxWidth='sm'>
                Your message has been received and a member of our team will get back to you soon.
              </AlertDescription>
            </Alert>
          </Stack>
      )
    } else {
      return (
          <Stack spacing={6} width={"100%"}>
            {error &&
            <Alert
                status={'error'}
                variant='subtle'
                flexDirection='column'
                alignItems='center'
                justifyContent='center'
                textAlign='center'
                height='200px'
            >
              <AlertIcon boxSize='40px' mr={0}/>
              <AlertDescription maxWidth='sm'>
                {error}
              </AlertDescription>
            </Alert>
            }
            <FormControl>
              <FormLabel>Email</FormLabel>
              <Input type={"text"} value={email}
                     onChange={onEmailChange}/>
            </FormControl>

            <FormControl>
              <FormLabel>Subject</FormLabel>
              <Input type={"text"} value={subject}
                     onChange={onSubjectChange}/>
            </FormControl>

            <FormControl>
              <FormLabel>Subject</FormLabel>
              <Textarea rows={10} value={enquiry}
                        onChange={onEnquiryChange}/>
            </FormControl>


            <Button bg={'primary.500'} color={'white'} isFullWidth
                    onClick={submit}>Submit</Button>

          </Stack>

      )
    }
  }

  return (
      <HomeBlock id={"contact"}>
        <Stack width={"100%"} align={"center"} textAlign={"center"}>
          <Heading as={"h2"} size={"xl"}>Get in touch!</Heading>

          {renderForm()}
        </Stack>

      </HomeBlock>
  )
}

function FooterBlock() {
  return (
      <div id={"demo"} style={{
        backgroundColor: "#f1f1f1",
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        margin: "25px 0px 0px 0px",
      }}>
        <div style={{display: 'flex', flexDirection:'column', flexWrap: 'wrap', justifyContent: 'center', paddingTop: '20px', textAlign: 'center'}}>
          <Stack spacing={5} align={'center'}>
            <HStack spacing={6}>
              <Link href={"https://about.halalmunch.com/privacy-policy"}>Privacy Policy</Link>
              <Link href={"https://about.halalmunch.com/terms"}>Terms</Link>
              <Link href={"https://about.halalmunch.com/contact"}>Contact Us</Link>
            </HStack>
            <HStack spacing={6}>
              <Link href={"https://facebook.com/halalmunch"} target={"_"}>Facebook</Link>
              <Link href={"https://twitter.com/halal_munch"} target={"_"}>Instagram</Link>
              <Link href={"https://instagram.com/halalmunch"} target={"_"}>Twitter</Link>
            </HStack>

            <LogoDefault/>

            <Text style={{padding: '30px 0 20px 0', textAlign: 'center'}}>
              {new Date().getFullYear()} Halal Munch Ltd.
            </Text>
          </Stack>
        </div>
      </div>

  )
}

function HomeBlock(props) {
  return <Stack
      padding={["10px 10px", "10px 10px", "10px 20px", "30px 10%"]}
      width={"100%"}
      align={"center"}
      justify={"center"}
      {...props}
  />
}

export default Home;
