import React, {useContext, useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import VerifyEmailForm from "./components/VerifyEmailForm";
import {HeroBackground, Panel} from "../../components/blocks";
import Header from "../../components/Header";
import {Box, Heading, HStack, Stack} from "@chakra-ui/react";
import {ApiContext} from "../../providers/ApiProvider";
import {NotificationContext} from "../../providers/NotificationProvider";
import {AuthContext} from "../../providers/AuthProvider";
import routes from "../../constants/routes";
import Loader from "../../components/loading/Loader";


function VerifyEmail() {

  const history = useHistory();
  const {id} = useParams()
  const {apiService} = useContext(ApiContext)
  const {success, error} = useContext(NotificationContext)
  const {fetchUserFromToken} = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (id) {
      setIsLoading(true)
      apiService.userAccountVerifyEmail(id)
          .then(result => {
            success("Email verified", result.message)
            fetchUserFromToken()
                .then(() => history.push(routes.ACCOUNT_DASHBOARD))
                .catch(e => error("Failed to load your account", e.message))
          })
          .catch(e => error("Email verification failed", e.message))
          .finally(() => setIsLoading(false))
    }
  }, [id])

  return (
      <HeroBackground style={{height: '100%', display: 'flex', flexDirection: 'column'}}>
        <Header/>
        <HStack spacing={10} justify={'space-around'} algin={'center'} flex={1}>
          <Box display={["none", "none", "block"]}  style={{padding: '20px 15px', maxWidth: '500px'}}>
            <Stack spacing={4} style={{height: '80%', display: 'flex', flex: 1, flexDirection: 'column',justifyContent: 'center', padding: '12vh 25px'}}>
              <Heading as={"h1"} size={"2xl"}  color={"white"}>
                Take your business online. <br/>
                <u>No monthly fees.</u>
              </Heading>
              <Heading as={"h4"} size={"md"} color={"white"}>
                Digitalise your business. Order management. Live updates. Advanced AI insights. <br/>
                All the good stuff without the price.
              </Heading>
            </Stack>
          </Box>
          <Panel width={"70%"} height={"max-content"}  style={{backgroundColor: 'white', margin: '10px 20px 20px 0px', padding: '40px 60px'}}>
            <Loader isLoading={isLoading}>
              <VerifyEmailForm/>
            </Loader>
          </Panel>
        </HStack>
      </HeroBackground>
  )

}

export default VerifyEmail;
