/*--------------------------------------------------------
----------------------------------------------------------
//  ACTION TYPES
----------------------------------------------------------
---------------------------------------------------------*/

import {
  AiFillClockCircle,
  AiFillDashboard,
  AiFillFileText, AiFillSetting, AiFillShop,
  AiFillShopping,
  AiFillTags,
} from 'react-icons/ai'

export const ACTION_FETCH_RESTAURANTS = "dashboard:ACTION_FETCH_RESTAURANTS";
export const ACTION_SET_RESTAURANT = "dashboard:ACTION_FETCH_RESTAURANT";
export const ACTION_SET_DASHBOARD_PAGE = "dashboard:ACTION_SET_DASHBOARD_PAGE";










/*--------------------------------------------------------
----------------------------------------------------------
//  ACTIONS
----------------------------------------------------------
---------------------------------------------------------*/

export const doFetchRestaurants = () => ({
  type: ACTION_FETCH_RESTAURANTS
})

export const doSelectRestaurant = (r) => ({
  type: ACTION_SET_RESTAURANT,
  payload: {
    restaurant: r
  }
})

export const doSelectDashboardPage = (page) => ({
  type: ACTION_SET_DASHBOARD_PAGE,
  payload: {
    page: page
  }
})








/*--------------------------------------------------------
----------------------------------------------------------
//  REDUCERS
----------------------------------------------------------
---------------------------------------------------------*/

export const DASHBOARD_PAGES = {
  DASH: {
    eventKey: "dash",
    icon: AiFillDashboard,
    label: "Overview"
  },
  ORDERS: {
    eventKey: "orders",
    icon: AiFillFileText,
    label: "Orders"
  },
  DISCOUNTS: {
    eventKey: "discounts",
    icon: AiFillTags,
    label: "Discounts"
  },
  MENU: {
    eventKey: "menu",
    icon: AiFillShopping,
    label: "Menu"
  },
  OPEN_HOURS: {
    eventKey: "open-hours",
    icon: AiFillClockCircle,
    label: "Open Hours"
  },
  STORE: {
    eventKey: "store",
    icon: AiFillShop,
    label: "Store Details"
  },
  ACCOUNT_SETTINGS: {
    eventKey: "settings",
    icon: AiFillSetting,
    label: "Account"
  },
};

const INITIAL_STATE = {
  restaurant: null,
  currentDashboardPage: null,

}


export default function dashboardReducer(state = INITIAL_STATE, action) {
  const actionType = action?.type
  const payload = action?.payload
  switch (actionType) {
    case ACTION_SET_RESTAURANT:
      return {
        ...state,
        restaurant: payload?.restaurant
      };
    case ACTION_FETCH_RESTAURANTS:
      return {
        ...state
      }
    case ACTION_SET_DASHBOARD_PAGE:
      return {
        ...state,
        currentDashboardPage: DASHBOARD_PAGES[payload?.page],
      }
    default:
      return state;
  }
}

export const getRestaurant = (state) => state?.dashboard?.restaurant
export const getCurrentDashboard = (state) => state?.dashboard?.currentDashboardPage

