import React, {useContext, useState} from 'react'
import {Box, Button, Flex, FormControl, FormLabel, Heading, Input, Stack, Text, Link} from '@chakra-ui/react'
import {HeroBackground} from "../../components/blocks";
import {colors} from "../../constants/styles";
import styled from "styled-components";
import {AuthContext} from "../../providers/AuthProvider";
import {NotificationContext} from "../../providers/NotificationProvider";
import {useHistory, Link as RouterLink} from "react-router-dom";
import routes from "../../constants/routes";
import {LogoDefault} from "../../components/logos";
import {ResponsiveContext} from "../../providers/ResponsiveProvider";

function SignInPage(props) {


  const {signIn} = useContext(AuthContext);
  const {error} = useContext(NotificationContext);
  const {isMobile} = useContext(ResponsiveContext);
  const history = useHistory()

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  function onSignIn() {
    setLoading(true);
    signIn(username, password)
        .then((token: string) => history.push(routes.ACCOUNT_DASHBOARD))
        .catch((e: any) => {
          error("Sign in failed", e.message)
        })
        .finally(() => setLoading(false));
  }

  return (
      <Flex flex={1} height={"100%"} width={"100%"}>
        {/*<HeroBackground style={{height: '100vh', width: '100%', backgroundColor: colors.WHITE}}>*/}
          <LinerGradientBox
              height={"100%"}
              width={"100%"}
              display={"flex"}
              alignItems={"center"}
              justifyContent={isMobile ? 'center' : "flex-end"}
              style={isMobile ? {background: 'white'} : {}}
          >

            <Stack spacing={4} width={["100%", "100%", "60%"]} maxWidth={"550px"} padding={["20px", "30px", "80px", "100px"]}>
              <LogoDefault></LogoDefault>
              <Heading as={"h1"} size={"lg"} >Welcome to Halal Munch Partners Hub</Heading>
              <Text>Enter into our Halal Munch Partners Hub. Edit your menu, view orders, track sales and much much more!</Text>
              <Stack spacing={4} justify={'stretch'} >
                <FormControl>
                  <FormLabel>Email</FormLabel>
                  <Input defaultValue={username} placeholder={"e.g. John.Smith@halalmunch.com"} onChange={(event) => setUsername(event.target.value)}/>
                </FormControl>

                <FormControl>
                  <FormLabel>Password</FormLabel>
                  <Input type={"password"} defaultValue={password} placeholder={"e.g. ******"} onChange={(event) => setPassword(event.target.value)}/>
                </FormControl>

                <Button isFullWidth isLoading={loading} colorScheme={"primary"} type={"submit"}  onClick={onSignIn}>Sign In</Button>

                <Link as={RouterLink} color={"primary.500"} to={routes.AUTH_FORGOTTEN_PASSWORD}><u>Forgotten password?</u></Link>
              </Stack>
            </Stack>

          </LinerGradientBox>
        {/*</HeroBackground>*/}
      </Flex>
  )
}

export const LinerGradientBox = styled(Box)`
background: rgb(231,77,60);
background: -moz-linear-gradient(90deg, rgba(231,77,60,1) 30%, rgba(255,255,255,0) 30%);
background: -webkit-linear-gradient(90deg, rgba(231,77,60,1) 30%, rgba(255,255,255,0) 30%);
background: linear-gradient(90deg, rgba(231,77,60,1) 30%, rgba(255,255,255,0) 30%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#e74d3c",endColorstr="#ffffff",GradientType=1);
`


export default SignInPage;
