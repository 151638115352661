import React, {useContext, useEffect} from "react";
import {Route, Switch, useHistory, useLocation} from "react-router-dom";
import routes from "../../constants/routes";
import {AuthContext} from "../../providers/AuthProvider";
import VerifyEmail from "./VerifyEmail";
import {Flex, Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay, Stack} from '@chakra-ui/react'
import MainDashboardLoader from "../dashboard/MainDashboardLoader";
import {ResponsiveContext} from "../../providers/ResponsiveProvider";
import SignUpForm from "./components/SignUpForm";
import {LogoDefault} from "../../components/logos";
import SignInPage from "./SignInPage";
import PasswordResetPage from "./PasswordResetPage";


function Auth() {

  const {isAuthenticated, isVerified} = useContext(AuthContext);
  const {isMobile} = useContext(ResponsiveContext);
  const history = useHistory();

  useEffect(() => {
    if (isAuthenticated) {
      history.push(routes.DASHBOARD)
    }
  }, [isAuthenticated])


  function AuthModal({children}) {
    return (
        <Flex flex={1}>
          {isMobile && <MainDashboardLoader/>}
          <Modal onClose={() => null} size={isMobile ? 'full' : "xl"} isOpen={!isVerified} initialFocusRef={null}>
          <ModalOverlay />
          <ModalContent padding={"10px 10px"}>
            {children}
          </ModalContent>
        </Modal>
        </Flex>
    )
  }


  return (
      <Switch>
        <Route path={`${routes.AUTH}/verify/:id`}>
          <AuthModal>
            <ModalHeader>Please verify your email</ModalHeader>
            <ModalBody>
              <VerifyEmail/>
            </ModalBody>
          </AuthModal>
        </Route>
        <Route path={`${routes.AUTH_SIGN_UP}`}>
          <AuthModal>
            <ModalHeader justify={"center"}>
              <Stack width={"100%"} spacing={6} align={"center"}>
                <LogoDefault/>
              </Stack>
            </ModalHeader>
            <ModalBody>
              <SignUpForm onComplete={() => history.push(routes.ACCOUNT_DASHBOARD)}/>
            </ModalBody>
          </AuthModal>
        </Route>
        <Route path={`${routes.AUTH_SIGN_IN}`}>
          <SignInPage/>
        </Route>
        <Route path={`${routes.AUTH_FORGOTTEN_PASSWORD}`}>
          <PasswordResetPage/>
        </Route>
      </Switch>
  )
}

export default Auth;
