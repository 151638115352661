import React from 'react';
import ErrorMessage from './ErrorMessage';
import PropType from 'prop-types';
import { Flex } from '@chakra-ui/react';


export default class ErrorMessageBoundary extends React.Component {
  static propTypes = {
    message: PropType.string,
  };

  static defaultProps = {
    message: null
  };

  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      errorSent: null,
    };
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({hasError: true});
    // You can also log the error to an error reporting service
    console.log('error', error);
    console.log('info', info);
  }

  render() {
    return (
        <React.Fragment>
          {this.state.hasError ?
              <Flex margin={"20px"} align={"center"} justify={"center"}>

                <ErrorMessage message={this.props.message}/>

              </Flex>
              :
              this.props.children
          }
        </React.Fragment>
    );
  }
}
