import axios, {AxiosError, AxiosResponse} from 'axios';
import Logger from '../utils/Logger';
import {isEmpty} from 'lodash-es';

const DEFAULT_4XX_ERROR = "Oh no, a bad request was received, please try again.";
const DEFAULT_404_ERROR = "Uh-oh, there's nothing here... Please go back.";
const DEFAULT_5XX_ERROR = "Oops something went wrong on our side, please try again.";
const DEFAULT_NO_RESPONSE_ERROR = "Oops, looks like our service is down at the moment. Please try again later.";
const DEFAULT_PERMISSION_ERROR = "You do not have permission to access this.";

interface IResponse {
  result: Array<any>,
  message: string,
  error: string
}

class ServiceHandler {
  static logger = new Logger(ServiceHandler.name);

  static setupAxiosDefaults() {
    axios.defaults.timeout = 10000
    ServiceHandler.setupAxiosAuthHeader("default")
  }

  static setupAxiosAuthHeader(token: string) {
    console.log("Setting header", token)
    if (token == null) {
      token = "default"
    }
    axios.defaults.headers["Authorization"] = `Bearer ${token}`
  }


  static handleAxiosResponse(response : AxiosResponse, resultOnly=true, default4XXError: string, default5XXError: string) {
    const status = response.status;
    const data = response.data;
    if (ServiceHandler._is2xxError(status)) {
      if (data.error) {
        throw Error(data.error)
      } else {
        if (resultOnly) {
          return data.result
        } else {
          return data
        }
      }
    } else {
      if (data.error) {
        throw Error(data.error)
      } else if(ServiceHandler._isPermissionError(status)) {
        throw new Error(DEFAULT_PERMISSION_ERROR);
      } else if(ServiceHandler._is4xxError(status)) {
        throw new Error(default4XXError || DEFAULT_4XX_ERROR);
      } else if (ServiceHandler._is5xxError(status)) {
        throw new Error(default5XXError || DEFAULT_5XX_ERROR);
      } else {
        throw new Error(default5XXError || DEFAULT_5XX_ERROR);
      }
    }
  }

  static _is2xxError(status: any) {
    return status.toString().startsWith(2);
  }

  static _is404Error(status: any) {
    return status.toString().startsWith(404);
  }

  static _is4xxError(status: any) {
    return status.toString().startsWith(4);
  }

  static _isPermissionError(status: any) {
    return status === 401 || status === 402 || status === 403;
  }

  static _is5xxError(status: any) {
    return status.toString().startsWith(5);
  }

  static handleServiceError(err: AxiosError, default4XXError: string, default5XXError: string) {
    ServiceHandler.logger.error("Response error:", err)
    const response = err.response;

    if (response == null) {
      throw new Error(DEFAULT_NO_RESPONSE_ERROR)
    }
    const status = response.status;

    const data = response.data;

    ServiceHandler.logger.error("Error response", response);

    if(ServiceHandler._is404Error(status)) {
      ServiceHandler.logger.error("404 error", status);
      throw new Error(default4XXError || DEFAULT_404_ERROR)
    } else if (!isEmpty(data) && !isEmpty(data.error)) {
      ServiceHandler.logger.error("Error from backend with message", data.error);
      throw new Error(data.error);
    } else if(ServiceHandler._isPermissionError(status)) {
      ServiceHandler.logger.error("Permissions error", status);
      throw new Error(DEFAULT_PERMISSION_ERROR)
    } else if(ServiceHandler._is4xxError(status)) {
      ServiceHandler.logger.error("4XX error", status);
      throw new Error(default4XXError || DEFAULT_4XX_ERROR)
    } else if(ServiceHandler._is5xxError(status)) {
      ServiceHandler.logger.error("5XX error", status);
      throw new Error(default5XXError || DEFAULT_5XX_ERROR)
    } else {
      ServiceHandler.logger.error("Unknown error", status);
      throw new Error(default5XXError || DEFAULT_5XX_ERROR)
    }
  }

}

export default ServiceHandler;
