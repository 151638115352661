import React, {useContext, useState} from 'react';
import styled from 'styled-components';
import {colors} from '../constants/styles';
import {useHistory} from 'react-router-dom';
import {AuthContext} from "../providers/AuthProvider";
import {Button, Link, Menu, MenuItem, Box, Flex, MenuButton, Icon, MenuList, Stack, HStack, IconButton} from "@chakra-ui/react";
import {AiOutlineMenu} from "react-icons/ai";
import {LogoDefault} from "./logos";
import routes from "../constants/routes";

function Header(props) {

  const menuItems = [
    {
      label: "Features",
      url: "#features",
    },
    // {
    //   label: "Demo",
    //   url: "#demo",
    // },
    {
      label: "Pricing",
      url: "#pricing",
    },
    {
      label: "FAQ",
      url: "#faq",
    },
    {
      label: "Contact",
      url: "#contact",
    },
  ];

  const {isAuthenticated, signOut} = useContext(AuthContext);
  const history = useHistory();

  return (
      <Flex height={"60px"} padding={"10px 20px"}>
        <Flex flex={1} justify={'space-between'} align={'center'}>
          <div style={{display: 'flex', flex: 1, alignItems: 'center',}} onClick={() => history.push("/")}>
            <LogoDefault/>
            {/*<Image loading="lazy"src={logo} width={'auto'} height={'25px'} alt={"Halal Munch Logo"} style={{height: '25px !important', width: 'auto !important'}}/>*/}
            {/*<Box display={["none", "none", "block"]}>*/}
            {/*  <HeaderTitleComponent>Partners</HeaderTitleComponent>*/}
            {/*</Box>*/}
          </div>

          {/* Mobile */}
          <Flex justify={"flex-end"} display={[null, null, "none", "none"]}>
            <Menu>
              <MenuButton
                  as={IconButton}
                  aria-label={"Menu button"}
                  size={"md"}
                  icon={<Icon as={AiOutlineMenu}/>}
                  bg={"white"}
                  // color={"white"}
                  variant="solid"
              />
              <MenuList>
                {menuItems.map(item => <MenuItem key={item.url}><Link color={colors.SECONDARY} href={`/${item.url}`}>{item.label}</Link></MenuItem>)}
                <Button colorScheme={"primary"} onClick={() => history.push(routes.AUTH_SIGN_UP)}>Become a Partner</Button>
              </MenuList>
            </Menu>
          </Flex>

          {/* Desktop */}
          <HStack align={'center'} display={["none", "none", "flex"]} spacing={[5, 5, 5, 10]}>
            {menuItems.map(item =>
                <Link
                    key={item.url} color={"white"} href={`/${item.url}`}
                    fontWeight={"bold"}
                >
                  {item.label}
                </Link>
            )}
            <Button colorScheme={"primary"} onClick={() => history.push(routes.AUTH_SIGN_UP)}>Become a Partner</Button>
          </HStack>
        </Flex>
      </Flex>
  )
}

export default Header;
