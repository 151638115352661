
class PostRestaurantOnBoardRequest {
  name: string;
  description: string;
  restaurantType: string;
  halalCategory: string;
  amenities: Array<string>;
  cuisines: Array<string>;
  placeId: any;
  showFullAddress: string;
  showOnMap: string;
  email: string;
  phone: string;
  website: string;
  instagram: string;
  facebook: string;


  constructor(name: string, description: string, restaurantType: string, halalCategory: string, amenities: Array<string>, cuisines: Array<string>, placeId: any, showFullAddress: string, showOnMap: string, email: string, phone: string, website: string, instagram: string, facebook: string) {
    this.name = name;
    this.description = description;
    this.restaurantType = restaurantType;
    this.halalCategory = halalCategory;
    this.amenities = amenities;
    this.cuisines = cuisines;
    this.placeId = placeId;
    this.showFullAddress = showFullAddress;
    this.showOnMap = showOnMap;
    this.email = email;
    this.phone = phone;
    this.website = website;
    this.instagram = instagram;
    this.facebook = facebook;
  }
}

export default PostRestaurantOnBoardRequest;
