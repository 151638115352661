import React, {createContext, useContext, useEffect, useState} from 'react';
import Logger from "../utils/Logger";
import {isEmpty} from "lodash-es";
import {ApiContext} from "./ApiProvider";
import routes from "../constants/routes";
import {useHistory} from "react-router-dom";
import {RestaurantAccount} from "../models/models";

interface AuthContextProps {
  user?: RestaurantAccount | null,
  firebaseCustomToken: string | null,
  isAuthenticated: boolean,
  isVerified: boolean,
  signIn: (email: string, password: string) => Promise<RestaurantAccount>,
  signUp: (email: string, password: string, confirm: string) => Promise<RestaurantAccount>,
  signOut: () => void,
  passwordReset: (email: string) => void,
  fetchUserFromToken: Promise<void>,
}
export const AuthContext = createContext<AuthContextProps>({
  user: null,
  firebaseCustomToken: null,
  isAuthenticated: false,
  isVerified: false,
  signIn: (email: string, password: string) => Promise.reject(),
  signUp: (email: string, password: string, confirm: string) => Promise.reject(),
  signOut: () => null,
  passwordReset: (email: string) => null,
  fetchUserFromToken: async () => null,
});

interface AuthProviderProps {
  children: any
}

export function AuthProvider ({ children } : AuthProviderProps) {
  const logger = new Logger(AuthProvider.name)

  const history = useHistory();

  const {setupAxiosAuthHeader, apiService} = useContext(ApiContext)
  const [user, setUser] = useState<RestaurantAccount | null>(null);
  const [firebaseCustomToken, setFirebaseCustomToken] = useState<string | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [token, setToken] = useState("");

  useEffect(() => {
    // console.log("Initialising firebase", FIREBASE_CONFIG);
    const currentToken = window.localStorage.getItem("token")
    if (currentToken) {
      setToken(currentToken)
    } else {
      setToken("ERROR")
    }
    // firebase.initializeApp(FIREBASE_CONFIG);
    // firebase.auth().onAuthStateChanged(onAuthStateChanged)
  }, []);

  useEffect(() => {
    logger.info("User changed:", user)
    setIsAuthenticated(user != null && !isEmpty(user))
    setIsVerified(user?.email_verified || false);
  }, [user])

  useEffect(() => {
    logger.info("Token changed:", token)
    setupAxiosAuthHeader(token)
    if (isEmpty(token) || token === "ERROR") {
      window.localStorage.removeItem("token")
      setUser(null)
      if (token === "ERROR") {
        history.push(routes.HOME)
      }
    } else {
      window.localStorage.setItem("token", token)
      fetchUserFromToken(token)
    }
  }, [token])

  async function fetchUserFromToken(token) {
    await apiService.userAccountGetCurrent(token)
        .then(data => {
          setUser(data?.user)
          setFirebaseCustomToken(data?.firebase_token)
        })
        .catch(() => setUser(null))
  }

  async function doSignInWithEmailAndPassword(email: string, password: string) : Promise<RestaurantAccount> {
    const response = await apiService.userAccountSignIn(email, password)
    setToken(response.token)
    setUser(response.user)
    return response.user;
  }

  async function doSignUpWithEmailAndPassword(email: string, password: string, confirm: string) : Promise<RestaurantAccount> {
    const response = await apiService.userAccountSignUp(email, password, confirm);
    setToken(response.token)
    setUser(response.user)
    return response.user;
  }


  async function doPasswordReset(email: string) : Promise<RestaurantAccount> {
    const response = await apiService.userAccountResetPassword(email);
    return response;
  }

  async function doSignOut() {
    console.log("Signing Out")
    setToken(null)
  }

  return (
      <AuthContext.Provider
          value={{
            user: user,
            firebaseCustomToken: firebaseCustomToken,
            isAuthenticated: isAuthenticated,
            isVerified: isVerified,
            signIn: doSignInWithEmailAndPassword,
            signUp: doSignUpWithEmailAndPassword,
            signOut: doSignOut,
            passwordReset: doPasswordReset,
            fetchUserFromToken: fetchUserFromToken,
          }}
      >
        {children}
      </AuthContext.Provider>
  )
}
