import React, {useContext, useEffect, useState} from "react";
import {AuthContext} from "../../providers/AuthProvider";
import {ApiContext} from "../../providers/ApiProvider";
import {useHistory, useParams} from "react-router-dom";
import {
  Box, Flex, Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay,
} from "@chakra-ui/react";
import {
  AiFillClockCircle,AiFillHome,
  AiFillSetting,AiFillSignal, AiFillStar,
  AiFillTags, AiOutlineDashboard, AiOutlineFileSearch,
} from 'react-icons/ai'
import {NotificationContext} from "../../providers/NotificationProvider";
import ErrorMessageBoundary from "../../components/error/ErrorMessageBoundary";
import {ResponsiveContext} from "../../providers/ResponsiveProvider";
import DashboardMenu from "./components/DashboardMenu";
import Dashboards from "./Dashboards";
import VerifyEmailForm from "../auth/components/VerifyEmailForm";

export const DASHBOARD_PAGES = {
  DASH: {
    eventKey: "",
    icon: AiFillHome,
    label: "Home"
  },
  SALES: {
    eventKey: "sales",
    icon: AiFillSignal,
    label: "Sales"
  },
  LIVE_ORDERS: {
    eventKey: "orders",
    icon: AiOutlineDashboard,
    label: "Live Orders"
  },
  MENU: {
    eventKey: "menu",
    icon: AiOutlineFileSearch,
    label: "Menu"
  },
  OPEN_HOURS: {
    eventKey: "open-hours",
    icon: AiFillClockCircle,
    label: "Open Hours"
  },
  REVIEWS: {
    eventKey: "reviews",
    icon: AiFillStar,
    label: "Ratings & Reviews"
  },
  DISCOUNTS: {
    eventKey: "discounts",
    icon: AiFillTags,
    label: "Discounts"
  },
  // STORE: {
  //   eventKey: "store",
  //   icon: AiFillShop,
  //   label: "Store Details"
  // },
  ACCOUNT_SETTINGS: {
    eventKey: "settings",
    icon: AiFillSetting,
    label: "Settings"
  },
}

function DashboardPage() {
  const {isAuthenticated, isVerified} = useContext(AuthContext);
  const {success, error} = useContext(NotificationContext);
  const {apiService} = useContext(ApiContext);
  const {isMobile} = useContext(ResponsiveContext);
  const {page} = useParams();
  const history = useHistory();


  return (
      <Flex flex={1} direction={'column'}>

        <Box display={["block"]} height={'100%'}>
          <Flex flex={1} direction={isMobile ? 'column' : 'row'} height={'100%'}>
            <DashboardMenu selectedPage={page}/>
            <Flex id={"dashboard_page__body"} flex={1} marginLeft={isMobile ? '0' : '240px'} paddingTop={isMobile ? "60px" : "30px"} bg={"#f9fafa"}  overflowX={"hidden"} width={isMobile ? '100%' : 'auto'} style={{minHeight: '100%'}}>
              <ErrorMessageBoundary>
                <Dashboards/>
              </ErrorMessageBoundary>
            </Flex>
            {/* Verify Email Modal */}
            <Modal onClose={() => null} size={"full"} isOpen={!isVerified && isAuthenticated}>
              <ModalOverlay/>
              <ModalContent>
                <ModalHeader>Please verify your email</ModalHeader>
                <ModalBody>
                  <VerifyEmailForm/>
                </ModalBody>
              </ModalContent>
            </Modal>
          </Flex>
        </Box>
      </Flex>
  );


}

export default DashboardPage;
