import {Restaurant, RestaurantAccount} from "../../models/models";
import React, {useContext, useEffect, useState} from "react";
import {
  Button,
  FormLabel,
  Flex,
  Input,
  FormControl,
  VStack, Text, Heading,
  Textarea, Tag, Modal,
  FormHelperText,
  Select, TagLabel, TagCloseButton, Stack, HStack,
  Alert, AlertIcon, AlertTitle, AlertDescription, Link, CloseButton,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  PinInput, PinInputField,
  TabList, Tabs, TabPanels, TabPanel, Tab,
} from "@chakra-ui/react";
import Logger from "../../utils/Logger";
import {ApiContext} from "../../providers/ApiProvider";
import {DashboardBase, DashboardBasePanel} from "./components/DashboardBase";
import ErrorMessageBoundary from "../../components/error/ErrorMessageBoundary";
import {NotificationContext} from "../../providers/NotificationProvider";
import Loader from "../../components/loading/Loader";
import StoreDetailsUpdatePanel from "./components/StoreDetailsUpdatePanel";
import StoreLocationUpdatePanel from "./components/StoreLocationUpdatePanel";
import StoreContactUpdatePanel from "./components/StoreContactUpdatePanel";
import StoreCategoriesUpdatePanel from "./components/StoreCategoriesUpdatePanel";
import {AuthContext} from "../../providers/AuthProvider";

interface Props {
  restaurantId: string,
}

function AccountSettingsDashboard(props: Props) {
  const logger = new Logger(AccountSettingsDashboard.name)
  const {apiService} = useContext(ApiContext);
  const {success, error} = useContext(NotificationContext);
  const {isAuthenticated} = useContext(AuthContext);

  const {restaurantId} = {...props};
  const [restaurant, setRestaurant] = useState<Restaurant | null>();
  const [restaurantAccount, setRestaurantAccount] = useState<RestaurantAccount>(null);
  const [isRestaurantLoading, setIsRestaurantLoading] = useState(false);
  const [isAccountLoading, setIsAccountLoading] = useState(true);
  const [isLoadingContact, setIsLoadingContact] = useState(false);
  const [isLoadingStripe, setIsLoadingStripe] = useState(false);
  const [showPhoneVerifyModal, setShowPhoneVerifyModal] = useState(false);
  const [phoneVerificationCode, setPhoneVerificationCode] = useState("");

  // Image Upload
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  useEffect(() => {
    if (restaurantId && isAuthenticated) {
      fetchAccountDetails()
    }
  }, [restaurantId, isAuthenticated])

  useEffect(() => {
    setRestaurant(null)
    if (restaurantId && isAuthenticated) {
      fetchRestaurant()
    }
  }, [restaurantId, isAuthenticated])

  useEffect(() => {
    if (restaurantAccount) {
      setEmail(restaurantAccount.email)
      setPhone(restaurantAccount.phone)
    }
  }, [restaurantAccount])

  function fetchRestaurant() {
    setIsRestaurantLoading(true)
    apiService.restaurantGetByIdForCurrentUser(restaurantId)
        .then(data => setRestaurant(data))
        .catch(e => error("Failed to load store", e.message))
        .finally(() => setIsRestaurantLoading(false))
  }

  function fetchAccountDetails() {
    setIsAccountLoading(true)
    apiService.accountGetByRestaurantId(restaurantId)
        .then(data => setRestaurantAccount(data))
        .catch(e => error("Failed to load store", e.message))
        .finally(() => setIsAccountLoading(false))
  }

  function saveContactDetails() {
    apiService.accountUpdate(restaurant.id, email, phone)
        .then(res => {
          success("Success", res.message)
          setRestaurantAccount(res.result)
        })
        .catch(e => error("Error", e.message))
        .finally(() => setIsLoadingContact(false))
  }

  function deleteAccount() {
    setIsLoadingContact(true)
    apiService.accountDelete(restaurant.id)
        .then(res => {
          success("Success", res.message)
          setRestaurantAccount(res.result)
        })
        .catch(e => error("Error", e.message))
        .finally(() => setIsLoadingContact(false))
  }

  function connectWithStripe() {
    setIsLoadingStripe(true)
    apiService.accountSetup(restaurant.id)
        .then(resp => {
          const data = resp.result;
          window.open(data.url);
        })
        .catch(e => error("Error", e.message))
        .finally(_ => setIsLoadingStripe(false))
  }

  function sendPhoneVerificationCode() {
    setIsLoadingContact(true)
    apiService.accountRequestPhoneVerificationCode(restaurant.id)
        .then(resp => {
          success("Success", resp.message)
        })
        .catch(e => error("Error", e.message))
        .finally(_ => setIsLoadingContact(false))
  }

  function submitPhoneVerificationCode(phoneCode) {
    setIsLoadingContact(true)
    apiService.accountSubmitPhoneVerificationCode(restaurant.id, phoneCode)
        .then(resp => {
          success("Success", resp.message)
          setRestaurantAccount(resp.result)
          setShowPhoneVerifyModal(false)
        })
        .catch(e => error("Error", e.message))
        .finally(() => {
          setIsLoadingContact(false)
          setPhoneVerificationCode(null)
        })
  }


  return (
      <DashboardBase title={"Account Settings"} subtitle={"The information is not shared to any of your customers."}>
        <ErrorMessageBoundary>
          <Flex flex={1} direction={"column"}>
            <Tabs variant="soft-rounded" isLazy colorScheme="primary">
                <TabList>
                  {/*<Tab>Store</Tab>*/}
                  <Tab>Account Settings</Tab>
                  <Tab>Store Settings</Tab>
                </TabList>
                <TabPanels>
                  {/*<TabPanel>*/}
                  {/*  <OpenHoursDeliveryForm restaurantId={restaurant.id}/>*/}
                  {/*</TabPanel>*/}
                  <TabPanel>
                    <VStack spacing={6} flex={1} align={"stretch"}>
                      {!restaurantAccount?.can_accept_payments && !isAccountLoading &&
                      <Alert status="warning">
                        <AlertIcon />
                        <Stack>
                          <AlertTitle mr={2}>Your account is not complete.</AlertTitle>
                          <AlertDescription>
                            You will need to provide your contact details below, and complete you stripe account setup.
                            We are unable to accept orders and payments until this is complete.
                          </AlertDescription>
                        </Stack>
                        <CloseButton position="absolute" right="8px" top="8px" />
                      </Alert>
                      }
                      <DashboardBasePanel
                          title={"Contact Details"}
                          isLoading={isAccountLoading}
                          onAction={fetchAccountDetails}
                      >
                        <Stack spacing={4}>

                          <FormControl>
                            <FormLabel>
                              Email &nbsp;
                              {restaurantAccount?.email_verified ? <Tag colorScheme={"green"}>verified</Tag> : <Tag colorScheme={"red"}>not verified</Tag>}
                            </FormLabel>
                            <HStack>
                              <Input name="email" defaultValue={restaurantAccount?.email} value={email} onChange={event => setEmail(event.target.value)}/>
                              {restaurantAccount?.email && !restaurantAccount?.email_verified && <Button size={"sm"}>Verify</Button>}
                            </HStack>
                            <FormHelperText>This email will only be used by us to contact you.</FormHelperText>
                          </FormControl>

                          <FormControl>
                            <FormLabel>
                              Phone &nbsp;
                              {restaurantAccount?.phone_verified ? <Tag colorScheme={"green"}>verified</Tag> : <Tag colorScheme={"red"}>not verified</Tag>}
                            </FormLabel>
                            <HStack>
                              <Input name="phone" defaultValue={restaurantAccount?.phone} value={phone} onChange={event => setPhone(event.target.value)}/>
                              {restaurantAccount?.phone && !restaurantAccount?.phone_verified && <Button size={"sm"} onClick={() => setShowPhoneVerifyModal(true)}>Verify</Button>}
                            </HStack>
                            <FormHelperText>This phone number will only be used by us to contact you.</FormHelperText>
                          </FormControl>
                          <Drawer placement={"bottom"} isOpen={showPhoneVerifyModal} onClose={() => setShowPhoneVerifyModal(false)}>
                            <DrawerOverlay />
                            <DrawerContent>
                              <DrawerCloseButton />
                              <DrawerHeader>Verify Phone Number</DrawerHeader>

                              <DrawerBody align={"center"}>
                                <Stack>
                                  <Text>Please enter the 6 digit code that was sent to {phone}</Text>
                                  <HStack justify={"center"}>
                                    <PinInput
                                        onComplete={submitPhoneVerificationCode}
                                        value={phoneVerificationCode}
                                        onChange={setPhoneVerificationCode} size={"lg"}
                                        isInvalid={phoneVerificationCode == null}
                                    >
                                      <PinInputField />
                                      <PinInputField />
                                      <PinInputField />
                                      <PinInputField />
                                      <PinInputField />
                                      <PinInputField />
                                    </PinInput>
                                  </HStack>
                                </Stack>
                              </DrawerBody>

                              <DrawerFooter>
                                <Button
                                    colorScheme="secondary"
                                    onClick={() => sendPhoneVerificationCode()}
                                    loading={isLoadingContact}
                                >
                                  Send Verification Code
                                </Button>
                              </DrawerFooter>
                            </DrawerContent>
                          </Drawer>

                          <Button isLoading={isLoadingContact} onClick={() => saveContactDetails()}>Save Changes</Button>
                        </Stack>
                      </DashboardBasePanel>

                      <DashboardBasePanel title={"Payment Details"} isLoading={isAccountLoading}>
                        <Stack spacing={4}>

                          <FormControl>
                            <FormLabel>Stripe Account Id</FormLabel>
                            <Input name="stripe_account_id" value={restaurantAccount?.stripe_account_id} readOnly/>
                            <FormHelperText>This is your stripe account that is connected with us. We use stripe as our payments provider.
                              To make changes to your stripe account please get in touch with us,
                            </FormHelperText>
                          </FormControl>
                          {restaurantAccount?.stripe_account_id ?
                              <HStack>
                                {!restaurantAccount?.details_submitted &&
                                <Button colorScheme={"secondary"} isLoading={isLoadingStripe}
                                        onClick={() => connectWithStripe()}>
                                  Complete Stripe Account Setup
                                </Button>
                                }
                              </HStack>
                              :
                              <Button colorScheme={"secondary"} isLoading={isLoadingStripe} onClick={() => connectWithStripe()}>
                                Connect with Stripe
                              </Button>
                          }
                        </Stack>
                      </DashboardBasePanel>
                      <hr/>
                    </VStack>
                  </TabPanel>
                  <TabPanel>
                    <VStack spacing={6} flex={1} align={"stretch"}>
                      <hr/>
                      <StoreDetailsUpdatePanel
                          restaurant={restaurant}
                      />

                      {/*  Address */}
                      <StoreLocationUpdatePanel
                          restaurant={restaurant}
                      />
                      <hr/>

                      <StoreContactUpdatePanel
                          restaurant={restaurant}
                      />
                      <hr/>

                      <StoreCategoriesUpdatePanel
                          restaurant={restaurant}
                      />
                      <br/><br/>
                      {/*<Button loading={isLoading} onClick={onSubmit}>Submit Changes</Button>*/}
                    </VStack>
                  </TabPanel>
                </TabPanels>
              </Tabs>
          </Flex>
        </ErrorMessageBoundary>
      </DashboardBase>
  )
}


export default AccountSettingsDashboard;
