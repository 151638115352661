import React, {useContext, useEffect, useState} from "react";
import {ApiContext} from "../../../providers/ApiProvider";
import {
  Button,
  Flex,
  FormControl,
  FormHelperText,
  Input,
  FormLabel,
  Select,
  Switch,
  HStack,
  Stack,
} from "@chakra-ui/react";
import {NotificationContext} from "../../../providers/NotificationProvider";
import {useFormik} from "formik";
import Logger from "../../../utils/Logger";
import {isEmpty} from "lodash-es";
import {NumberInputWithIncrements} from "../../../components/inputs";
import {DashboardBasePanel} from "./DashboardBase";
import {OrderSettings, Restaurant} from "../../../models/models";


interface OpenHoursOrderSettingsFormPanelProps {
  restaurantId: string
}

function OpenHoursOrderSettingsFormPanel(props: OpenHoursOrderSettingsFormPanelProps) {
  const logger = new Logger(OpenHoursOrderSettingsFormPanel.name)

  const {apiService} = useContext(ApiContext);
  const {success, error} = useContext(NotificationContext);
  const {restaurantId} = {...props};

  const [orderOptions, setOrderOptions] = useState<OrderSettings>(null);
  const [isLoading, setIsLoading] = useState(true);


  useEffect(() => {
    if (restaurantId) {
      fetchOrderSettings()
    }
  }, [restaurantId])


  function fetchOrderSettings() {
    setIsLoading(true)
    apiService.orderSettingsGet(restaurantId)
        .then((data) => {
          setOrderOptions(data)
        })
        .catch(e => error("Failed to get your details", e.message))
        .finally(() => setIsLoading(false))
  }

  return (
      <DashboardBasePanel
          title={"Pre-Order settings"}
          description={"Change you're order settings and preferences"}
          isLoading={isLoading}
          onAction={fetchOrderSettings}
      >
        {orderOptions && <OpenHoursOrderSettingsForm
            restaurantId={restaurantId}
            orderOptions={orderOptions}
        />}
      </DashboardBasePanel>
  )

}

function OpenHoursOrderSettingsForm(props: { restaurantId: string, orderOptions: OrderSettings }) {
  const logger = new Logger(OpenHoursOrderSettingsForm.name)

  const {apiService} = useContext(ApiContext);
  const {success, error} = useContext(NotificationContext);

  const [orderOptions, setOrderOptions] = useState<OrderSettings>(props.orderOptions);
  const [isLoading, setIsLoading] = useState(true);

  const formik = useFormik({
    initialValues: {
      pre_order_enabled: false,
      pre_order_days_limit: 5,
    },
    validate: validate,
    onSubmit: updateOrderSettings
  });

  useEffect(() => {
    if (props?.orderOptions) {
      setOrderOptions(props.orderOptions)
    }
  }, [props])

  useEffect(() => {
    if (orderOptions) {
      formik.setValues({...orderOptions})
    }
  }, [orderOptions])


  function validate(values) {
    const errors = {};
    logger.info("Validating form")
    if (values.pre_order_enabled) {
      if (isEmpty(values.pre_order_days_limit)) {
        errors.pre_order_days_limit = "Please specifiy a pre order limit"
      }
    }
    if (values.pre_order_days_limit < 0) {
      errors.pre_order_days_limit = "Pre order limit cannot be less that zero"
    }
    return errors;
  }

  function updateOrderSettings() {
    apiService.orderSettingsUpdate(props?.restaurantId, {...formik.values})
        .then((data) => {
          success("Update successful", data.message)
          const result = data.result
          setOrderOptions(result)
          return;
        })
        .catch(e => error("Update failed", e.message));
  }

  return (
      <Stack spacing={10}>
        <FormControl id="preOrderEnabled">
          <HStack align={"center"}>
            <FormLabel>Enable Pre Order?</FormLabel>
            <Switch isChecked={formik.values.pre_order_enabled}
                    id={"pre_order_enabled"} name={"pre_order_enabled"}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
            />
          </HStack>
          <FormHelperText>This will allow your customers to pre order.</FormHelperText>
        </FormControl>

        <FormControl id="preOrderDaysLimit">
          <HStack align={"center"}>
            <FormLabel>Pre Order Limit</FormLabel>

            <NumberInputWithIncrements
                min={0}
                defaultValue={orderOptions?.pre_order_days_limit}
                rightAddon={"days"}
                onChange={(value) => formik.setFieldValue("pre_order_days_limit", value)}
            />
          </HStack>
          <FormHelperText>
            This will allow customers to pre-order upto {formik.values.pre_order_days_limit} days.
          </FormHelperText>
        </FormControl>

        <Button onClick={updateOrderSettings} isFullWidth>Save Changes</Button>
      </Stack>
  )

}

export default OpenHoursOrderSettingsFormPanel;
