import styled from "styled-components";
import {colors} from "../constants/styles";
import React, {useContext, useEffect, useState, useRef} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMapPin} from "@fortawesome/free-solid-svg-icons/faMapPin";
import {HelpText, Text} from "./text";
import Loader from "./loading/Loader";
import ScrollableView from "./ScrollableView";
import {useDebounce} from "react-use";
import {ApiContext} from "../providers/ApiProvider";
import {
  InputGroup,
  HStack,
  Input,
  InputRightAddon,
  InputLeftAddon,
  Button,
  FormControl,
  FormLabel,
  Icon,
  Tag, TagLabel, TagCloseButton, Select, Stack,
  useNumberInput
} from "@chakra-ui/react";
import { FiFile } from "react-icons/fi";
import { useController } from "react-hook-form";
import {isSafeInteger, toInteger} from "lodash-es";

export const TextInput = styled.input.attrs({ type: 'text' })`
  display: block;
  width: 100%;
  min-height: 40px;
  padding: 0.2rem 0.75rem;
  font-size: 0.9rem;
  font-weight: bold;
  line-height: 2;
  color: ${colors.INPUT_TEXT};
  background-color: ${colors.WHITE};
  background-clip: padding-box;
  border: 1px solid ${colors.INPUT_BORDER};
  border-radius: 7px;
  box-sizing: border-box;
  outline: none;
  margin: 5px;
  //transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
`;
TextInput.displayName = "TextInput";



export const PasswordInput = styled.input.attrs({ type: 'password' })`
  display: block;
  width: 100%;
  min-height: 40px;
  padding: 0.2rem 0.75rem;
  font-size: 0.9rem;
  font-weight: bold;
  line-height: 2;
  color: ${colors.INPUT_TEXT};
  background-color: ${colors.WHITE};
  background-clip: padding-box;
  border: 1px solid ${colors.INPUT_BORDER};
  border-radius: 7px;
  box-sizing: border-box;
  outline: none;
  margin: 5px;
  //transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
`;
PasswordInput.displayName = "PasswordInput";



export const SelectInput = styled.select`
  min-height: 40px;
  display: block;
  width: 100%;
  padding: 0.2rem 0.75rem;
  font-size: 0.9rem;
  font-weight: bold;
  line-height: 2;
  color: ${colors.INPUT_TEXT};
  background-color: ${colors.WHITE};
  background-clip: padding-box;
  border: 1px solid ${colors.INPUT_BORDER};
  border-radius: 7px;
  box-sizing: border-box;
  outline: none;
  margin: 5px;
  //transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
`;
SelectInput.displayName = "SelectInput";


export const TextAreaInput = styled.textarea`
  display: block;
  width: 100%;
  min-height: 40px;
  padding: 0.2rem 0.75rem;
  font-size: 0.9rem;
  font-weight: bold;
  line-height: 2;
  color: ${colors.INPUT_TEXT};
  background-color: ${colors.WHITE};
  background-clip: padding-box;
  border: 1px solid ${colors.INPUT_BORDER};
  border-radius: 7px;
  box-sizing: border-box;
  outline: none;
  margin: 5px;
  //transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
`;
TextAreaInput.displayName = "TextAreaInput";



const Address = styled.div`
  padding: 15px 10px;
  border-bottom: 1px solid ${colors.BORDER_DEFAULT};
  width: 100%;
  display: flex;
  align-items: center;
`;


const NoAddresses = styled(HelpText)`
  margin-top: 30px;
`;

interface EstablishmentsAutocompleteProps {
  onAddressSelected: (any) => void,
  defaultValue: string,
}

export function EstablishmentsAutocomplete(props: EstablishmentsAutocompleteProps) {
  const {apiService} = useContext(ApiContext);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [selected, setSelected] = useState(props.defaultValue);
  const [sessionToken, setSessionToken] = useState("");
  const [results, setResults] = useState([]);

  useDebounce(() => {
    if (search == null || search === "") {
      setResults([])
      setError(null)
    } else {
      fetchAddresses();
      setSelected(null)
    }
  }, 1000,[search])

  function fetchAddresses() {
    setIsLoading(true)
    setError(null)
    apiService.restaurantPlacesGetEstablishmentsSearch(search, sessionToken)
        .then(r => {
          setSessionToken(r.sessionToken);
          setResults(r.autocompletePredictions)
        })
        .catch(e => setError(e.message))
        .finally(() => setIsLoading(false))
  }

  function onAddressSelected(address) {
    setSelected(address);
    props.onAddressSelected(address)
  }

  const isAddressEmpty = results == null || results.length === 0;


  if (selected) {
    return <TextInput value={selected.description} onChange={(event) => setSearch(event.target.value)}/>
  } else {
    return (
        <>
          <TextInput defaultValue={search} placeholder={"Search Address"}
                     onChange={(event) => setSearch(event.target.value)}/>
          {!selected && renderElements()}
          <div style={{paddingBottom: '25px'}}/>
        </>
    )
  }

  function renderElements() {
    let renderElement;
    if (error != null) {
      renderElement = <NoAddresses muted>{error}</NoAddresses>;
    } else if(isLoading) {
      renderElement = <Loader />;
    } else if (!isLoading) {
      if (isAddressEmpty) {
        renderElement = <NoAddresses muted>No results</NoAddresses>;
      } else {
        renderElement = (
            <ScrollableView style={{width: "100%"}}>
              {renderAddressElements()}
            </ScrollableView>
        )
      }
    }
    return renderElement;
  }

  function renderAddressElements() {
    return results.map((address) => {
      return (
          <Address key={address} onClick={() => onAddressSelected(address)}>
            <FontAwesomeIcon icon={faMapPin}
                             style={{display: 'block', marginRight: '1em'}}
                             color={colors.BORDER_DEFAULT}/>
            <Text>{address.description}</Text>
          </Address>
      );
    });
  }
}



const Checkbox = styled.input.attrs({ type: 'checkbox' })`
  box-sizing: border-box;
  padding: 0;
  border-radius: 3px;
  background-color: white !important;
`;

const CheckboxOption = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  min-height: 2.5em;
  //border-bottom: 1px solid ${colors.BORDER_DEFAULT};
  flex-direction: row;
  justify-content: space-between;
`;
CheckboxOption.displayName = "CheckboxOption";
const CheckboxOptionLabel = styled.div`
  margin-left: 20px;
  font-weight: 200;
`;

export function CheckboxWithText(props: {label: string, selected?: boolean, onToggle: (boolean) => void}) {
  const {label, selected, onToggle} = {...props};
  const [isChecked, setIsChecked] = useState(selected || false);

  useEffect(() => {
    onToggle(isChecked);
  }, [isChecked, onToggle])

  return (
      <CheckboxOption key={label} selected={selected} onClick={() => setIsChecked((current) => !current)}>
        <CheckboxOptionLabel>{label}</CheckboxOptionLabel>
        <Checkbox style={{marginRight: '20px'}} checked={isChecked} readOnly={true}/>
      </CheckboxOption>
  )
}

interface InputWithSaveButtonProps {
  label: string,
  name: string,
  value: string,
  onSave: (string) => void,
  isLoading?: boolean,
  addon?: string,
}

export function InputWithSaveButton(props : InputWithSaveButtonProps) {

  // Props
  const {label, name, value, onSave, addon, isLoading} = {...props}

  const [newValue, setNewValue] = useState(value)

  useEffect(() => {
    setNewValue(value)
  }, [value])

  function onClick() {
    onSave(newValue)
  }
  return (
      <FormControl>
        <FormLabel>{label}</FormLabel>
        <Stack>
          <InputGroup>
            {addon && <InputLeftAddon children={addon}/>}
            <Input name={name} value={newValue} onChange={(event) => setNewValue((event.target.value))}/>
          </InputGroup>
          <Button
            size={"sm"}
            onClick={onClick}
            isDisabled={newValue == value}
            isLoading={isLoading}
          >
            Save Changes
          </Button>
        </Stack>
      </FormControl>
  )
}

interface NumberInputWithIncrementsProps {
  defaultValue?: number,
  step?: number,
  min?: number,
  max?: number,
  rightAddon?: string,
  leftAddon?: string,
  onChange: (number) => void
}

export function NumberInputWithIncrements(props : NumberInputWithIncrementsProps) {
  const {defaultValue=0, min=0, max=undefined, rightAddon=null, leftAddon=null, step=1, onChange, } = {...props}

  const { valueAsNumber, getInputProps, getIncrementButtonProps, getDecrementButtonProps } =
      useNumberInput({
        step: step,
        defaultValue: defaultValue,
        min: min,
        max: undefined,
        precision: 0,
      })
  const inc = getIncrementButtonProps()
  const dec = getDecrementButtonProps()
  const input = getInputProps({ isReadOnly: false })

  useEffect(() => {
    onChange(valueAsNumber)
  }, [valueAsNumber])

  return (
      <HStack align={"center"}>
        <Button {...dec}>-</Button>
        <InputGroup >
          {leftAddon && <InputLeftAddon children={leftAddon}/>}
          <Input {...input}/>
          {rightAddon && <InputRightAddon children={rightAddon}/>}
        </InputGroup>
        <Button {...inc}>+</Button>
      </HStack>
  )
}

export const FileUpload = ({ label, placeholder, acceptedFileTypes, image, isLoading, onChange=()=>null, onSubmit=()=>null }) => {
  const inputRef = useRef();

  const [uploadedImage, setUploadedImage] = useState(null);

  return (
      <FormControl>
        <FormLabel htmlFor="writeUpFile">{label}</FormLabel>
        <img src={uploadedImage == null ? image : uploadedImage} width={"300px"}
             height={"auto"} alt={"restaurant logo"}/>
        <br/>
        <InputGroup>
          <InputLeftAddon
              pointerEvents="none"
              children={<Icon as={FiFile} />}
          />
          <input
              type='file' accept={acceptedFileTypes} ref={inputRef}
              onChange={(e) => {
                setUploadedImage(e.target.files[0])
                onChange(e.target.files)
              }}
            style={{ display: 'none' }}/>
          <Input
              placeholder={placeholder || "Your file ..."}
              onClick={() => inputRef.current.click()}
              value={uploadedImage?.name}
          />

          <br/>
          {uploadedImage != null &&
          <Button onClick={onSubmit} loading={isLoading}>Save Changes</Button>
          }
        </InputGroup>
      </FormControl>
  );
}

interface TagSelectProps {
  options: Array<any>,
  defaultTags: Array<string>
}
export function TagSelect({onChange, options, defaultTags} : TagSelectProps) {

  const [tags, setTags] = useState(defaultTags || []);

  function onItemSelected(event) {
    setTags(t => {
      const tagToAdd = event.target.value
      let currentTags = t
      if (!currentTags) {
        currentTags = []
      }

      const index = currentTags.findIndex(c => c === tagToAdd)
      if (index < 0) {
        currentTags.push(tagToAdd)
      } else {
        currentTags[index] = tagToAdd
      }
      return currentTags
    })
  }

  return (
      <Stack spacing={2}>
        {tags?.map(c =>
            <Tag>
              <TagLabel>{c.title}</TagLabel>
              <TagCloseButton/>
            </Tag>
        )}
        <Select
            onChange={onItemSelected}
        >
          {options.map(h => <option value={h}>{h.title}</option>)}
        </Select>
      </Stack>
  )
}
