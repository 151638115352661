import {Restaurant} from "../../models/models";
import React, {useContext, useEffect, useState} from "react";
import {
  Alert, Button, Drawer, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay, Grid, GridItem, HStack,
  Stack, Tab, TabList, Tabs, Text, Flex, AlertIcon, AlertTitle, AlertDescription, Link, CloseButton
} from "@chakra-ui/react";
import Loader from "../../components/loading/Loader";
import MenuEditor from "./components/MenuEditor";
import {ApiContext} from "../../providers/ApiProvider";
import {DashboardBase, DashboardBasePanel} from "./components/DashboardBase";
import {NotificationContext} from "../../providers/NotificationProvider";
import {isEmpty} from "lodash-es";
import {currentFormatter} from "../../utils/InputValidators";
import ErrorMessageBoundary from "../../components/error/ErrorMessageBoundary";
import CreateMenuGroupForm from "./components/CreateMenuGroupForm";
import CreateMenuItemFormDrawer from "./components/CreateMenuItemFormDrawer";
import {Link as RouterLink} from "react-router-dom";
import routes from "../../constants/routes";
import {DASHBOARD_PAGES} from "./DashboardPage";

interface Props {
  restaurant: Restaurant | null
}
function MenuDashboard(props : Props) {

  const {restaurant=null} = {...props};


  const {success, error} = useContext(NotificationContext)
  const {apiService} = useContext(ApiContext);

  const [isLoading, setIsLoading] = useState(true);
  const [menuItemGroups, setMenuItemGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState({});
  const [selectedMenuItem, setSelectedMenuItem] = useState(null);
  const [activeKey, setActiveKey] = useState();
  const [showCreateMenuDrawer, setShowCreateMenuDrawer] = useState(false);
  const [showDeleteCategoryDrawer, setShowDeleteCategoryDrawer] = useState(false);
  const [showAddMenuGroup, setShowAddMenuGroup] = useState(false);


  useEffect(() => {
    if (restaurant?.id) {
      fetchMenu();
    }
  }, [restaurant])

  useEffect(() => {
    if (selectedGroup) {
      const el = document.getElementById(selectedGroup?.group_name);
      if (el) {
        el.scrollIntoView()
      }
    }
  }, [selectedGroup])

  useEffect(() => {
    console.log("selectedMenuItem", selectedMenuItem)
    setShowCreateMenuDrawer(selectedMenuItem !== null)
  }, [selectedMenuItem])

  useEffect(() => {
    if (!showCreateMenuDrawer) {
      setSelectedMenuItem(null)
    }
  }, [showCreateMenuDrawer])

  function deleteCategory() {
    setIsLoading(true)
    apiService.menuItemGroupDelete(restaurant?.id, selectedGroup.id)
        .then((r) => {
          success("Deleted Category", r.message)
          setShowDeleteCategoryDrawer(false)
          fetchMenu()
        })
        .catch(e => error("Delete category failed", e.message))
        .finally(() => setIsLoading(false))
  }

  function fetchMenu() {
    setIsLoading(true)
    apiService.menuGet(restaurant?.id)
        .then(setMenuItemGroups)
        .catch(e => error("Failed to load your menu", e.message))
        .finally(() => setIsLoading(false))
  }

  function onMenuItemAdded() {
    fetchMenu()
    setShowCreateMenuDrawer(false)
  }

  function onAddMenuItemClick(menuGroup=null, menuItem=null) {
    setSelectedGroup(menuGroup);
    setSelectedMenuItem(menuItem)
    setShowCreateMenuDrawer(true)
  }

  return (
    <DashboardBase title={"Menu"}>
      <Stack>
        <DashboardBasePanel
            title={"Menu Categories"}
            onAction={fetchMenu}
        >
          <Stack>
            <div style={{width: '100%'}}>
              <ErrorMessageBoundary>
                <Loader isLoading={isLoading}>
                  {/* ----------------------------------------------- */}
                  {/* Tabs start */}

                  <Tabs orientation={"horizontal"} size={"md"} align={"start"} isLazy variant="soft-rounded" colorScheme={"primary"}>
                    <TabList overflowX={"scroll"} overflowY={"hidden"} padding={"3px"}>
                      {menuItemGroups && menuItemGroups?.length > 0 ?
                          menuItemGroups?.map((group, index) => (
                          <Tab
                              key={group.group_name}
                              justify={"center"}
                              textAlign={"left"}
                              minWidth={"max-content"}
                              onClick={() => setSelectedGroup(group)}
                          >
                            {group.group_name}
                          </Tab>
                          ))
                          : <Alert status="info">
                            <AlertIcon/>
                            <Stack>
                              <AlertTitle mr={2}>No menu categories</AlertTitle>
                              <AlertDescription>
                                Please add a menu category before you can add menu items.
                              </AlertDescription>
                            </Stack>
                            <CloseButton position="absolute" right="8px" top="8px"/>
                          </Alert>
                      }
                      {/*<Tab*/}
                      {/*    key={"add-category"}*/}
                      {/*    justify={"center"}*/}
                      {/*    textAlign={"left"}*/}
                      {/*    minWidth={"max-content"}*/}
                      {/*>*/}
                      {/*  Add Category*/}
                      {/*</Tab>*/}
                    </TabList>
                  </Tabs>


                  {/* Tabs end */}
                  {/* ----------------------------------------------- */}


                </Loader>
              </ErrorMessageBoundary>
            </div>
          </Stack>
        </DashboardBasePanel>

          {menuItemGroups?.map((group, index) =>
              <DashboardBasePanel
                  title={group?.group_name}
                  p={4}
                  key={group?.group_name}
                  id={group?.group_name}
              >
                <Flex wrap={"wrap"}>
                  {group?.items.sort((a,b) => a?.item_name.localeCompare(b.item_name)).map(item =>
                      <MenuListItem key={item.item_name} item={item} onSelect={setSelectedMenuItem}/>
                  )}

                  <Flex align={"center"} justify={"center"} width={"100%"}>

                    <Button
                        margin={"8px"}
                        border={"2px dashed rgba(0,0,0,0.05)"}
                        borderRadius={"5px"}
                        bg={"rgba(0,0,0,0.02)"}
                        minHeight={"150px"}
                        color={"primary.500"}
                        width={"100%"}
                        onClick={() => onAddMenuItemClick(group, null)}
                    >
                      + Add Item
                    </Button>
                  </Flex>

                  {/*<Stack>*/}
                  {/*  {isEmpty(group?.items) && <Alert status={"info"}>No menu items for this category</Alert>}*/}
                  {/*  <Stack spacing={4} style={{padding: '10px 0'}}>*/}
                  {/*    <Button onClick={() => setShowCreateMenuDrawer(prv => !prv)}>Add {group?.group_name} Menu Item</Button>*/}
                  {/*    <Button colorScheme={"red"} onClick={() => setShowDeleteCategoryDrawer(true)}>Delete Category</Button>*/}
                  {/*  </Stack>*/}
                  {/*</Stack>*/}
                </Flex>
              </DashboardBasePanel>
          )}


        <DashboardBasePanel
            title={"Add Category"}
            p={4}
            key={"Add Category"}
            id={"Add Category"}
        >
          <CreateMenuGroupForm restaurantId={restaurant?.id} onComplete={() => fetchMenu()}/>
        </DashboardBasePanel>
      </Stack>

    {/*   Drawers */}

      {/* Create menu drawer start */}
      <CreateMenuItemFormDrawer
          restaurantId={restaurant?.id}
          menuItemGroup={selectedGroup}
          menuItem={selectedMenuItem}
          onComplete={onMenuItemAdded}
          onClose={() => setShowCreateMenuDrawer(false)}
          isOpen={showCreateMenuDrawer}
      />
      {/* Create menu drawer end */}

      {/* Delete menu drawer start */}
      <Drawer isOpen={showDeleteCategoryDrawer} placement={"bottom"}
              blockScrollOnMount={false}
              onClose={() => setShowDeleteCategoryDrawer(false)}>
        <DrawerOverlay>
          <DrawerContent>
            <DrawerCloseButton/>
            <DrawerHeader>Are you sure you want to delete your Menu Category?</DrawerHeader>
            <ErrorMessageBoundary>
              <div style={{display: 'flex', flexDirection: 'column', padding: '5px 10px'}}>
                <div style={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: 1,
                  justifyContent: 'center',
                  alignItems: 'center',
                  paddingTop: '20px'
                }}>
                  <Text>Deleting this will also delete all items under this category. Are you sure you want to
                    continue?</Text>
                  <Stack spacing={4} style={{paddingTop: "20px"}}>
                    <Button colorScheme={"red"} onClick={deleteCategory}>Delete</Button>
                    <Button onClick={() => setShowDeleteCategoryDrawer(false)}>Cancel</Button>

                  </Stack>
                </div>
              </div>
            </ErrorMessageBoundary>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
      {/* Delete menu drawer end */}

    {/* End Drawers */}

    </DashboardBase>
  )
}



function MenuListItem({item, onSelect=()=> null}) {
  function onClick() {
    console.log("Menu item clicked")
    onSelect(item)
  }
  return (
      <Stack
          padding={["8px"]}
          width={["100%", "100%", "50%","33%"]}
      >
        <Grid templateColumns={["80% 10% 10%", "80% 10% 10%", "70% 20% 10%"]} row={4} onClick={onClick}
              padding={"5px"}
              border={"2px dashed rgba(0,0,0,0.05)"}
              borderRadius={"5px"}
              height={"150px"}
        >
          <GridItem rowStart={1} colStart={1} colEnd={4} height={"25px"}>
            <Text color="gray.900" fontWeight={"bold"} overflow={"hidden"} textOverflow={"ellipsis"} whiteSpace={"nowrap"}>
              {item.item_name} {!isEmpty(item.item_short_name) && `(${item.item_short_name})`}
            </Text>
          </GridItem>
          <GridItem rowStart={2} colStart={1} colEnd={4} height={"25px"}>
            <Text color="gray.700" overflow={"hidden"} textOverflow={"ellipsis"} whiteSpace={"nowrap"}>{item.description}</Text>
          </GridItem>
          <GridItem rowStart={3} colStart={1} colEnd={4} height={"25px"}>
            <Text color="gray.500" overflow={"hidden"} textOverflow={"ellipsis"} whiteSpace={"nowrap"}>{item.dietary_comments}</Text>
          </GridItem>
          <GridItem rowStart={4} colStart={1} colEnd={4} height={"25px"}>
            <HStack>
              <Text color="gray.500">{item.variations && item.variations.length ? 'Variations Available' : ''} </Text>
            </HStack>
            {/*<Stack spacing={0}>*/}
            {/*  {item.variations?.map(v => (*/}
            {/*      <HStack>*/}
            {/*        <Text color="gray.700">{v?.variation_name}: </Text>*/}
            {/*        <Text color="gray.500">{v?.options?.map(o => o.option_name).join(",")}</Text>*/}
            {/*      </HStack>*/}
            {/*  ))}*/}
            {/*</Stack>*/}
          </GridItem>
          <GridItem rowStart={5} colStart={2} colEnd={4} height={"25px"}>
            <Text>{currentFormatter.format(item.price)}</Text>
          </GridItem>
        </Grid>
      </Stack>
  )
}



export default MenuDashboard;
