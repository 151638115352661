import React, {createContext, useContext, useEffect, useState} from 'react';
import {FIREBASE_CONFIG, RECAPTCHA_V3_SITE_KEY} from '../config/halalmunchAppConfig'
import firebase from 'firebase'
import 'firebase/firestore'
import 'firebase/app-check'
import {AuthContext} from "./AuthProvider";
import * as Sentry from "@sentry/react";
import {NotificationContext} from "./NotificationProvider";

interface FirebaseContextProps {
  firestoreDb: firebase.firestore.Firestore | null | undefined,
  firebaseUser: firebase.auth.UserCredential | null | undefined,
  loginWithEmailAndPassword: (token: string) => void
  isAuthenticatedWithFirebase: boolean
}

export const FirebaseContext = createContext<FirebaseContextProps>({
  firestoreDb: null,
  firebaseUser: null,
  loginWithEmailAndPassword: (token) => null,
  isAuthenticatedWithFirebase: false
});


export function FirebaseProvider(props: {children: any}) {
  const [firestoreDb, setFirestoreDb] = useState<firebase.firestore.Firestore>();
  const [firebaseUser, setFirebaseUser] = useState<firebase.auth.UserCredential>();
  const {user} = useContext(AuthContext);
  const {error} = useContext(NotificationContext);

  useEffect(() => {
    const app = firebase.initializeApp(FIREBASE_CONFIG)
    const appCheck = firebase.appCheck(app)
    appCheck.activate(RECAPTCHA_V3_SITE_KEY, true)
    setFirestoreDb(firebase.firestore(app))
  }, [])

  // useEffect(() => {
  //   if (user) {
  //     loginWithEmailAndPassword(user?.id)
  //   }
  // }, [user])

  function loginWithEmailAndPassword(token : string) {
    firebase.auth().signInWithCustomToken(token)
        .then((user) => {
          console.log("firebaseUser", user)
          setFirebaseUser(user)
        })
        .catch((e) => {
          error("Authentication error", "An error occurred authenticating your account,")
          Sentry.captureException(e)
        })
        .finally(() => {

        })
  }

  function isAuthenticatedWithFirebase() {
    return firebaseUser != null
  }

  return (
      <FirebaseContext.Provider
          value={{
            firestoreDb: firestoreDb,
            firebaseUser: firebaseUser,
            loginWithEmailAndPassword: loginWithEmailAndPassword,
            isAuthenticatedWithFirebase: isAuthenticatedWithFirebase(),
          }}
      >
        {props.children}
      </FirebaseContext.Provider>
  )
}
