import {Restaurant} from "../../models/models";
import React from "react";
import OpenStatus from "./components/OpenStatus";
import LiveOrdersReporting from "./components/LiveOrdersReporting";
import {DashboardBase} from "./components/DashboardBase";
import {Stack} from '@chakra-ui/react'
import {AddMenuAlert, AddOpeningHoursAlert} from "./DashboardAlerts";
import Loader from "../../components/loading/Loader";
import SummaryCurrentOrdersPanel from "./components/SummaryCurrentOrdersPanel";

interface SummaryDashboardProps {
  restaurant: Restaurant | null,
  isLoading?: boolean
}
function SummaryDashboard(props : SummaryDashboardProps) {

  const {restaurant=null, isLoading=false} = {...props};

  return (
      <DashboardBase title={"Overview"}>
        <Stack spacing={6}>
          <AddMenuAlert restaurant={restaurant}/>
          <AddOpeningHoursAlert restaurant={restaurant}/>

          <SummaryCurrentOrdersPanel restaurant={restaurant} />
        </Stack>
      </DashboardBase>
  )
}


export default SummaryDashboard;
