import MenuItemGroup from "../../../models/MenuItemGroup";
import React, {useContext, useEffect, useState} from "react";
import {MenuItem, MenuItemVariation, MenuItemVariationOption} from "../../../models/models";
import {ApiContext} from "../../../providers/ApiProvider";
import {NotificationContext} from "../../../providers/NotificationProvider";
import {
  Stack,
  Input,
  FormControl,
  FormHelperText,
  FormErrorMessage,
  FormLabel,
  Button,
  Textarea,
  Divider,
  Flex,
  InputGroup,
  InputLeftAddon,
  HStack,
  CloseButton,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  Drawer,
  DrawerFooter,
  DrawerBody,
  TabList,
  Tab,
  TabPanels, TabPanel, Tabs
} from "@chakra-ui/react";
import {useFormik, FieldArray} from "formik";
import {isEmpty} from "lodash-es";
import ErrorMessageBoundary from "../../../components/error/ErrorMessageBoundary";
import Logger from "../../../utils/Logger";

interface CreateMenuItemFormProps {
  restaurantId: string,
  menuItemGroup: MenuItemGroup,
  menuItem?: MenuItem,
  onComplete: (item: MenuItem) => void
  isOpen: boolean,
  onClose: () => void,
}

function CreateMenuItemFormDrawer(props: CreateMenuItemFormProps) {
  const logger = new Logger(CreateMenuItemFormDrawer.name)
  const {apiService} = useContext(ApiContext);
  const {success, error} = useContext(NotificationContext);
  const [isCategoriesLoading, setIsCategoriesLoading] = useState(false)
  const [buttonLabel, setButtonLabel] = useState("Add Item")
  const [isLoading, setIsLoading] = useState(false)

  const {restaurantId, menuItemGroup, menuItem, onComplete=()=>null, isOpen=false, onClose=()=>null} = {...props};

  const formik = useFormik({
    initialValues: {
      id: "",
      item_name: "",
      item_short_name: "",
      description: "",
      dietary_comments: "",
      price: "",
      variations: []
    },
    onSubmit: onSubmit,
    validate: validate,
  })

  useEffect(() => {
    formik.resetForm()
    if (menuItem) {
      formik.resetForm()
      formik.setValues({...menuItem})
      setButtonLabel("Update Item")
    } else {
      setButtonLabel("Add Item")

    }
  }, [menuItem, isOpen])


  function onSubmit(values) {
    console.log("Submitting form with values", values)
    const menuItem = Object.assign(new MenuItem(), {
      ...formik.values,
      item_group_id: menuItemGroup?.id,
      restaurantId: restaurantId,
    })
        apiService.menuUpdate(restaurantId, menuItem)
        .then(r => {
          success("Successfully updated menu", r.message)
          onComplete(r.data)
        })
        .catch(e => error("Menu update failed", e.message))
        .finally(() => formik.setSubmitting(false))
  }

  function validate(values) {
    logger.info("validating form")
    let errors = {};

    if (isEmpty(values.item_name)) {
      errors.item_name = "Item name cannot be empty"
    }

    if (values.price == null || String(values.price).trim() == "" || values.price < 0) {
      errors.price = "Price cannot be less than zero"
    }

    let variationErrors = []
    values.variations.forEach((variation, index) => {
      let variationError = {}
      if (isEmpty(variation.variation_name)) {
        variationError.variation_name = "Variation name cannot be empty"
      }
      if (variation.option_min == null) {
        variationError.option_min = "Variation minimum options cannot be empty if variation has been set"
      }
      if (variation.option_min > variation.options.length) {
        variationError.option_min = "Variation minimum cannot be more than the options available"
      }
      if (variation.option_max > variation.option_min) {
        variationError.option_max = "Variation option maximum cannot be more than the minimum"
      }

      let variationOptionErrors = []
      variation.options.forEach((option, optionIndex) => {
        let optionError = {}
        if (isEmpty(option.option_name)) {
          optionError.option_name = "Option name cannot be empty"
        }
        if (option.price_change == null || option.price_change === "") {
          optionError.price_change = "Option price cannot be empty"
        }
        if (isEmpty(optionError)) {
          variationOptionErrors.push(null)
        } else {
          variationOptionErrors.push(optionError)

        }
      })

      if (variationOptionErrors.some(vo => vo != null)) {
        variationError.options = variationOptionErrors
      }

      if (isEmpty(variationError)) {
        variationErrors.push(null)
      } else {
        variationErrors.push(variationError)
      }
    })

    if (variationErrors.some(ve => ve != null)) {
      errors.variations = variationErrors
    }

    logger.info("form validation errors", errors)

    return errors
  }

  function isVariationInvalid(index, field) {
    return !isEmpty(formik.errors.variations) && !isEmpty(formik.errors.variations[index]) && formik.errors.variations[index][field]
  }
  function getVariationFieldError(index, field) {
    return isEmpty(formik.errors.variations) || isEmpty(formik.errors.variations[index]) ? null : formik.errors.variations[index][field];
  }

  function isVariationOptionInvalid(variationIndex, optionIndex, field) {
    return !isEmpty(formik.errors.variations) && !isEmpty(formik.errors.variations[variationIndex])
    && !isEmpty(formik.errors.variations[variationIndex].options) && !isEmpty(formik.errors.variations[variationIndex].options[optionIndex])
    && formik.errors.variations[variationIndex].options[optionIndex][field]
  }
  function getVariationOptionFieldError(variationIndex, optionIndex, field) {
    return !isEmpty(formik.errors.variations)  && !isEmpty(formik.errors.variations[variationIndex])
    && !isEmpty(formik.errors.variations[variationIndex].options) && !isEmpty(formik.errors.variations[variationIndex].options[optionIndex])
        ? formik.errors.variations[variationIndex].options[optionIndex][field] : null
  }

  function addNewVariation() {
    const newVariations = [...formik.values.variations]
    newVariations.push(new MenuItemVariation())
    formik.setFieldValue("variations", newVariations)
  }

  function deleteVariation(variationIndex) {
    const newVariations = [...formik.values.variations]
    newVariations.splice(variationIndex, 1)
    formik.setFieldValue("variations", newVariations)
  }

  function addNewOption(variationIndex) {
    const newOptions = [...formik.values.variations[variationIndex].options]
    newOptions.push(new MenuItemVariationOption())
    formik.setFieldValue(`variations[${variationIndex}].options`, newOptions)
  }

  function deleteOption(variationIndex, optionIndex) {
    const newOptions =  [...formik.values.variations[variationIndex].options]
    newOptions.splice(optionIndex, 1)
    formik.setFieldValue(`variations[${variationIndex}].options`, newOptions)
  }

  function closeDrawer() {
    onClose()
  }

  function onSubmitClick() {
    logger.info("on form submit clicked")
    formik.submitForm()
  }

  return (
      <Drawer isOpen={isOpen} placement={"bottom"}
              blockScrollOnMount={false}

              onClose={closeDrawer} >
        <DrawerOverlay>
          <DrawerContent style={{height: '100%'}}>
            <DrawerCloseButton/>
            <DrawerHeader>Add item</DrawerHeader>
            <DrawerBody padding={["0 5px 10px 5px", "0 10px 10px 10px","0 20px 10px 20px"]}>
            <ErrorMessageBoundary>
              <Flex direction={"column"} justify={"space-between"} align={"center"}  flex={1} width={"100%"}>
                <Tabs size={"md"} isLazy variant="enclosed" colorScheme={"primary"} width={"100%"}>
                  <TabList >
                    <Tab
                        justify={"center"}
                        textAlign={"left"}
                        minWidth={"max-content"}
                    >
                      Item Details
                    </Tab>
                    <Tab
                        justify={"center"}
                        textAlign={"left"}
                        minWidth={"max-content"}
                    >
                      Item Variations
                    </Tab>
                  </TabList>
                  <TabPanels>
                    <TabPanel p={4}>
                      <Stack spacing={4}>
                        <Stack direction={["column", "column", "row"]}>
                          <FormControl>
                            <FormLabel>Item ID</FormLabel>
                            <Input
                                id="id"
                                name="id"
                                placeholder="Chicken Burger"
                                values={formik.values.id}
                                readOnly
                                disabled
                            />
                          </FormControl>
                          <FormControl isRequired isInvalid={formik.errors.item_name && formik.touched.item_name}>
                            <FormLabel>Item Display Name</FormLabel>
                            <Input
                                id="item_name"
                                name="item_name"
                                placeholder="Chicken Burger"
                                onChange={formik.handleChange}
                                onBlue={formik.handleBlur}
                                defaultValue={formik.values.item_name}
                            />
                            <FormErrorMessage>{formik.errors.item_name}</FormErrorMessage>
                            <FormHelperText>This is what your customers will see on our platform.</FormHelperText>
                          </FormControl>
                          <FormControl isInvalid={formik.errors.item_short_name && formik.touched.item_short_name}>
                            <FormLabel>Item Short Name</FormLabel>
                            <Input
                                id="item_short_name"
                                name="item_short_name"
                                placeholder="CHICK BGR"
                                onChange={formik.handleChange}
                                onBlue={formik.handleBlur}
                                defaultValue={formik.values.item_short_name}
                            />
                            <FormErrorMessage>{formik.errors.item_short_name}</FormErrorMessage>
                            <FormHelperText>This will be used for orders and receipts if set.</FormHelperText>
                          </FormControl>
                        </Stack>
                        <FormControl isInvalid={formik.errors.description && formik.touched.description}>
                          <FormLabel>Description</FormLabel>
                          <Textarea
                              name="description"
                              placeholder="Describe how delicious your dish is"
                              rows={3}
                              onChange={formik.handleChange}
                              onBlue={formik.handleBlur}
                              defaultValue={formik.values.description}
                          />
                          <FormErrorMessage>{formik.errors.description}</FormErrorMessage>
                        </FormControl>
                        <FormControl isInvalid={formik.errors.dietary_comments && formik.touched.dietary_comments}>
                          <FormLabel>Dietary and allergens</FormLabel>
                          <Textarea
                              name="dietary_comments"
                              placeholder="Contains nuts"
                              rows={3}
                              onChange={formik.handleChange}
                              onBlue={formik.handleBlur}
                              defaultValue={formik.values.dietary_comments}
                          />
                          <FormErrorMessage>{formik.errors.dietary_comments}</FormErrorMessage>
                        </FormControl>
                        <FormControl isRequired isInvalid={formik.errors.price && formik.touched.price}>
                          <FormLabel>Price</FormLabel>
                          <InputGroup>
                            <InputLeftAddon>£</InputLeftAddon>
                            <Input
                                id={"price"}
                                name={"price"}
                                defaultValue={formik.values.price || ""}
                                onChange={formik.handleChange}
                                onBlue={formik.handleBlur}
                                type={"number"}
                                min={0}
                                step={0.01}
                            />
                          </InputGroup>
                          <FormErrorMessage>{formik.errors.price}</FormErrorMessage>
                        </FormControl>
                      </Stack>
                    </TabPanel>
                    <TabPanel>
                      <Stack spacing={8}>
                        {formik.values.variations.map((variation, variationIndex) =>
                            <Stack key={variationIndex} spacing={4} border={"dashed 2px #e1e1e1"} borderRadius={"10px"} padding={["0.5rem 0.8rem", "0.5rem 0.8rem", "0.5rem 1rem"]}>
                              <Stack spacing={8} display={"flex"} direction={["column", "column", "row"]} justify={["start", "start", "space-between"]} align={["center", "center", "start"]}>

                                <Stack spacing={4}>
                                  <FormControl
                                      isRequired
                                      isInvalid={isVariationInvalid(variationIndex, "variation_name")}
                                  >
                                    <FormLabel>Variation Name</FormLabel>
                                    <Input
                                        id={`variations.${variationIndex}.variation_name`}
                                        name={`variations.${variationIndex}.variation_name`}
                                        defaultValue={variation.variation_name}
                                        placeholder="Select a drink to your meal"
                                        onChange={formik.handleChange}
                                        onBlue={formik.handleBlur}
                                    />
                                    <FormErrorMessage>{getVariationFieldError(variationIndex, "variation_name")}</FormErrorMessage>
                                  </FormControl>
                                  <FormControl>
                                    <FormLabel>Description</FormLabel>
                                    <Textarea
                                        id={`variations[${variationIndex}].description`}
                                        name={`variations[${variationIndex}].description`}
                                        rows={2}
                                        defaultValue={variation.description}
                                        placeholder="Select a drink to your meal"
                                        onChange={formik.handleChange}
                                        onBlue={formik.handleBlur}
                                    />
                                  </FormControl>
                                  <HStack spacing={2} align={"flex-start"}>
                                    <FormControl
                                        isRequired
                                        isInvalid={isVariationInvalid(variationIndex, "option_min")}
                                    >
                                      <FormLabel>Min options</FormLabel>
                                      <Input
                                          id={`variations[${variationIndex}].option_min`}
                                          name={`variations[${variationIndex}].option_min`}
                                          defaultValue={variation.option_min || 1}
                                          onChange={formik.handleChange}
                                          onBlue={formik.handleBlur}
                                          type={"number"}
                                      />
                                      <FormErrorMessage>{getVariationFieldError(variationIndex, "option_min")}</FormErrorMessage>
                                      <FormHelperText>The minimum number of options a user must select</FormHelperText>
                                    </FormControl>
                                    <FormControl
                                        isInvalid={isVariationInvalid(variationIndex, "option_max")}
                                    >
                                      <FormLabel>Max options</FormLabel>
                                      <Input
                                          id={`variations[${variationIndex}].option_max`}
                                          name={`variations[${variationIndex}].option_max`}
                                          defaultValue={""}
                                          onChange={formik.handleChange}
                                          onBlue={formik.handleBlur}
                                          type={"number"}
                                      />
                                      <FormErrorMessage>{getVariationFieldError(variationIndex, "option_max")}</FormErrorMessage>
                                      <FormHelperText>The maximum number of options a user can select.
                                        Leave blank if user can pick as many options as they like.</FormHelperText>
                                    </FormControl>
                                  </HStack>
                                </Stack>

                                <Stack spacing={4}>
                                  {variation?.options?.map((option, optionIndex) =>
                                      <Stack spacing={4} align={"flex-end"} key={optionIndex}>
                                        <HStack spacing={4} align={"flex-start"} >
                                          <FormControl isRequired isInvalid={isVariationOptionInvalid(variationIndex, optionIndex, "option_name")}>
                                            <FormLabel>Option {optionIndex + 1}</FormLabel>
                                            <Input
                                                id={`variations[${variationIndex}].options[${optionIndex}].option_name`}
                                                name={`variations[${variationIndex}].options[${optionIndex}].option_name`}
                                                defaultValue={option.option_name}
                                                placeholder={`Cola`}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                autoFocus
                                            />
                                            <FormErrorMessage>{getVariationOptionFieldError(variationIndex, optionIndex, "option_name")}</FormErrorMessage>
                                          </FormControl>
                                          <FormControl isRequired isInvalid={isVariationOptionInvalid(variationIndex, optionIndex, "price_change")}>
                                            <FormLabel>Price</FormLabel>
                                            <InputGroup >
                                              <InputLeftAddon children={"£"}/>
                                              <Input
                                                  id={`variations[${variationIndex}].options[${optionIndex}].price_change`}
                                                  name={`variations[${variationIndex}].options[${optionIndex}].price_change`}
                                                  defaultValue={option.price_change || "0.00"}
                                                  type={"number"}
                                                  step={"0.01"}
                                                  onChange={formik.handleChange}
                                                  onBlur={formik.handleBlur}
                                              />
                                            </InputGroup>
                                            <FormErrorMessage>{getVariationOptionFieldError(variationIndex, optionIndex, "price_change")}</FormErrorMessage>
                                          </FormControl>
                                          <CloseButton
                                              size={"sm"}
                                              colorScheme={"red"}
                                              onClick={() => deleteOption(variationIndex, optionIndex)}
                                          />
                                        </HStack>
                                        <Divider orientation="horizontal" />
                                      </Stack>
                                  )}
                                  <Button variant={"solid"} onClick={() => addNewOption(variationIndex)}>Add Option</Button>
                                </Stack>
                              </Stack>
                              <Button variant={"solid"} onClick={() => deleteVariation(variationIndex)}>Delete Variation</Button>
                              <Divider orientation="horizontal" />
                            </Stack>
                        )}
                        <Button variant={"outline"} onClick={addNewVariation}>Add Variation</Button>
                      </Stack>
                    </TabPanel>
                  </TabPanels>
                </Tabs>

              </Flex>
            </ErrorMessageBoundary>
            </DrawerBody>
            <DrawerFooter>
              <Button colorScheme={"primary"} size={"lg"} isFullWidth isLoading={formik.isSubmitting} onClick={onSubmitClick}>{buttonLabel}</Button>
            </DrawerFooter>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
  )
}

export default CreateMenuItemFormDrawer;
