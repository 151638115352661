import {Restaurant} from "../../models/models";
import React, {useContext, useEffect, useState} from "react";
import {AuthContext} from "../../providers/AuthProvider";
import {
  Alert, AlertDescription,
  AlertIcon, AlertTitle,
  Button, CloseButton, Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack, Text
} from "@chakra-ui/react";
import {Link as RouterLink, Route, Switch, useRouteMatch} from "react-router-dom";
import routes from "../../constants/routes";
import {isEmpty} from "lodash-es";
import {DASHBOARD_PAGES} from "./DashboardPage";
import {DashboardBasePanel} from "./components/DashboardBase";


export function AddMenuAlert(props: {restaurant: Restaurant | null}) {
  const {restaurant} = {...props}
  if (restaurant != null && isEmpty(restaurant?.menu)) {
    return (
        <DashboardBasePanel>
          <Alert status="info">
            <AlertIcon/>
            <Stack>
              <AlertTitle mr={2}>You have not added your menu yet!</AlertTitle>
              <AlertDescription><Link as={RouterLink} to={`${routes.DASHBOARD}/${DASHBOARD_PAGES.MENU.eventKey}`}>Click
                here to add you menu</Link></AlertDescription>
            </Stack>
            <CloseButton position="absolute" right="8px" top="8px"/>
          </Alert>
        </DashboardBasePanel>
    )
  } else {
    return null
  }
}



export function AddOpeningHoursAlert(props: {restaurant: Restaurant | null}) {
  const {restaurant} = {...props}
  if (restaurant != null && !restaurant?.open_hours?.some(oh => oh.open != null)) {
    return (
        <DashboardBasePanel>
          <Alert status="info">
            <AlertIcon/>
            <Stack>
              <AlertTitle mr={2}>You have not added any opening hours yet!</AlertTitle>
              <AlertDescription><Link as={RouterLink}
                                      to={`${routes.DASHBOARD}/${DASHBOARD_PAGES.OPEN_HOURS.eventKey}`}>Click here
                to add your opening hours</Link></AlertDescription>
            </Stack>
            <CloseButton position="absolute" right="8px" top="8px"/>
          </Alert>
        </DashboardBasePanel>
    )
  } else {
    return null
  }
}
