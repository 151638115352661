import React from 'react';
import PropTypes from 'prop-types';

import {StaticMap} from 'react-map-gl';
import {MAPBOX_ACCESS_TOKEN} from "../../config/mapboxConfig";

// Mapbox CSS
import "./mapbox-gl.css"

interface Props {
  latitude: number,
  longitude: number,
  zoom: number,
  height: string,
  width: string,
  reuseMaps: boolean,
}

export default function MapBoxStaticMap (props : Props) {

  return (
      <StaticMap {...props}
                  mapboxApiAccessToken={MAPBOX_ACCESS_TOKEN}
                  mapStyle={'mapbox://styles/mafz/cjlvdn3jf2hhl2sqkcybni7xr'}>

        {props.children}

      </StaticMap>
  );
}
