import {DashboardBasePanel} from "./DashboardBase";
import {
  FormControl, FormHelperText, FormLabel, Input,
  Stack,
} from "@chakra-ui/react";
import React from "react";
import {Restaurant} from "../../../models/models";
import Logger from "../../../utils/Logger";
import AddressUtil from "../../../utils/AddressUtil";
import MapBoxStaticMap from "../../../components/map/MapBoxStaticMap";
import {Marker} from "react-map-gl";
import YouPin from "../../../components/map/Markers/YouPin";

interface StoreLocationUpdatePanelProps {
  restaurant: Restaurant | null | undefined,
}

function StoreLocationUpdatePanel(props : StoreLocationUpdatePanelProps) {
  const logger = new Logger(StoreLocationUpdatePanel.name)

  // Props
  const {restaurant} = {...props}

  // App State

  // Component State


  return (
      <DashboardBasePanel title={"Store Location"} isLoading={restaurant == null}>
        <Stack spacing={4}>

          <FormControl>
            <FormLabel>Your address</FormLabel>
            <Input name="address" value={AddressUtil.formatAddress(restaurant?.address)} readOnly/>
            <FormHelperText>To change your address please get in touch with us.</FormHelperText>
            <br/>
            <br/>
            {restaurant?.address?.point &&
            <MapBoxStaticMap
              latitude={restaurant?.address?.point?.latitude}
              longitude={restaurant?.address?.point?.longitude}
              zoom={12}
              height={"300px"}
              width={"100%"}
              reuseMaps={true}
            >
              <Marker
                latitude={restaurant?.address?.point?.latitude}
                longitude={restaurant?.address?.point?.longitude}
              >
                <YouPin/>
              </Marker>
            </MapBoxStaticMap>
            }
          </FormControl>

        </Stack>
      </DashboardBasePanel>
  )
}

export default StoreLocationUpdatePanel;
