import React, {Fragment} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faExclamationTriangle} from '@fortawesome/free-solid-svg-icons';
import {colors} from "../../constants/styles";
import {DashboardBasePanel} from "../../pages/dashboard/components/DashboardBase";
import { Stack, Text } from '@chakra-ui/react';

interface Props {
  message: string
}

function ErrorMessage(props : Props) {

  function reloadPage() {
    window.location.reload()
  }

  return (
      <DashboardBasePanel onAction={reloadPage} actionButtonText={"Reload Page"} >
        <Stack spacing={8} align={"center"} textAlign={"center"} bg={"gray.50"} borderRadius={"5px"} margin={5} padding={5}>
          {props.message ?
              <Text>
                {props.message}
              </Text>
          :
              <Text>
                Failed to fetch data... please try refreshing the page. If this error continues please report this to <a href="mailto:support@halalmunch.com">support@halalmunch.com</a>
              </Text>
          }
        </Stack>
      </DashboardBasePanel>
  )
}


export default ErrorMessage;
