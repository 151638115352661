import styled from "styled-components";
import PropTypes from 'prop-types';
import ViewWrapper from '../common/ViewWrapper';

const ScrollableView = styled.div`
  overflow-y: ${props => props.horizontal ? "hidden" : "scroll"};
  overflow-x: ${props => props.horizontal ? "scroll" : "hidden"};
  display: flex;
  flex-direction: ${props => props.horizontal ? "row" : "column"};;
  scroll-behavior: smooth;
  overflow-scrolling: touch;
  -webkit-overflow-scrolling: touch;
  ${props => props.showScroll && `
  -webkit-scrollbar: none;
  -ms-overflow-style: none;
  `}
`;

ScrollableView.propTypes = {
  horizontal: PropTypes.bool,
  showScroll: PropTypes.bool
};

ScrollableView.defaulProps = {
  horizontal: false,
  showScroll: false,
};

ScrollableView.displayName = "ScrollableView";

export default ScrollableView;
