import styled, {css} from 'styled-components';
import {colors} from "../constants/styles";


interface BaseHeadingProps {
  primary?: boolean,
  secondary?: boolean,
  tertiary?: boolean,
  dark?: boolean,
}

export const Heading1 = styled.h1<BaseHeadingProps>`
  font-family: "Signika", "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin-block-start: 0.8em;
  margin-block-end: 0.7em;
  
  ${props => props.primary && css`
    color: ${colors.PRIMARY}
  `}
  
  ${props => props.secondary && css`
    color: ${colors.SECONDARY}
  `}

  ${props => props.tertiary && css`
    color: ${colors.TERTIARY}
  `}
  
  ${props => props.dark && css`
    color: ${colors.WHITE}
  `}
`;

export const Heading2 = styled.h2<BaseHeadingProps>`
  font-family: "Signika", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 800;
  margin-block-start: 0.75em;
  margin-block-end: 0.5em;

  ${props => props.primary && css`
    color: ${colors.PRIMARY}
  `}
  
  ${props => props.secondary && css`
    color: ${colors.SECONDARY}
  `}
  
  ${props => props.tertiary && css`
    color: ${colors.TERTIARY}
  `}
  
  ${props => props.dark && css`
    color: ${colors.WHITE}
  `}
`;

export const Heading3 = styled.h3<BaseHeadingProps>`
  font-family: "Signika", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: bold;
  margin-block-start: 0.5em;
  margin-block-end: 0.2em;
  ${props => props.primary && css`
    color: ${colors.PRIMARY}
  `}
  
  ${props => props.secondary && css`
    color: ${colors.SECONDARY}
  `}
  
  ${props => props.tertiary && css`
    color: ${colors.TERTIARY}
  `}
  
  ${props => props.dark && css`
    color: ${colors.WHITE}
  `}
`;



export const Text = styled.p<BaseHeadingProps>`
  font-family: "Signika", "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin-block-start: 0.1em;
  margin-block-end: 0.1em;
  ${props => props.primary && css`
    color: ${colors.PRIMARY}
  `}
  
  ${props => props.secondary && css`
    color: ${colors.SECONDARY}
  `}
  
  ${props => props.tertiary && css`
    color: ${colors.TERTIARY}
  `}
  
  ${props => props.dark && css`
    color: ${colors.WHITE}
  `}
`;

export const SubHeading = styled(Text)<BaseHeadingProps>`
  font-family: "Signika", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1.2em;
  margin-block-start: 0.1em;
  margin-block-end: 0.5em;
  ${props => props.primary && css`
    color: ${colors.PRIMARY}
  `}
  
  ${props => props.secondary && css`
    color: ${colors.SECONDARY}
  `}
  
  ${props => props.tertiary && css`
    color: ${colors.TERTIARY}
  `}
  
  ${props => props.dark && css`
    color: ${colors.WHITE}
  `}
`;



export const HelpText = styled(Text)`
  color: ${colors.INPUT_HELP_TEXT};
`


