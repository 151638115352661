import styled from "styled-components";
import {colors} from "../constants/styles";
import wallboard from '../assets/images/svg/wallboard_light.svg';
import React from "react";
import {Box, Heading, Stack} from "@chakra-ui/react";
import heroBackgroundImage from "../assets/images/svg/hero-background-2.svg";
import foodDeliveryImage from "../assets/images/iStock-1287632111-food-delivery-m.jpg";

export const ShowcaseInformationBlock = styled.div`
  display: flex;
  flex-direction: column;
  color: ${props => props.color || colors.TEXT_DEFAULT};
  max-width: 400px;
  min-width: 200px;
  min-height: 300px;
  padding: 15px 0;
  border-radius: 10px;
  margin: 5px;
  width: 300px;
  background-color: #fafafa;
`;


export const StandOutBox = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${props => props.color || 'white'};
  //width: 400px;
  border-radius: 15px;
  padding: 25px;
  //box-shadow: 0 5px 40px rgba(0,0,0,0.2)
  box-shadow: 0 15px 30px rgba(0,0,0,0.1);
  margin: 15px 0 15px 0;
`;

export function StandOutBoxInfo(props) {
  return <StandOutBox color={"#ffdede"} {...props}/>
}


export const DefaultBox = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${props => props.color || 'white'};
  //width: 400px;
  border-radius: 15px;
  border-color: #e1e1e1;
  border-width: 1px;
  border-style: solid;
  padding: 25px;
  //box-shadow: 0 5px 40px rgba(0,0,0,0.2)
  //box-shadow: 0 15px 30px rgba(0,0,0,0.1)
  
`;


export const DefaultNoBorderBox = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${props => props.color || 'white'};
  //width: 400px;
  border-radius: 15px;
  border-color: white;
  border-width: 1px;
  border-style: solid;
  padding: 25px;
  //box-shadow: 0 5px 40px rgba(0,0,0,0.2)
  //box-shadow: 0 15px 30px rgba(0,0,0,0.1)
  
`;

export const Panel = (props: any) =>  <Box
    boxShadow={"0 2px 5px rgb(0 0 0 / 10%)"}
    borderRadius={"10px"}
    backgroundColor={"white"}
    padding={"15px 10px"}
    {...props}
/>;


export const HeroBackground = styled.div`
  background-image: url(${foodDeliveryImage});
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: left;
    background-position-y: bottom;
`;


export const HomePageDisplayText = () => {
  return (
      <Stack spacing={4}
             height={"80%"}
             direction={"column"}
             padding={"12vh 25px"}
             justify={"center"}
             flex={1}
      >
        <Heading as={"h1"} size={"2xl"} color={"white"}>
          Halal food, all in one platform.<br/>
          <u>No monthly fees.</u>
        </Heading>
        <Heading as={"h4"} size={"md"} color={"white"}>
          Everything you need to digitalise your business.
          <br/><br/>
          Empowering small businesses with all the good stuff without the price.
        </Heading>
      </Stack>
  )
}



export const LinerGradientBox = styled(Box)`
background: rgb(231,77,60);
background: -moz-linear-gradient(90deg, rgba(231,77,60,1) 30%, rgba(255,255,255,0) 30%);
background: -webkit-linear-gradient(90deg, rgba(231,77,60,1) 30%, rgba(255,255,255,0) 30%);
background: linear-gradient(90deg, rgba(231,77,60,1) 30%, rgba(255,255,255,0) 30%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#e74d3c",endColorstr="#ffffff",GradientType=1);
`
