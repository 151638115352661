

//////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Account
//////////////////////////////////////////////////////////////////////////////////////////////////////////////

export class RestaurantAccount {
    id: string;
    restaurant_id : string;
    email: string;
    email_verified: string;
    phone: string;
    phone_verified: string;
    primary_contact : string;
    stripe_account_id: string;
    updated_at: Date;
    created_at: Date;
    restaurant: Restaurant;
    details_submitted: string;
    payouts_enabled: string;
    can_accept_payments: string;


    constructor(id: string, restaurant_id: string, email: string, email_verified: string, phone: string, phone_verified: string, primary_contact: string, stripe_account_id: string, updated_at: Date, created_at: Date, restaurant: Restaurant, details_submitted: string, payouts_enabled: string, can_accept_payments: string) {
        this.id = id;
        this.restaurant_id = restaurant_id;
        this.email = email;
        this.email_verified = email_verified;
        this.phone = phone;
        this.phone_verified = phone_verified;
        this.primary_contact = primary_contact;
        this.stripe_account_id = stripe_account_id;
        this.updated_at = updated_at;
        this.created_at = created_at;
        this.restaurant = restaurant;
        this.details_submitted = details_submitted;
        this.payouts_enabled = payouts_enabled;
        this.can_accept_payments = can_accept_payments;
    }
}

//////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Consumaer User
//////////////////////////////////////////////////////////////////////////////////////////////////////////////

export class User {
    username: string;

    constructor(username: string) {
        this.username = username;
    }
}



//////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Restaurants
//////////////////////////////////////////////////////////////////////////////////////////////////////////////

export class Restaurant {
    id: string;
    title: string;
    description: string;
    cuisines: Array<Cuisine>;
    amenities: Array<Amenity>;
    halalness: Halalness;
    address: RestaurantAddress;
    contact: RestaurantContact;
    distance: string;
    image: string;
    props: Array<any>;
    rating: number;
    type: string;
    open_hours: Array<OpenHour>;
    menu: Array<MenuItemGroup>;
    delivery: RestaurantDelivery;
    collection: RestaurantCollection;
    order_options: OrderSettings;


    constructor(id: string, title: string, description: string, cuisines: Array<Cuisine>,
                amenities: Array<Amenity>, halalness: Halalness, address: RestaurantAddress,
                contact: RestaurantContact, distance: string, image: string, props: Array<any>,
                rating: number, type: string, open_hours: Array<OpenHour>, menu: Array<MenuItemGroup>,
                delivery: RestaurantDelivery, collection: RestaurantCollection, order_options: OrderSettings) {
        this.id = id;
        this.title = title;
        this.description = description;
        this.cuisines = cuisines;
        this.amenities = amenities;
        this.halalness = halalness;
        this.address = address;
        this.contact = contact;
        this.distance = distance;
        this.image = image;
        this.props = props;
        this.rating = rating;
        this.type = type;
        this.open_hours = open_hours;
        this.menu = menu;
        this.delivery = delivery;
        this.collection = collection;
        this.order_options = order_options;
    }
}

export class RestaurantCollection {
    enabled: boolean;
    status: string;
    interval: string;
    interval_max_orders: number;
    collection_slots: Array<RestaurantCollectionSlot>;


    constructor(enabled: boolean, status: string, interval: string, interval_max_orders: number, collection_slots: Array<RestaurantCollectionSlot>) {
        this.enabled = enabled;
        this.status = status;
        this.interval = interval;
        this.interval_max_orders = interval_max_orders;
        this.collection_slots = collection_slots;
    }
}

export class RestaurantContact {
    email: string;
    phone: string;
    website: string;
    instagram: string;
    facebook: string;

    constructor(
        email: string, phone: string, website: string, instagram: string,
        facebook: string) {
        this.email = email;
        this.phone = phone;
        this.website = website;
        this.instagram = instagram;
        this.facebook = facebook;
    }
}

export class RestaurantAddress {
    id: string;
    streetAddress: string;
    postcode: string;
    town: string;
    point: {
        latitude: number,
        longitude: number,
    };
    country: string;

    constructor(id: string, streetAddress: string, postcode: string, town: string, point: any, country: string) {
        this.id = id;
        this.streetAddress = streetAddress;
        this.postcode = postcode;
        this.town = town;
        this.point = point;
        this.country = country;
    }
}


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
// CATEGORIES
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
export class Halalness {
    name: string;
    title: string;
    imageUrl: string;

    constructor(name: string, title: string, imageUrl: string) {
        this.name = name;
        this.title = title;
        this.imageUrl = imageUrl;
    }
}

export class Cuisine {
    name: string;
    title: string;

    constructor(name: string, title: string) {
        this.name = name;
        this.title = title;
    }
}

export class Amenity {
    name: string;
    title: string;
    featured: boolean;

    constructor(name: string, title: string, featured: boolean) {
        this.name = name;
        this.title = title;
        this.featured = featured;
    }
}

//////////////////////////////////////////////////////////////////////////////////////////////////////////////
// DELIVERY COLLECTION OPEN HOURS
//////////////////////////////////////////////////////////////////////////////////////////////////////////////

export class RestaurantCollectionSlot {
    status: string;
    day: number;
    start: string;
    end: string;


    constructor(status: string, day: number, start: string, end: string) {
        this.status = status;
        this.day = day;
        this.start = start;
        this.end = end;
    }
}

export class RestaurantDeliveryHour {
    status: string;
    day: number;
    delivery_start: string;
    delivery_end: string;


    constructor(status: string, day: number, delivery_start: string, delivery_end: string) {
        this.status = status;
        this.day = day;
        this.delivery_start = delivery_start;
        this.delivery_end = delivery_end;
    }
}

export class RestaurantDelivery {
    enabled: boolean;
    status: string;
    max_distance: number;
    min_delivery_charge: number;
    min_delivery_charge_miles: number;
    add_delivery_per_mile: number;
    min_order_total: number;
    postcode_exceptions: string;
    delivery_hours: Array<RestaurantDeliveryHour>


    constructor(enabled: boolean, status: string, max_distance: number, min_delivery_charge: number, min_delivery_charge_miles: number, add_delivery_per_mile: number, min_order_total: number, postcode_exceptions: string, delivery_hours: Array<RestaurantDeliveryHour>) {
        this.enabled = enabled;
        this.status = status;
        this.max_distance = max_distance;
        this.min_delivery_charge = min_delivery_charge;
        this.min_delivery_charge_miles = min_delivery_charge_miles;
        this.add_delivery_per_mile = add_delivery_per_mile;
        this.min_order_total = min_order_total;
        this.postcode_exceptions = postcode_exceptions;
        this.delivery_hours = delivery_hours;
    }
}

export class OpenHour {
    restaurant_id: string;
    day: string;
    open: string;
    close: string;
    status: string;
    updated_at: Date;
    created_at: Date;


    constructor(restaurant_id: string, day: string, open: string, close: string, status: string, updated_at: Date, created_at: Date) {
        this.restaurant_id = restaurant_id;
        this.day = day;
        this.open = open;
        this.close = close;
        this.status = status;
        this.updated_at = updated_at;
        this.created_at = created_at;
    }
}

export class OrderSettings {
    id: string;
    restaurant_id: string;
    pre_order_enabled: boolean;
    pre_order_days_limit: number;
    updated_at: Date;
    created_at: Date;

    constructor(
        id: string, restaurant_id: string, pre_order_enabled: boolean,
        pre_order_days_limit: number, updated_at: Date, created_at: Date) {
        this.id = id;
        this.restaurant_id = restaurant_id;
        this.pre_order_enabled = pre_order_enabled;
        this.pre_order_days_limit = pre_order_days_limit;
        this.updated_at = updated_at;
        this.created_at = created_at;
    }
}

//////////////////////////////////////////////////////////////////////////////////////////////////////////////
// MENU
//////////////////////////////////////////////////////////////////////////////////////////////////////////////

export class MenuItemGroup {
    id: number;
    restaurant_id: string;
    group_name: string;
    description: string;
    view_order_priority: number;
    items: Array<MenuItem>;
    updated_at: Date;
    created_at: Date;

    constructor(id: number, restaurant_id: string, group_name: string, description: string, view_order_priority: number, items: Array<MenuItem>, updated_at: Date, created_at: Date) {
        this.id = id;
        this.restaurant_id = restaurant_id;
        this.group_name = group_name;
        this.description = description;
        this.view_order_priority = view_order_priority;
        this.items = items;
        this.updated_at = updated_at;
        this.created_at = created_at;
    }
}

export class MenuItemGroupUpdate {
    group_name: string;
    description: string;
    view_order_priority: number;


    constructor(group_name: string, description: string, view_order_priority: number) {
        this.group_name = group_name;
        this.description = description;
        this.view_order_priority = view_order_priority;
    }
}

export class MenuItem {
    id: number;
    restaurant_id: string;
    description: string;
    view_order_priority: number;
    item_name: string;
    item_short_name: string;
    dietary_comments: string;
    price: number;
    discounted_price: number;
    available: boolean;
    item_group_id: string;
    variations: Array<MenuItemVariation>;
    updated_at: Date;
    created_at: Date;


    constructor(id: number, restaurant_id: string, description: string, view_order_priority: number, item_name: string, item_short_name: string, dietary_comments: string, price: number, discounted_price: number, available: boolean, item_group_id: string, variations: Array<MenuItemVariation>, updated_at: Date, created_at: Date) {
        this.id = id;
        this.restaurant_id = restaurant_id;
        this.description = description;
        this.view_order_priority = view_order_priority;
        this.item_name = item_name;
        this.item_short_name = item_short_name;
        this.dietary_comments = dietary_comments;
        this.price = price;
        this.discounted_price = discounted_price;
        this.available = available;
        this.item_group_id = item_group_id;
        this.variations = variations;
        this.updated_at = updated_at;
        this.created_at = created_at;
    }
}

export class MenuItemVariation {
    id: number;
    item_id: string;
    variation_name: string;
    description: string;
    option_min: number;
    option_max: number;
    updated_at: Date;
    created_at: Date;
    options: Array<MenuItemVariationOption>;

    constructor(id: number, item_id: string, variation_name: string, description: string, option_min: number, option_max: number, updated_at: Date, created_at: Date, options: Array<MenuItemVariationOption>) {
        this.id = id;
        this.item_id = item_id;
        this.variation_name = variation_name;
        this.description = description;
        this.option_min = option_min || 1;
        this.option_max = option_max;
        this.updated_at = updated_at;
        this.created_at = created_at;
        this.options = options || [new MenuItemVariationOption()];
    }
}


export class MenuItemVariationOption {
    id: number;
    variation_id: string;
    option_name: string;
    price_change: number;
    updated_at: Date;
    created_at: Date;

    constructor(id: number, variation_id: string, option_name: string, price_change: number, updated_at: Date, created_at: Date) {
        this.id = id;
        this.variation_id = variation_id;
        this.option_name = option_name;
        this.price_change = price_change || 0;
        this.updated_at = updated_at;
        this.created_at = created_at;
    }
}


export class OrderItem {

    item: MenuItem;
    quantity: number;
    comments: string;


    constructor(item: MenuItem, quantity: number, comments: string) {
        this.item = item;
        this.quantity = quantity;
        this.comments = comments;
    }
}


export class Order {
    id: number;
    reference: string;
    status: string;
    order_total: number;
    subtotal: number;
    service_charge: number;
    comments: string;
    eta:Date;
    order_date:Date;
    order_type: string;
    delivery_address: string;
    updated_at: Date;
    created_at : Date
    items: Array<OrderItem>;
    user: User;
    restaurant: Restaurant;


    constructor(id: number, reference: string, status: string, order_total: number, subtotal: number, service_charge: number, comments: string, eta: Date, order_date: Date, order_type: string, delivery_address: string, updated_at: Date, created_at: Date, items: Array<OrderItem>, user: UserAccount, restaurant: Restaurant) {
        this.id = id;
        this.reference = reference;
        this.status = status;
        this.order_total = order_total;
        this.subtotal = subtotal;
        this.service_charge = service_charge;
        this.comments = comments;
        this.eta = eta;
        this.order_date = order_date;
        this.order_type = order_type;
        this.delivery_address = delivery_address;
        this.updated_at = updated_at;
        this.created_at = created_at;
        this.items = items;
        this.user = user;
        this.restaurant = restaurant;
    }
}



