import {Order, OrderItem} from "../models/models";
import {
  Flex, HStack, Stack, Text, Heading, Tag, Button, IconButton, Divider, Icon,
  SkeletonText, Skeleton, DrawerOverlay, DrawerContent, DrawerHeader, DrawerCloseButton, Drawer, DrawerBody,
  DrawerFooter,
} from '@chakra-ui/react'
import dayjs from "dayjs";
import relativeTime from 'dayjs/plugin/relativeTime';
import React, {useContext, useState} from "react";
import {AiOutlineClockCircle} from "react-icons/all";
import FormatterUtil from "../utils/FormatterUtil";
import {ResponsiveContext} from "../providers/ResponsiveProvider";
import {NotificationContext} from "../providers/NotificationProvider";
import {ApiContext} from "../providers/ApiProvider";
import Loader from "./loading/Loader";


dayjs.extend(relativeTime);

interface OrderCardProps {
  order: Order;
  onClick: (Order) => void;
}

export function OrderCard(props : OrderCardProps) {
  const {order} = {...props};
  const {isMobile} = useContext(ResponsiveContext);

  return (
      <Stack bg={"white"} padding={"10px 5px"} marginBottom={"2"} width={isMobile ? "100%" : "300px"}
             {...props}
      >
        <Flex justifyContent={"space-between"}>
          <Text fontWeight={"bold"} fontSize={"1.3em"} color={"black"} textTransform={"uppercase"}>{order?.reference}</Text>
          <OrderTag status={order?.status ? order?.status : 'UNKNOWN STATUS'}/>
        </Flex>
        <Flex justifyContent={"space-between"} align={"flex-end"}>
          <Stack spacing={1}>
            <Text color={"gray.600"} fontWeight={"400"}>{order?.user?.username}</Text>
            <Text color={"gray.600"} fontWeight={"400"}>{order?.order_type ? order?.order_type?.toUpperCase() : 'UNKNOWN TYPE'}</Text>
          </Stack>
          <HStack align={"center"}>
            <Icon as={AiOutlineClockCircle}/>
            <Text>{dayjs(order?.created_at).fromNow()}</Text>
          </HStack>
        </Flex>
        <Divider/>
      </Stack>
  )
}

export function OrderCardLoading() {

  return (
      <Stack bg={"white"} padding={"10px 5px"} marginBottom={"2"} width={"300px"}>
        <Flex justifyContent={"space-between"}>
          <Skeleton height={"30px"} width={"50%"}/>
          <Skeleton height={"30px"} width={"30%"}/>
        </Flex>
        <Stack spacing={1}>
          <Skeleton height={"20px"} width={"60%"}/>
        </Stack>
        <HStack align={"center"}>
          <Skeleton height={"20px"} width={"60%"}/>
        </HStack>
        <Divider/>
      </Stack>
  )
}

interface OrderDetailsCardProps {
  order: Order,
  isLoading: boolean,
  onAccept: (Order) => void,
  onReject: (Order) => void,
  onCancel: (Order) => void,
  onReady: (Order) => void,
  onClose: () => void,
}

export function OrderDetailsCard(props : OrderDetailsCardProps) {

  // Props
  const {order, onClose} = {...props};

  const {apiService} = useContext(ApiContext);
  const {isMobile} = useContext(ResponsiveContext);
  const {success, error} = useContext(NotificationContext);

  const [isLoading, setIsLoading] = useState<boolean>(false)


  function acceptOrder() {
    setIsLoading(true)
    apiService.ordersAccept(order?.id)
        .then(resp => {
          success(null, resp.message)
          onClose()
        })
        .catch(e => error("", e.message))
        .finally(() => setIsLoading(false))
  }

  function rejectOrder() {
    setIsLoading(true)
    apiService.ordersReject(order?.id)
        .then(resp => {
          success(null, resp.message)
          onClose()
        })
        .catch(e => error("", e.message))
        .finally(() => setIsLoading(false))

  }

  function cancelOrder() {
    setIsLoading(true)
    apiService.ordersCancel(order?.id)
        .then(resp => {
          success(null, resp.message)
          onClose()
        })
        .catch(e => error("", e.message))
        .finally(() => setIsLoading(false))
  }

  function readyOrder() {
    setIsLoading(true)
    apiService.ordersReady(order?.id)
        .then(resp => {
          success(null, resp.message)
          onClose()
        })
        .catch(e => error("", e.message))
        .finally(() => setIsLoading(false))
  }

  function completeOrder() {
    setIsLoading(true)
    apiService.ordersComplete(order?.id)
        .then(resp => {
          success(null, resp.message)
          onClose()
        })
        .catch(e => error("", e.message))
        .finally(() => setIsLoading(false))
  }

  function getCard() {
    return (
        <Stack spacing={4}>
          <Flex justifyContent={"space-between"}>
            <HStack spacing={2}>
              <Heading as={"h3"} fontWeight={"bold"} fontSize={"1.4em"}
                       color={"black"} textTransform={"uppercase"}>{order?.reference}</Heading>
              <OrderTag status={order?.status}/>
            </HStack>
          </Flex>
          <Divider/>
          <Flex justifyContent={"space-between"} align={"flex-end"}>
            <Stack spacing={1}>
              <Text color={"gray.600"} fontWeight={"400"}>{order?.user?.username}</Text>
              <Text color={"gray.600"} fontWeight={"400"}>{order?.order_type?.toUpperCase()}</Text>
            </Stack>
            <HStack align={"center"}>
              <Icon as={AiOutlineClockCircle}/>
              <Text>{dayjs(order?.created_at).fromNow()}</Text>
            </HStack>
          </Flex>
          <Divider/>
          <Stack>
            {order?.items?.map(item => <OrderItemCard orderItem={item}/>)}
          </Stack>
          <Divider/>
          <Divider/>
          <Flex justify={"space-between"}>
            <Text size={"md"} fontWeight={"bold"}>Total</Text>
            <Text size={"md"} fontWeight={"bold"}>{FormatterUtil.formatCurrency(order?.order_total)}</Text>
          </Flex>
          <Divider/>
        </Stack>
    )
  }

  function getActionButtons() {
    const statusLower = order?.status?.toLowerCase();

    if (isLoading) {
      return (
          <HStack spacing={4} align={"center"} justify={"center"} width={"100%"}>
            <Loader isLoading={isLoading} />
          </HStack>
        )
    }

    if (statusLower === "preparing") {
      return (
          <HStack spacing={4} align={"center"} width={"100%"}>
            <Button isFullWidth colorScheme={"red"} onClick={cancelOrder}>Cancel Order</Button>
            <Button isFullWidth colorScheme={"green"} onClick={readyOrder}>Order is Ready</Button>
          </HStack>
      )
    } else if (statusLower === "completed" || statusLower === "ready to collect" || statusLower === "ready to deliver") {
      return (
          <HStack spacing={4} align={"center"} width={"100%"}>
            <Button isFullWidth colorScheme={"green"} onClick={completeOrder}>Complete Order</Button>
            {/*<Button isFullWidth colorScheme={"blue"} onClick={() => null}>View Order</Button>*/}
          </HStack>
      )
    } else {
      return (
          <HStack spacing={4} align={"center"} width={"100%"}>
            <Button isFullWidth colorScheme={"red"} onClick={rejectOrder}>Reject Order</Button>
            <Button isFullWidth colorScheme={"green"} onClick={acceptOrder}>Accept Order</Button>
          </HStack>
      )
    }
  }

  if (isMobile) {
    return (
        <Drawer
            isOpen={order != null}
            onClose={() => onClose()}
            placement={"bottom"}
        >
          <DrawerOverlay>
            <DrawerContent style={{height: '100%'}}>
              <DrawerHeader>Order Details</DrawerHeader>
              <DrawerCloseButton/>
              <DrawerBody align={"center"}>
                {getCard()}
              </DrawerBody>
              <DrawerFooter>
                {getActionButtons()}
              </DrawerFooter>
            </DrawerContent>
          </DrawerOverlay>
        </Drawer>
    )
  } else {
    if (order == null) {
      return <></>
    } else {
      return (
          <Stack bg={"#fcfcfc"} padding={"20px 15px"} marginBottom={"2"}
                 spacing={10}
                 borderColor={"gray.100"}
                 borderLeftWidth={"5px"}
                 borderLeftStyle={"solid"}
                 flex={1}>
            <Heading size={"lg"}>Order Details</Heading>
            {getCard()}
            {getActionButtons()}
          </Stack>
      )
    }
  }
}

export function OrderDetailsCardLoading() {

  function OrderItemLoading() {
    return (
        <Flex justify={"space-between"}>
          <Skeleton height={"20px"} width={"70%"}/>
          <Skeleton height={"20px"} width={"10%"}/>
        </Flex>
    )
  }

  return (
      <Stack bg={"white"} padding={"10px 5px"} marginBottom={"2"} borderLeft={"solid 5px rgba(0,0,0,0.1)"} flex={1}>
        <Flex justifyContent={"space-between"}>
          <Skeleton height={"30px"} width={"50%"}/>
          <Skeleton height={"30px"} width={"30%"}/>
        </Flex>
        <Divider/>
        <Stack spacing={2}>
          <OrderItemLoading/>
          <OrderItemLoading/>
          <OrderItemLoading/>
          <OrderItemLoading/>
        </Stack>
        <Divider/>
        <Divider/>
        <Stack spacing={2}>
          <OrderItemLoading/>
          <OrderItemLoading/>
        </Stack>
      </Stack>
  )
}

function OrderItemCard(props : {orderItem: OrderItem}) {

  const {orderItem} = {...props};

  return (
      <Stack>
        <Flex direction={"row"} justify={"space-between"}
              padding={'10px 10px'}>
          <HStack spacing={6}>
            <Text width={"30px"}>{orderItem?.quantity}x</Text>
            <Stack>
              <Text
                  size={"md"}>{orderItem?.item?.item_name}</Text>
            </Stack>
          </HStack>
          <Text>{FormatterUtil.formatCurrency(orderItem?.item?.price * orderItem?.quantity)}</Text>
        </Flex>
        {/*<Divider/>*/}
      </Stack>
  )
}

function OrderTag(props: {status: string}) {
  const {status} = {...props};
  let color = null;

  if (status == null) {
    return null;
  }
  const statusLower = status.toLowerCase();

  if (statusLower === "requested") {
    color = "blue"
  } else if (statusLower === "restaurant accepted" || statusLower === "preparing") {
    color = "orange"
  } else if (statusLower === "restaurant rejected" ||
      statusLower === "restaurant cancelled" ||
      statusLower === "user cancelled") {
    color = "red"
  }  else if (statusLower === "ready to collect" || statusLower === "ready to deliver" || statusLower === "complete") {
    color = "green"
  } else {
    color = "gray"
  }

  return <Tag colorScheme={color}>{status}</Tag>
}
