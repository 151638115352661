import {DashboardBasePanel} from "./DashboardBase";
import {
  FormControl,
  FormHelperText,
  FormLabel,
  HStack,
  Input, Select,
  Stack,
  Tag,
  TagCloseButton,
  TagLabel, Button
} from "@chakra-ui/react";
import remove from "lodash-es/remove";
import React, {useContext, useEffect, useState} from "react";
import {Restaurant} from "../../../models/models";
import Logger from "../../../utils/Logger";
import {ApiContext} from "../../../providers/ApiProvider";
import {NotificationContext} from "../../../providers/NotificationProvider";
import {AuthContext} from "../../../providers/AuthProvider";

interface StoreCategoriesUpdateDashboardPanelProps {
  restaurant: Restaurant | null | undefined,
}

function StoreCategoriesUpdatePanel(props: StoreCategoriesUpdateDashboardPanelProps) {
  const logger = new Logger(StoreCategoriesUpdatePanel.name)

  // Props
  const {restaurant} = {...props}

  // App State
  const {isAuthenticated} = useContext(AuthContext);
  const {apiService} = useContext(ApiContext);
  const {success, error} = useContext(NotificationContext);

  // Component State
  const [isLoading, setIsLoading] = useState(false)
  const [cuisines, setCuisines] = useState([])
  const [amenities, setAmenities] = useState([])
  const [halalness, setHalalness] = useState(null)
  const [cuisineCategories, setCuisineCategories] = useState([]);
  const [amenityCategories, setAmenityCategories] = useState([]);

  useEffect(() => {
    if (restaurant) {
      setCuisines(restaurant?.cuisines)
      setAmenities(restaurant?.amenities)
      setHalalness(restaurant?.halalness)
    }
  }, [restaurant])

  useEffect(() => {
    if (isAuthenticated) {
      setIsLoading(true)
      apiService.restaurantGetOnBoardInputData()
          .then(data => {
            // setRestaurantTypes(data.types);
            // setHalalCategories(data.halalCategories);
            setCuisineCategories(data?.cuisineCategories);
            setAmenityCategories(data?.amenityCategories);
          })
          .catch(e => error("Failed to load store data", e.message))
          .finally(() => setIsLoading(false))
    }
  }, [isAuthenticated])

  function updateRestaurant(data) {
    setIsLoading(true)
    apiService.restaurantUpdate(restaurant.id, data)
        .then(resp => {
          success("Updated restaurant success", resp.message)
          // setRestaurant(data.result)
        })
        .catch(e => error("Failed to update store", e.message))
        .finally(() => setIsLoading(false))
  }

  return (
      <DashboardBasePanel title={"Store Cuisines"} isLoading={restaurant==null}>
        <Stack spacing={4}>
          <FormControl>
            <FormLabel>Halal Category</FormLabel>
            <Input name="halalness" value={restaurant?.halalness?.title} disabled/>
            <FormHelperText>To change your Halal category please get in touch with us.</FormHelperText>
          </FormControl>


          {/* Cuisines */}

          <FormControl>
            <FormLabel>Cuisines</FormLabel>
            <Stack spacing={2}>
              <HStack spacing={2} wrap={"wrap"}>
                {cuisines?.sort((c1, c2) => c1?.name?.localeCompare(c2?.name)).map(c =>
                    <Tag>
                      <TagLabel>{c.title}</TagLabel>
                      <TagCloseButton onClick={() => setCuisines(prevCuisines => {
                        remove(prevCuisines, el => el?.title === c.title);
                        return [...prevCuisines]
                      })}/>
                    </Tag>
                )}
              </HStack>
              <Select
                  onChange={(event) => setCuisines(prevCuisines => [...prevCuisines, JSON.parse(event.target.value)])}
                  value={""}
              >
                <option value={""}>Please Select</option>
                {cuisineCategories.sort((c1, c2) => c1?.name?.localeCompare(c2.name)).map(h => <option value={JSON.stringify(h)}>{h.title}</option>)}
              </Select>
              <Button
                  size={"sm"}
                  isLoading={isLoading}
                  onClick={() => updateRestaurant({cuisines: cuisines?.map(c => c.name)})}
              >
                Save Changes
              </Button>
            </Stack>
            <FormHelperText>What cuisines does your business cover?</FormHelperText>
          </FormControl>


          {/* Amenities */}

          <FormControl>
            <FormLabel>Amenity</FormLabel>
            <Stack spacing={2}>
              <HStack spacing={2} wrap={"wrap"}>
                {amenities?.sort((c1, c2) => c1?.name?.localeCompare(c2.name)).map(c =>
                    <Tag>
                      <TagLabel>{c.title}</TagLabel>
                      <TagCloseButton onClick={() => setAmenities(prevAmenities => {
                        remove(prevAmenities, el => el?.title === c.title);
                        return [...prevAmenities];
                      })}/>
                    </Tag>
                )}
              </HStack>
              <Select
                  onChange={(event) => setAmenities(prevAmenities => [...prevAmenities, JSON.parse(event.target.value)])}
                  value={""}
              >
                <option value={""}>Please Select</option>
                {amenityCategories.sort((c1, c2) => c1?.name?.localeCompare(c2.name)).map(h => <option value={JSON.stringify(h)}>{h.title}</option>)}
              </Select>
              <Button
                  size={"sm"}
                  isLoading={isLoading}
                  onClick={() => updateRestaurant({amenities: amenities?.map(c => c.name)})}
              >
                Save Changes
              </Button>
            </Stack>
            <FormHelperText>What amenity does your business cover?</FormHelperText>
          </FormControl>
        </Stack>
      </DashboardBasePanel>

  )
}

export default StoreCategoriesUpdatePanel;
