import {Restaurant} from "../../models/models";
import React, {useContext, useEffect, useState} from "react";
import {DashboardBase, DashboardBasePanel} from "./components/DashboardBase";
import { TabList, Tabs, TabPanels, TabPanel, Tab, Stack, Button } from "@chakra-ui/react";
import OpenHoursCollectionForm from "./components/OpenHoursCollectionForm";
import OpenHoursDeliveryForm from "./components/OpenHoursDeliveryForm";
import OpenHoursForm from "./components/OpenHoursForm";
import {AuthContext} from "../../providers/AuthProvider";
import {ApiContext} from "../../providers/ApiProvider";
import {NotificationContext} from "../../providers/NotificationProvider";
import Loader from "../../components/loading/Loader";
import OpenHoursOrderSettingsForm from "./components/OpenHoursOrderSettingsForm";

interface OpenHoursDashboardProps {
  restaurant: Restaurant
}
function OpenHoursDashboard(props : OpenHoursDashboardProps) {

  const {restaurant=null} = {...props};

  return (
      <DashboardBase title={"Open Hours"}>
        <Stack spacing={6}>
          <OpenHoursPanel
              restaurantId={restaurant?.id}
          />

          <OpenHoursCollectionForm restaurantId={restaurant.id}/>

          <OpenHoursOrderSettingsForm restaurantId={restaurant.id}/>
        </Stack>
      </DashboardBase>
  )

}

interface OpenHoursPanelProps {
  restaurantId: string | null
}
function OpenHoursPanel(props : OpenHoursPanelProps) {
  const DAYS = {
    0: "Sunday",
    1: "Monday",
    2: "Tuesday",
    3: "Wednesday",
    4: "Thursday",
    5: "Friday",
    6: "Saturday",
  }

  interface OpenHourType {
    day: number,
    open: string,
    close: string,
    status: string,
  }

  // Props
  const {restaurantId} = {...props}

  // App State
  const {isAuthenticated} = useContext(AuthContext)
  const {apiService} = useContext(ApiContext)
  const {success} = useContext(NotificationContext)

  // Component State
  const [openHours, setOpenHours] = useState<Array<OpenHourType>>([])
  const [openHoursUpdated, setOpenHoursUpdated] = useState<Array<OpenHourType>>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    if (isAuthenticated && restaurantId) {
      getOpenHours()
    }
  }, [isAuthenticated, restaurantId])

  function getOpenHours() {
    setIsLoading(true)
    apiService.openHoursGet(restaurantId)
        .then(d => {
          setOpenHours(d)
          setOpenHoursUpdated(d)
        })
        .catch(e => setError(e))
        .finally(() => setIsLoading(false))
  }


  function updateOpenHours() {
    setIsLoading(true)
    apiService.openHoursUpdate(restaurantId, openHoursUpdated)
        .then(resp => {
          setOpenHours(resp.result)
          setOpenHoursUpdated(resp.result)
          success("Update Successfully", resp.message)
        })
        .catch(e => setError(e))
        .finally(() => setIsLoading(false))
  }

  return (
      <DashboardBasePanel
          title={"Change your opening hours"}
          description={"Choose each day you’re open."}
          onAction={getOpenHours}
      >
        <Loader isLoading={isLoading} text={"Loading opening hours"}>
          <Stack>
            <Stack spacing={2}>
              {Object.entries(DAYS).map((day) => {
                const dayNumber = day[0]
                const dayLabel = day[1]
                const openHour = openHours[dayNumber]
                return (
                    <OpenHoursForm
                        key={dayNumber}
                        day={dayNumber}
                        dayLabel={dayLabel}
                        open={openHour?.open}
                        close={openHour?.close}
                        status={openHour?.status}
                        openLabel={"Open"}
                        closeLabel={"Close"}
                        closedLabel={"Close for the day"}
                        onChange={({day, open, close,status}) => {
                          setOpenHoursUpdated(prevState => {
                            prevState[day] = {
                              day: day, open: open, close: close, status:status
                            }
                            return [...prevState]
                          })
                        }}
                    />
                )
              })}
            </Stack>
            <Button onClick={updateOpenHours} isFullWidth>Save Changes</Button>
          </Stack>
        </Loader>
      </DashboardBasePanel>
  )
}

export default OpenHoursDashboard;
