import React, {useContext, useEffect, useState} from "react";
import Loader from "../../../components/loading/Loader";
import {ApiContext} from "../../../providers/ApiProvider";
import {
  Button,
  Flex,
  FormControl,
  FormHelperText,
  Input,
  FormLabel,
  Select,
  Switch,
  HStack,
  Stack,
  InputGroup,
  InputRightAddon,
  FormErrorMessage,
} from "@chakra-ui/react";
import OpenHoursForm from "./OpenHoursForm";
import {NotificationContext} from "../../../providers/NotificationProvider";
import {useFormik} from "formik";
import Logger from "../../../utils/Logger";
import {isEmpty} from "lodash-es";
import {NumberInputWithIncrements} from "../../../components/inputs";
import {DashboardBasePanel} from "./DashboardBase";


const DAYS = {
  0: "Sunday",
  1: "Monday",
  2: "Tuesday",
  3: "Wednesday",
  4: "Thursday",
  5: "Friday",
  6: "Saturday",
}

interface OpenHoursDashboardProps {
  restaurantId: string
}

function OpenHoursCollectionForm(props: OpenHoursDashboardProps) {
  const logger = new Logger(OpenHoursCollectionForm.name)

  const {apiService} = useContext(ApiContext);
  const {success, error} = useContext(NotificationContext);
  const {restaurantId} = {...props};

  const [collection, setCollection] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [collectionEnableMaxBookings, setCollectionEnableMaxBookings] = useState(false);

  const formik = useFormik({
    initialValues: {
      enabled: false,
      // interval: null,
      // collectionEnableMaxBookings: false,
      // interval_max_orders: 5,
      openHours: [],
    },
    validate: validate,
    onSubmit: updateCollection
  });

  useEffect(() => {
    if (restaurantId) {
      fetchCollection()
    }
  }, [restaurantId])

  useEffect(() => {
    if (!isEmpty(collection)) {
      formik.setValues({...collection})
      setCollectionEnableMaxBookings(collection.interval_max_orders != null)
    }
  }, [collection])

  useEffect(() => {
    if (!collectionEnableMaxBookings) {
      formik.setFieldValue("interval_max_orders", null)
    }
  }, [collectionEnableMaxBookings])

  function fetchCollection() {
    setIsLoading(true)
    apiService.collectionGet(restaurantId)
        .then((data) => {
          setCollection(data)
        })
        .catch(e => error("Fetch collection details failed", e.message))
        .finally(() => setIsLoading(false))
  }

  function validate(values) {
    const errors = {};
    logger.info("Validating form")
    if (values.enabled) {
      if (isEmpty(values.interval)) {
        errors.interval = "Collection interval must be set if collections are enabled"
      }
    }
    if (values.interval_max_orders < 0) {
      errors.interval_max_orders = "Interval max orders cannot be less that zero"
    }
    return errors;
  }

  function updateCollection() {
    apiService.collectionUpdate(restaurantId, {...formik.values})
        .then((data) => {
          success("Update collection successful", data.message)
          const result = data.result
          setCollection(result.collection)
          return;
        })
        .catch(e => error("Update collection failed", e.message));
  }

  const slotIntervalData = [
    {value: "HOURLY", label: "Every hour"},
    {value: "HALF_HOUR", label: "Every 30 minutes"},
  ]

  return (
      <DashboardBasePanel
          title={"Collection settings"}
          description={"Change you're collection settings"}
          isLoading={isLoading}
          onAction={fetchCollection}
      >
        <Stack spacing={10}>
          <FormControl id="collectionEnabled">
            <HStack align={"center"}>
              <FormLabel>Enable Collection?</FormLabel>
              <Switch isChecked={formik.values.enabled}
                      id={"enabled"} name={"enabled"}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
              />
            </HStack>
            <FormHelperText>This will allow your customers to collect from you address.</FormHelperText>
          </FormControl>

          {/*<FormControl id="collectionSlotInterval" isInvalid={formik.errors.interval && formik.touched.interval}>*/}
          {/*  <FormLabel>Collection Slot Interval</FormLabel>*/}
          {/*  <Select*/}
          {/*      defaultValue={formik.values.interval}*/}
          {/*      // value={collection.interval || slotIntervalData[0].label}*/}
          {/*      // onChange={(event) => setCollection(c => ({...c, interval: event.target.value}))}*/}
          {/*      id={"interval"} name={"interval"}*/}
          {/*      onChange={formik.handleChange}*/}
          {/*      onBlur={formik.handleBlur}*/}

          {/*  >*/}
          {/*    {slotIntervalData.map(s => <option value={s.value}>{s.label}</option>)}*/}
          {/*  </Select>*/}
          {/*  <FormErrorMessage>{formik.errors.interval}</FormErrorMessage>*/}
          {/*  <FormHelperText>Your customers will have to book collections at regular intervals. E.g. every hour, every*/}
          {/*    half hour etc. Select 'Any' to allow customers to request*/}
          {/*    any collection time.</FormHelperText>*/}
          {/*</FormControl>*/}

          {/*<FormControl id="enableCollectionMaxBookings"  isInvalid={formik.errors.interval_max_orders && formik.touched.interval_max_orders}>*/}
          {/*  <HStack align={"center"}>*/}
          {/*    <FormLabel>Enable Collection Slot Booking Limit?</FormLabel>*/}
          {/*    <Switch isChecked={collectionEnableMaxBookings}*/}
          {/*            onChange={(event) => setCollectionEnableMaxBookings(event.target.checked)}/>*/}
          {/*  </HStack>*/}
          {/*  {collectionEnableMaxBookings &&*/}
          {/*    <NumberInputWithIncrements*/}
          {/*        min={0}*/}
          {/*        defaultValue={3}*/}
          {/*        rightAddon={"maximum orders per slot"}*/}
          {/*        onChange={(value) => formik.setFieldValue("interval_max_orders", value)}*/}
          {/*    />*/}
          {/*  }*/}
          {/*  <FormErrorMessage>{formik.errors.interval_max_orders}</FormErrorMessage>*/}
          {/*  <FormHelperText>Switch this on to limit the number of orders for each time slot.</FormHelperText>*/}
          {/*</FormControl>*/}

          {/*<Stack spacing={4}>*/}
          {/*    {Object.entries(DAYS).map((day) => {*/}
          {/*      const dayNumber = day[0]*/}
          {/*      const dayLabel = day[1]*/}
          {/*      const collection_slot = collection?.slots?.find(oh => oh.day == dayNumber);*/}
          {/*      return (*/}
          {/*          <OpenHoursForm*/}
          {/*              key={dayNumber}*/}
          {/*              day={dayNumber}*/}
          {/*              dayLabel={dayLabel}*/}
          {/*              open={collection_slot?.start}*/}
          {/*              close={collection_slot?.end}*/}
          {/*              openLabel={"Collection Start"}*/}
          {/*              closeLabel={"Collection End"}*/}
          {/*              closedLabel={"No collections on this day"}*/}
          {/*              onChange={({day, open, close}) => {*/}
          {/*                const slots_set = [...formik.values.slots]*/}
          {/*                const slotToAdd = {*/}
          {/*                  day: day,*/}
          {/*                  start: open,*/}
          {/*                  end: close,*/}
          {/*                }*/}
          {/*                const slotIndex = slots_set.findIndex(s => s.day == day)*/}
          {/*                if (slotIndex > -1) {*/}
          {/*                  slots_set[slotIndex] = slotToAdd*/}
          {/*                } else {*/}
          {/*                  slots_set.push(slotToAdd)*/}
          {/*                }*/}
          {/*                formik.setFieldValue("slots", [...slots_set])*/}
          {/*              }}*/}
          {/*          />*/}
          {/*      )*/}
          {/*    })*/}
          {/*      // collection.sort((oh1, oh2) => oh1.day - oh2.day).map(oh => <OpenHourCard key={oh.day} openHour={oh} onChange={(hours) => updateOpenHours(hours)}/>)*/}
          {/*    }*/}
          {/*  </Stack>*/}
          <Button onClick={updateCollection} isFullWidth>Save Changes</Button>
        </Stack>
      </DashboardBasePanel>
  )

}

export default OpenHoursCollectionForm;
