// Colors

module.exports = {
  colors: {
    WHITE: "#FFFFFF",
    PRIMARY: "#E74C3C",
    SECONDARY: "#243747",
    SECONDARY_ALT: "#456990",
    ACCENT_HIGH: "#A5F8D3",
    ACCENT_MID: "#FFE75D",
    ACCENT_LOW: "#FF8591",
    TERTIARY: "#FFFFFF",
    DISABLED: "#c4c4c4",
    DISABLED_TEXT: "#243747",

    SUCCESS: "#11ae4a",
    DANGER: "#ff4242",
    INFO: "#4198be",
    WARNING: "#eb8615",

    BORDER_DEFAULT: 'rgba(0,0,0,0.08)',

    BACKGROUND_PAGE_DEFAULT: "#f9fafa",
    BACKGROUND_DEFAULT: "#ffffff",

    BUTTON_LINK_TEXT_DARK: '#77797B',
    BUTTON_LINK_TEXT_LIGHT: '#FFFFFF',

    INPUT_TEXT: '#5a5a5a',
    INPUT_HELP_TEXT: '#818181',
    INPUT_BACKGROUND: '#ffffff',
    INPUT_BORDER: '#AEB0B2',

    TEXT_DEFAULT: '#1c1c1c',
  },


  COLOR_TEXT_DEFAULT: '#1c1c1c',
  COLOR_TEXT_INPUT_DEFAULT: '#5a5a5a',
  COLOR_TEXT_INPUT_BACKGROUND_DEFAULT: '#eeeeee',
  COLOR_TEXT_INPUT_BACKGROUND_DISABLED: '#c1c1c1',
  COLOR_TEXT_MUTED: 'rgb(117, 117, 117)',


// Dimensions
  NAVBAR_HEIGHT: "44px",
  TITLE: "44px",

// Fonts
  FONT_FAMILY: '"Signika", -apple-system, system-ui, sans-serif !important,',
  FONT_LIGHT: 300,
// $options-height: 50px,
// $full-screen-height: 100%,`
// $full-app-height: calc(#{$full-screen-height} - #{$navbar-height}),
// $main-height: calc(#{$full-app-height} - #{$options-height}),

// Responsive
  WIDTH_DEVICES: {
    mobileS: '320px',
    mobileM: '375px',
    mobileL: '425px',
    tablet: '768px',
    laptop: '1024px',
    laptopL: '1440px',
    desktop: '2560px'
  },

  zIndexes: {
    DOCK_NAV_LAYER: 200,
    POP_UP_FOR_SCREEN_LAYER: 300,
    POP_UP_LAYER: 400,
    NOTIFICATION_LAYER: 1000,
  },

}
