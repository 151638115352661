import isEmpty from "lodash-es/isEmpty";
import RestaurantAddress from "../models/RestaurantAddress";

export default class AddressUtil {
  static formatAddress(address : RestaurantAddress) {
    if (address == null) {
      return null;
    }
    const streetAddress = address.streetAddress;
    const town = address.town;
    const postcode = address.postcode;
    const country = address.country;

    let formattedAddress = "";

    if (streetAddress != null && streetAddress.trim() !== "") {
      formattedAddress += stripTrailingComma(streetAddress.trim()) + ", ";
    }

    if (town != null && town.trim() !== "") {
      formattedAddress += stripTrailingComma(town.trim()) + ", ";
    }

    if (postcode != null && postcode.trim() !== "") {
      formattedAddress += stripTrailingComma(postcode.trim()) + ", ";
    }

    if (country != null && country.trim() !== "") {
      formattedAddress += stripTrailingComma(country.trim()) + ", ";
    }

    if (formattedAddress.trim().endsWith(",")) {
      formattedAddress = stripTrailingComma(formattedAddress);
      formattedAddress = formattedAddress.substring(0, formattedAddress.lastIndexOf(","));
    }

    return formattedAddress;
  }
}

const stripTrailingComma = function (str) {
  if (str.endsWith(",")) {
    return str.substring(0, str.lastIndexOf(","));
  } else {
    return str;
  }
};