import {DashboardBasePanel} from "./DashboardBase";
import {
  Button,
  FormControl, FormHelperText, FormLabel, HStack, Input, InputGroup,
  Stack, Textarea, VStack,
} from "@chakra-ui/react";
import React, {useContext, useEffect, useState} from "react";
import {Restaurant} from "../../../models/models";
import Logger from "../../../utils/Logger";
import {ApiContext} from "../../../providers/ApiProvider";
import {NotificationContext} from "../../../providers/NotificationProvider";
import {AuthContext} from "../../../providers/AuthProvider";
import {InputWithSaveButton} from "../../../components/inputs";
import axios from "axios";

interface StoreDetailsUpdatePanelProps {
  restaurant: Restaurant | null | undefined,
}

function StoreDetailsUpdatePanel(props : StoreDetailsUpdatePanelProps) {
  const logger = new Logger(StoreDetailsUpdatePanel.name)

  // Props
  const {restaurant} = {...props}

  // App State
  const {isAuthenticated} = useContext(AuthContext);
  const {apiService} = useContext(ApiContext);
  const {success, error} = useContext(NotificationContext);

  // Component State
  const [isLoading, setIsLoading] = useState(false);
  const [restaurantTitle, setRestaurantTitle] = useState(restaurant?.title);
  const [restaurantDescription, setRestaurantDescription] = useState(restaurant?.description);
  const [restaurantImageFile, setRestaurantImageFile] = useState(null);
  const [restaurantImageForm, setRestaurantImageForm] = useState(null);
  const [isRestaurantImageUploadLoading, setIsRestaurantImageUploadLoading] = useState(null);
  const [s3UploadRequestData, setS3UploadRequestData] = useState(null);

  useEffect(() => {
    setRestaurantTitle(restaurant?.title)
    setRestaurantDescription(restaurant?.description)
  }, [restaurant])

  function updateRestaurant(data: any) {
    setIsLoading(true)
    apiService.restaurantUpdate(restaurant.id, data)
        .then(data => {
          success("Updated restaurant success", data.message)
          // setRestaurant(data.result)
        })
        .catch(e => error("Failed to update store", e.message))
        .finally(() => setIsLoading(false))
  }

  function onFileUpload(files) {
    setIsRestaurantImageUploadLoading(true)
    logger.debug("file uploaded", files)
    if (files != null && files.length > 0) {
      const uploadImageFile = files[0];
      apiService.restaurantGetUploadS3Link(restaurant?.id)
          .then(s3FormData => {
            setRestaurantImageForm(s3FormData)
            setRestaurantImageFile(uploadImageFile)
          })
          .catch(e => error("", e.message))
          .finally(() => setIsRestaurantImageUploadLoading(false))
    } else {
      setRestaurantImageFile(null)
    }
  }

  function uploadImageToS3() {
    setIsRestaurantImageUploadLoading(true)
    const formData = new FormData();
    for (const [key, value] of Object.entries(restaurantImageForm.fields)) {
      formData.append(key, value)
    }
    // Update the formData object
    formData.append("file", restaurantImageFile, restaurantImageForm.name)
    axios.post(restaurantImageForm.url, formData)
        .then(d => {
          success("Image uploaded successfully", "")
        })
        .catch(e => error("", e.message))
        .finally(() => setIsRestaurantImageUploadLoading(false))
  }



  return (
      <DashboardBasePanel title={"Store Details"} isLoading={restaurant == null}>
        <Stack spacing={4}>

          <FormControl>
            <FormLabel>Store Name</FormLabel>
            <HStack>
              <Input name="title" value={restaurantTitle} readOnly/>
            </HStack>
            <FormHelperText>To change your store name please get in touch with us.</FormHelperText>
          </FormControl>

          <FormControl>
            <FormLabel>Store Description</FormLabel>
            <Stack>
              <Textarea
                  name="description"
                  componentClass="textarea" rows={3}
                  placeholder="Describe your business in a few words"
                  value={restaurantDescription}
                  onChange={event => setRestaurantDescription(event.target.value)}
              />
              <Button
                  size={"sm"}
                  onClick={() => updateRestaurant({description: restaurantDescription})}
                  isDisabled={restaurantDescription === restaurant?.description}
                  isLoading={isLoading}
              >
                Save Changes
              </Button>
            </Stack>
          </FormControl>

          <FormControl>
            <FormLabel>Store Image</FormLabel>
            <HStack>
              <InputGroup>
                <Input
                    type={"file"}
                    onChange={(event) => onFileUpload(event?.target?.files)}
                />
              </InputGroup>
              <Button
                  isDisabled={!restaurantImageFile || !restaurantImageForm}
                  isLoading={isRestaurantImageUploadLoading}
                  onClick={uploadImageToS3}
              >
                Upload
              </Button>
            </HStack>
          </FormControl>

        </Stack>
      </DashboardBasePanel>
  )
}

export default StoreDetailsUpdatePanel;
