import React, {useContext, useState} from "react";
import {AuthContext} from "../../../providers/AuthProvider";
import routes from "../../../constants/routes";
import {RestaurantAccount} from "../../../models/models";
import {Input, Stack, Button, Heading, Flex, FormControl, FormLabel,
  Text, Link
} from '@chakra-ui/react'
import { useHistory, Link as RouterLink } from "react-router-dom";
import {NotificationContext} from "../../../providers/NotificationProvider";

interface Props {
  onComplete: (user: RestaurantAccount) => void,

}

function SignUpForm(props: Props) {

  const {signUp} = useContext(AuthContext);
  const {error} = useContext(NotificationContext);
  const history = useHistory()

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [loading, setLoading] = useState(false);

  function onSignUp() {
    setLoading(true);
    signUp(email, password, confirmPassword)
        .then((user: RestaurantAccount) => props.onComplete(user))
        .catch((e: any) => error("Sign up failed", e.message))
        .finally(() => setLoading(false));
  }

  return (
        <Flex direction={'column'} justify={'center'} align={'stretch'} width={"100%"}>
          <Stack spacing={6}>
          <Heading as={"h1"} size={"lg"} alignSelf={"center"}>Sign Up</Heading>
            <Stack spacing={2}>
              <Text>
                Here at Halal Munch, we want to help you digitalise your business and get online without costing
                you an arm and a leg. <br/>
                We can help you reach new customers, help you understand your customers through powerful insights, and
                help promote your brand throughout our platform.
              </Text>
            </Stack>
            <Stack spacing={4} justify={'stretch'} >
                  <FormControl>
                    <FormLabel>Email</FormLabel>
                    <Input  defaultValue={email} placeholder={"Email"} onChange={(event) => setEmail(event.target.value)}/>
                  </FormControl>

                  <FormControl>
                    <FormLabel>Password</FormLabel>
                    <Input type={"password"} defaultValue={password} placeholder={"Password"} onChange={(event) => setPassword(event.target.value)}/>
                  </FormControl>


                  <FormControl>
                    <FormLabel>Confirm Password</FormLabel>
                    <Input type={"password"} defaultValue={confirmPassword} placeholder={"Confirm Password"} onChange={(event) => setConfirmPassword(event.target.value)}/>
                  </FormControl>

                  {/*<TextInput placeholder={"Confirm Password"} />*/}

                </Stack>
            <Button isFullWidth isLoading={loading} colorScheme={"primary"} type={"submit"}  onClick={onSignUp}>Sign Up</Button>

            <Text>
              Already have an account? <Link as={RouterLink} color={"primary.500"} to={routes.AUTH_SIGN_IN}><u>Sign in to continue</u>.</Link>
            </Text>
          </Stack>
      </Flex>
  )
}

export default SignUpForm;
