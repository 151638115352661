import {DashboardBasePanel} from "./DashboardBase";
import {
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  StatArrow,
  StatGroup,
  Stack, Flex
} from "@chakra-ui/react";
import React, {useContext, useEffect, useState} from "react";
import {Order, Restaurant} from "../../../models/models";
import Logger from "../../../utils/Logger";
import {ApiContext} from "../../../providers/ApiProvider";
import {NotificationContext} from "../../../providers/NotificationProvider";
import FormatterUtil from "../../../utils/FormatterUtil";

interface SummaryCurrentOrdersPanelProps {
  restaurant: Restaurant | null | undefined,
}

function SummaryCurrentOrdersPanel(props : SummaryCurrentOrdersPanelProps) {
  const logger = new Logger(SummaryCurrentOrdersPanel.name)

  // Props
  const {restaurant} = {...props}

  // App State
  const {apiService} = useContext(ApiContext);
  const {success, error} = useContext(NotificationContext);

  // Component State
  const [isLoading, setIsLoading] = useState(true);
  const [stats, setStats] = useState(null);
  const [acceptedOrders, setAcceptedOrders] = useState<Array<Order> | null>(null);
  const [cancelledOrders, setCancelledOrders] = useState<Array<Order> | null>(null);
  const [revenue, setRevenue] = useState<number | null>(null);

  useEffect(() => {
    if (restaurant?.id) {
      getOrderStats()
    }
  }, [restaurant])


  function getOrderStats() {
    setIsLoading(true)
    apiService.ordersWeekStats(restaurant?.id)
        .then(data => {
          const result = data?.result
          setAcceptedOrders(result?.accepted)
          setCancelledOrders(result?.cancelled)
          setRevenue(result?.revenue)
        })
        .catch(e => error("Error", e.message))
        .finally(() => setIsLoading(false))
  }

  return (
      <DashboardBasePanel title={"This weeks orders"} isLoading={restaurant == null} onAction={getOrderStats}>
        <Stack spacing={4}>

            <StatGroup>
              <Stat>
                <StatLabel color={"green.500"}>Accepted</StatLabel>
                <StatNumber color={"green.500"}>{acceptedOrders?.length}</StatNumber>
                <StatHelpText>
                  Number of orders accepted this week
                </StatHelpText>
              </Stat>
              <Stat>
                <StatLabel color={"orange.500"}>Cancelled</StatLabel>
                <StatNumber color={"orange.500"}>{cancelledOrders?.length}</StatNumber>
                <StatHelpText>
                  Number of orders cancelled this week
                </StatHelpText>
              </Stat>

              <Stat>
                <StatLabel>Gross</StatLabel>
                <StatNumber>{FormatterUtil.formatCurrency(revenue)}</StatNumber>
                <StatHelpText>
                  Current gross revenue as of now
                </StatHelpText>
              </Stat>
            </StatGroup>

        </Stack>
      </DashboardBasePanel>
  )
}

export default SummaryCurrentOrdersPanel;
