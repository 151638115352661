import React, {useContext, useState} from 'react'
import {Box, Button, Flex, FormControl, FormLabel, Heading, Input, Stack, Text, Link} from '@chakra-ui/react'
import {HeroBackground, LinerGradientBox} from "../../components/blocks";
import {colors} from "../../constants/styles";
import styled from "styled-components";
import {AuthContext} from "../../providers/AuthProvider";
import {NotificationContext} from "../../providers/NotificationProvider";
import {useHistory, Link as RouterLink} from "react-router-dom";
import routes from "../../constants/routes";
import {LogoDefault} from "../../components/logos";
import {ResponsiveContext} from "../../providers/ResponsiveProvider";

function PasswordResetPage(props) {


  const {passwordReset} = useContext(AuthContext);
  const {error, success} = useContext(NotificationContext);
  const {isMobile} = useContext(ResponsiveContext);
  const history = useHistory()

  const [username, setUsername] = useState("");
  const [loading, setLoading] = useState(false);
  const [isDone, setIsDone] = useState(false)

  function onResetPasswordClick() {
    setLoading(true);
    passwordReset(username)
        .then((response: any) => setIsDone(true))
        .catch((e: any) => {
          error("Sign in failed", e.message)
        })
        .finally(() => setLoading(false));
  }

  return (
      <Flex flex={1} height={"100%"} width={"100%"}>
        {/*<HeroBackground style={{height: '100vh', width: '100%', backgroundColor: colors.WHITE}}>*/}
          <LinerGradientBox
              height={"100%"}
              width={"100%"}
              display={"flex"}
              alignItems={"center"}
              justifyContent={isMobile ? 'center' : "flex-end"}
              style={isMobile ? {background: 'white'} : {}}
          >

            <Stack spacing={4} width={["100%", "100%", "60%"]} maxWidth={"550px"} padding={["20px", "30px", "80px", "100px"]}>
              <LogoDefault></LogoDefault>
              <Heading as={"h1"} size={"lg"} >Send reset password email</Heading>
              { isDone ?
                  <Stack>
                    <Text>
                      If an account exists with that email, we'll send a password reset email shortly.
                    </Text>
                    <Button colorScheme={"primary"} onClick={() => history.push(routes.AUTH_SIGN_IN)}>Back to sign in</Button>
                  </Stack>
                  :
                  <Stack spacing={4} justify={'stretch'} >
                    <FormControl>
                      <FormLabel>Email</FormLabel>
                      <Input defaultValue={username} placeholder={"e.g. John.Smith@halalmunch.com"} onChange={(event) => setUsername(event.target.value)}/>
                    </FormControl>

                    <Button isFullWidth isLoading={loading} colorScheme={"primary"} type={"submit"}  onClick={onResetPasswordClick}>Submit</Button>
                  </Stack>

              }
            </Stack>

          </LinerGradientBox>
        {/*</HeroBackground>*/}
      </Flex>
  )
}


export default PasswordResetPage;
