import {Order, Restaurant} from "../../models/models";
import React, {useContext, useEffect, useState} from "react";
import {DashboardBase, DashboardBasePanel} from "./components/DashboardBase";
import {ApiContext} from "../../providers/ApiProvider";
import {OrderCard, OrderCardLoading, OrderDetailsCard, OrderDetailsCardLoading} from "../../components/orders";
import ErrorMessageBoundary from "../../components/error/ErrorMessageBoundary";
import {useInterval} from "react-use";
import {
  Stack,
} from '@chakra-ui/react'
import {AuthContext} from "../../providers/AuthProvider";
import {ResponsiveContext} from "../../providers/ResponsiveProvider";
import {NotificationContext} from "../../providers/NotificationProvider";
import Loader from "../../components/loading/Loader";
import {FirebaseContext} from "../../providers/FirebaseProvider";

interface Props {
  restaurant: Restaurant | null
}
function OrdersDashboard(props : Props) {
  const {apiService} = useContext(ApiContext);
  const {isAuthenticated, user, firebaseCustomToken} = useContext(AuthContext);
  const {isMobile} = useContext(ResponsiveContext);
  const {success, error} = useContext(NotificationContext);
  const {firestoreDb, loginWithEmailAndPassword, isAuthenticatedWithFirebase} = useContext(FirebaseContext);

  const {restaurant=null} = {...props};

  const [orders, setOrders] = useState<Array<Order>>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [selectedOrder, setSelectedOrder] = useState<Order>(null)

  useEffect(() => {
    if (isAuthenticated && restaurant?.id) {
      // setIsLoading(true)
      // fetchOrders();
    }
  }, [restaurant, isAuthenticated])

  useEffect(() => {
    if (isAuthenticated && firebaseCustomToken) {
      loginWithEmailAndPassword(firebaseCustomToken)
    }
  }, [isAuthenticated, firebaseCustomToken])

  useEffect(() => {
    if (isAuthenticatedWithFirebase && firestoreDb && restaurant && restaurant?.id) {
      fetchOrders()
    }
  }, [firestoreDb, restaurant, isAuthenticatedWithFirebase])

  // useInterval(() => {
  //   fetchOrders();
  // }, 60000);

  function fetchOrders() {
    console.debug("Fetching orders from firestore")
    firestoreDb.collection(`restaurants`).doc(restaurant?.id).collection('orders').onSnapshot(orderCol => {
      console.log("firebase collection returned: ", orderCol.docs)
      orderCol.docs.forEach(doc => {
        console.log("firebase doc returned: ", doc.data())
      })
      /*
      # first status
  pending = "Pending"
  # additional payment status TBC
  payment_intent = "Payment Intent"
  awaiting_payment = "Awaiting Payment"
  # start status for restaurant flow
  requested = "Requested"
  restaurant_accepted = "Preparing"
  restaurant_preparing = "Preparing"
  restaurant_rejected = "Restaurant Rejected"
  user_cancelled = "User Cancelled"
  restaurant_cancelled = "Restaurant Cancelled"

  # restaurant ready
  ready_to_collect = "Ready To Collect"
  ready_to_deliver = "Ready To Deliver"
  ready = "Ready"
  # restaurant complete
  complete = "Complete"
       */
      setOrders(orderCol.docs.map(doc => Object.assign(new Order(), doc.data())).sort((o1, o2) => {
        const o1Status = o1?.status;
        const o2Status = o2?.status;

        // Requested at the top
        if (o1Status === o2Status) {
          return o1?.order_date?.valueOf() - o2?.order_date?.valueOf()
        }

        if (o1Status === "Requested") {
          return 1
        }
        if (o2Status === "Requested") {
          return -1
        }

        // Preparing after
        if (o1Status === "Preparing") {
          return 1
        }
        if (o2Status === "Preparing") {
          return -1
        }

        // Ready orders next
        if (o1Status === "Ready" || o1Status === "Ready To Collect" || o1Status === "Ready To Deliver") {
          return 1
        }
        if (o1Status === "Ready" || o1Status === "Ready To Collect" || o1Status === "Ready To Deliver") {
          return -1
        }

        // Complete orders at the end
        if (o1Status === "Restaurant Rejected" || o1Status === "Complete") {
          return -1
        }
        if (o1Status === "Restaurant Rejected" || o1Status === "Complete") {
          return 1
        }

        return o1?.order_date?.valueOf() - o2?.order_date?.valueOf()
      }).reverse())
    })
    // if (restaurant?.id) {
    //   apiService.ordersGetLiveByRestaurantId(restaurant?.id)
    //       .then(data => {
    //         setOrders(data)
    //       })
    //       .catch(e => error("", e.message))
    //       .finally(() => setIsLoading(false))
    // }
  }


  return (
      <DashboardBase title={"Manage Your Orders"} subtitle={"New orders will automatically appear here as they come in."}>
        <DashboardBasePanel
            title={"Live Orders"}
            actionButtonText={"Refresh"}
            onAction={() => fetchOrders()}
        >
          <ErrorMessageBoundary>
            <Stack direction={'row'} flex={1} align={"flex-start"} >
              <Loader isLoading={isLoading}>
                <Stack spacing={3} width={isMobile ? '100%' : 'inherit'}>
                  {orders?.map(order =>
                    <OrderCard
                        order={order}
                        onClick={() => setSelectedOrder(order)}
                        borderColor={"gray.100"}
                        borderRightWidth={"5px"}
                        borderRightStyle={selectedOrder?.id === order?.id ? `solid` : 'none'}
                        // border={selectedOrder?.id === order?.id ? `5px solid` : 'none'}
                        // borderRadius={selectedOrder ? "5px" : '0'}
                    />
                  )}
                </Stack>

                <OrderDetailsCard
                    order={selectedOrder}
                    onClose={() => setSelectedOrder(null)}
                />
              </Loader>

            </Stack>
          </ErrorMessageBoundary>
        </DashboardBasePanel>
      </DashboardBase>
  )
}


export default OrdersDashboard;
