import {Order, Restaurant} from "../../models/models";
import React, {useContext, useEffect, useState} from "react";
import {DashboardBase, DashboardBasePanel} from "./components/DashboardBase";
import {ApiContext} from "../../providers/ApiProvider";
import {OrderCard, OrderCardLoading, OrderDetailsCard, OrderDetailsCardLoading} from "../../components/orders";
import ErrorMessageBoundary from "../../components/error/ErrorMessageBoundary";
import {useInterval} from "react-use";
import {
  HStack, Stack, Text, Heading, Button, Icon, Skeleton
} from '@chakra-ui/react'
import {AuthContext} from "../../providers/AuthProvider";
import { colors } from "../../constants/styles";

interface Props {
  restaurant: Restaurant | null,
}
function SalesDashboard(props : Props) {
  const {apiService} = useContext(ApiContext);
  const {isAuthenticated} = useContext(AuthContext);

  const {restaurant=null} = {...props};

  const [orders, setOrders] = useState<Array<Order>>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [error, setError] = useState<boolean>(true)
  const [selectedOrder, setSelectedOrder] = useState<Order>(null)

  useEffect(() => {
    if (isAuthenticated && restaurant?.id) {
      fetchOrders();
    }
  }, [restaurant, isAuthenticated])

  useInterval(() => {
    fetchOrders();
  }, 60000);

  function fetchOrders() {
    setIsLoading(true)
    apiService.ordersGetByRestaurantId(restaurant.id)
        .then(data => setOrders(data))
        .catch(e => setError(e.message))
        .finally(() => setIsLoading(false))
  }

  return (
      <DashboardBase title={"Sales"} subtitle={"View your orders and items sold"}>
        <DashboardBasePanel
            title={"Coming Soon"}
        >
          <Text>
            Sales dashboard coming soon. You'll be able to view your previous sales and view a beakdown
            of all your individual product sales.
          </Text>
        </DashboardBasePanel>
      </DashboardBase>
  )
}


export default SalesDashboard;
