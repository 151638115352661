import React, {useContext, useEffect, useState} from "react";
import {AuthContext} from "../../../providers/AuthProvider";
import {ApiContext} from "../../../providers/ApiProvider";
import {NotificationContext} from "../../../providers/NotificationProvider";
import {Flex, Heading, Stack, Text, Button} from "@chakra-ui/react";

interface Props {
}

function VerifyEmailForm(props: Props) {

  const {user} = useContext(AuthContext);
  const {apiService} = useContext(ApiContext)
  const {success, error} = useContext(NotificationContext)

  const [loading, setLoading] = useState(false);
  const [hasSent, setHasSent] = useState(false);

  function resendVerificationEmail() {
    setLoading(true);
    apiService.userAccountSendVerificationMail()
        .then((d) => {
          setHasSent(true)
          success("Verification mail sent", d.message)
        })
        .catch((e) => error("Failed to send verification mail", e.message))
        .finally(() => setLoading(false));
  }

  return (
      <Flex direction={'column'} justify={'center'} align={'stretch'} width={"100%"}>
        <Stack spacing={6}>
          <Heading as={"h1"} size={"lg"}>Thanks for joining</Heading>
          <Stack spacing={4} justify={'stretch'} >
            <Stack spacing={4}>
              <Text>We'd love to get you started but we need to verify your email to continue. </Text>
              <Text>An email has been sent to {user?.email}.</Text>
              <Button isFullWidth isLoading={loading} colorScheme={"secondary"} type={"submit"}  onClick={resendVerificationEmail}>Resend Verification Email</Button>
            </Stack>
          </Stack>
        </Stack>
      </Flex>
  )
}

export default VerifyEmailForm;
