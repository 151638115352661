import React, {useContext, useEffect, useState} from "react";
import {AuthContext} from "../../../providers/AuthProvider";
import {useHistory, useRouteMatch} from "react-router-dom";
import {
  Box, Flex, Stack, Button, Icon, Text,
  IconButton,
  Drawer,
  DrawerContent,
  DrawerCloseButton,
  DrawerOverlay,
  DrawerHeader, Divider,
} from "@chakra-ui/react";
import {
  AiFillLeftSquare,
  AiOutlineMenu,
} from 'react-icons/ai'
import {LogoDefault} from "../../../components/logos";
import {ResponsiveContext} from "../../../providers/ResponsiveProvider";
import {DASHBOARD_PAGES} from "../DashboardPage";
import routes from "../../../constants/routes";
import {FirebaseContext} from "../../../providers/FirebaseProvider";

interface DashboardMenuProps {
  selectedPage: string,
}

function DashboardMenu(props: DashboardMenuProps) {
  const {selectedPage = null} = {...props}
  const {signOut} = useContext(AuthContext);
  const {firebaseUser} = useContext(FirebaseContext);
  const {isMobile} = useContext(ResponsiveContext);
  const history = useHistory();
  const {url} = useRouteMatch();

  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const selectPageKey = selectedPage == null ? DASHBOARD_PAGES.DASH.eventKey : selectedPage

  // useEffect(() => {
  //   setShowMobileMenu(false)
  // }, [selectedRestaurant])

  function onMenuItemClick(eventKey) {
    history.push(`${routes.DASHBOARD}/${eventKey}`)
    setShowMobileMenu(false)
  }

  function DashboardMenuButton(props: { label: string, icon: any, onClick: () => void, selected: boolean }) {
    const {label, icon, onClick = () => null, selected} = {...props}
    return (
        <Button
            // colorScheme="secondary"
            fontWeight={selected ? "bold" : "regular"}
            size={"sm"}
            color={selected ? "gray.700" : "#828585"}
            variant="ghost"
            leftIcon={<Icon as={icon} color={selected ? "primary.500" : "#828585"}/>}
            justify={'flex-start'}
            style={{justifyContent: 'flex-start'}}
            onClick={onClick}>
          {label}
        </Button>
    )
  }

  function getSignOutButton() {
    return (
        <DashboardMenuButton
            onClick={() => signOut()}
            selected={false}
            label={"Sign Out"}
            icon={AiFillLeftSquare}
        >
          Sign Out
        </DashboardMenuButton>
    )
  }

  if (isMobile) {
    return (
        <Box position={"fixed"} zIndex={50} width={"100%"} padding={"10px"} bg={"white"} borderBottom={"solid 2px rgba(0,0,0,0.1)"}>
          <Flex justify={'space-between'} align={"center"}>
            <LogoDefault/>
            <IconButton
                icon={<Icon as={AiOutlineMenu}/>}
                variant="solid"
                bg={"white"}
                onClick={() => setShowMobileMenu(true)}
            />
          </Flex>
          <Drawer isOpen={showMobileMenu} placement={"right"}
                  onClose={() => setShowMobileMenu(false)}>
            <DrawerOverlay>
              <DrawerContent bg={"white"} padding={2}>
                <DrawerCloseButton/>
                <DrawerHeader></DrawerHeader>
                <Stack spacing={3}>
                  <Divider/>
                  {Object.values(DASHBOARD_PAGES).map((page) =>
                      <DashboardMenuButton
                          label={page.label}
                          icon={page.icon}
                          selected={selectPageKey === page.eventKey}
                          onClick={() => onMenuItemClick(page.eventKey)}
                      />
                  )}
                  {getSignOutButton()}
                </Stack>
              </DrawerContent>
            </DrawerOverlay>
          </Drawer>
        </Box>
    )
  } else {
    return (
        <Stack spacing={4} w={['200px', '200px', '240px', '240px']} padding={"10px"} bg={"white"} h={'100vh'} position={"fixed"} top={"0"} left={"0"}>
          <LogoDefault/>
          {Object.values(DASHBOARD_PAGES).map((page) =>
              <DashboardMenuButton
                  label={page.label}
                  icon={page.icon}
                  selected={selectPageKey === page.eventKey}
                  onClick={() => onMenuItemClick(page.eventKey)}
              />
          )}
          <Stack flex={1}  padding={"20px 0"}>
            <Divider/>
            {getSignOutButton()}

            <Text>{firebaseUser?.user?.uid}</Text>

          </Stack>
        </Stack>
    )
  }

}


export default DashboardMenu;
