import MenuItem from "./MenuItem";

class MenuItemGroup {
  id: number;
  restaurant_id: string;
  group_name: string;
  description: string;
  view_order_priority: number;
  items: Array<MenuItem>;
  updated_at: Date;
  created_at: Date;


  constructor(id: number, restaurant_id: string, group_name: string, description: string, view_order_priority: number, items: Array<MenuItem>, updated_at: Date, created_at: Date) {
    this.id = id;
    this.restaurant_id = restaurant_id;
    this.group_name = group_name;
    this.description = description;
    this.view_order_priority = view_order_priority;
    this.items = items;
    this.updated_at = updated_at;
    this.created_at = created_at;
  }
}

export class MenuItemGroupUpdate {
  group_name: string;
  description: string;
  view_order_priority: number;


  constructor(group_name: string, description: string, view_order_priority: number) {
    this.group_name = group_name;
    this.description = description;
    this.view_order_priority = view_order_priority;
  }
}



export default MenuItemGroup;
